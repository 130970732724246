import { Component, Input, OnInit } from '@angular/core';
import {
  CuestionarioPreguntaDTO,
  CuestionarioRespuestaOpcionDTO,
  PreguntaOpcionDTO,
  TipoEstadoPersonalLegajoCuestinario,
} from 'src/app/ModelDTO/DTO';
import { LevelRangeDTO } from 'src/app/ModelDTO/DTO/levelRange.DTO';
import { QuestionComponent } from 'src/app/shared/forms/cuestionario-preguntas/cuestionario-tipo-preguntas/question.component';

interface OpcionMultiSelect {
  opcion: PreguntaOpcionDTO;
  selected: boolean;
}
@Component({
  selector: 'app-multiple-choice-question',
  templateUrl: './multiple-choice-question.component.html',
  styleUrls: ['./multiple-choice-question.component.scss'],
})
export class MultipleChoiceQuestionComponent implements OnInit, QuestionComponent {
  @Input() public data: CuestionarioPreguntaDTO;
  @Input() public modoAdministrador: boolean;
  @Input() public editionMode: boolean;
  @Input() public estadoCuestionario: TipoEstadoPersonalLegajoCuestinario;
  @Input() public cuestionarioTerminado: TipoEstadoPersonalLegajoCuestinario;
  @Input() public position: number;
  @Input() public previousResult: LevelRangeDTO;

  public opcionMultiSelect: OpcionMultiSelect;
  public arrayOpcionMultiSelect: OpcionMultiSelect[] = [];

  public ngOnInit(): void {
    this.data.opciones.forEach((opcion) => {
      this.arrayOpcionMultiSelect.push({
        opcion,
        selected: false,
      });
    });

    if (this.estadoCuestionario === this.cuestionarioTerminado) {
      this.data.respuesta?.opciones?.forEach((opcion) => {
        this.arrayOpcionMultiSelect.forEach((opcionAux) => {
          if (opcion.opcion.id === opcionAux.opcion.id) opcionAux.selected = true;
        });
      });
    }
  }

  public respuestaChange(respuesta: OpcionMultiSelect, value: boolean): void {
    this.data.respuesta.fecha = new Date();
    this.data.respuesta.modificada = true;
    this.data.respuesta.id = this.data.id;
    respuesta.selected = value;
    const indexRespuesta = this.data.respuesta?.opciones?.findIndex((x) => x.opcion.id === respuesta.opcion.id);
    const respuestaDto = new CuestionarioRespuestaOpcionDTO();
    respuestaDto.opcion = respuesta.opcion;

    if (!respuesta.selected) {
      this.data.respuesta?.opciones?.splice(indexRespuesta, 1);
    } else {
      this.data.respuesta.opciones.push(respuestaDto);
    }
  }
}
