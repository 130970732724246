import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthResponseDTO } from '../../models/DTO/authResponse.DTO';
import { PushService } from '../../services/push/signalr.push.service';

import { AuthRequestDTO } from '../../models/DTO/authRequest.DTO';
import { AuthServiceBackend } from '../../services/backend/auth.ServiceBackend';
import * as Sentry from '@sentry/browser';
import {
  CHAT_AUTH,
  CURRENT_COMPANY,
  CURRENT_USER_WEB,
  FCM,
  LAST_CLOCK_IN_OUT,
  SCROLL,
  USER_PERMISSION_FLAGS,
} from 'src/app/shared/localStorageConstants';
import { AppCacheService } from '../../services/app-cache/app-cache.service';
import { NgxNeoLoaderService } from 'src/app/shared/lib/ngx-neo-loader/ngx-neo-loader.service';
import { UserServiceBackend } from 'src/app/shared/lib/ngx-neo-frontend-mat/services/backend/user.ServiceBackend';
import { CordovaService } from 'src/app/shared/lib/ngx-neo-frontend-mat/services/cordova/cordova.service';
import { UserModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/user.ModelDTO';
import { ChatAuthentication } from 'src/app/app-common/chat/interfaces/chat-authentication.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public authResponseDTO: AuthResponseDTO;
  public chatAuth: ChatAuthentication;

  private loggedEventSubject = new BehaviorSubject<AuthResponseDTO>(undefined);
  public loggedEvent$ = this.loggedEventSubject.asObservable();

  private termsAcceptedSubject = new BehaviorSubject<Boolean>(false);
  public termsAccepted$ = this.termsAcceptedSubject.asObservable();

  constructor(
    private pushService: PushService,
    private authService: AuthServiceBackend,
    private loaderService: NgxNeoLoaderService,
    private userService: UserServiceBackend,
    private cacheService: AppCacheService,
    private cordovaService: CordovaService,
  ) {
    // set token if saved in local storage
    this.setUser(JSON.parse(localStorage.getItem(CURRENT_USER_WEB)), false);
  }

  public login(authResponseDTO: AuthResponseDTO): void {
    this.setUser(authResponseDTO);
    this.pushService.start(authResponseDTO);
  }

  public async refreshToken(): Promise<void> {
    const authResponseDTO = await this.authService.updateAuth(this.authResponseDTO);
    this.setUser(authResponseDTO);
    this.pushService.stop();
    setTimeout(() => this.pushService.start(this.authResponseDTO), 200);
  }

  public setChatAuth(chatAuth: ChatAuthentication): void {
    this.chatAuth = chatAuth;
    localStorage.setItem(CHAT_AUTH, JSON.stringify(chatAuth));
  }

  public async acceptTerms(): Promise<AuthResponseDTO> {
    const authResponseDTO = await this.authService.insertAuthTermsAccepted();
    this.authResponseDTO.termsAccepted = authResponseDTO.termsAccepted;
    this.setUser(this.authResponseDTO);
    return this.authResponseDTO;
  }

  public async updateUserLanguage(user: UserModelDTO): Promise<AuthResponseDTO> {
    this.authResponseDTO.language = user.getEntityDTO().language;
    this.setUser(this.authResponseDTO);
    return this.authResponseDTO;
  }

  public updateRequieres2fa(user: UserModelDTO): AuthResponseDTO {
    this.authResponseDTO.requieres2fa = user.getEntityDTO().requieres2fa;
    this.setUser(this.authResponseDTO);
    return this.authResponseDTO;
  }

  /**
   * We are using this method when the link has the tenant as parameter
   */
  public async changeTenant(tenant: string): Promise<AuthResponseDTO> {
    if (tenant !== this.authResponseDTO.tenant) {
      const authResponseDTO = await this.userService.getUserTenantsTOKEN(tenant);
      this.removeInfoLogin();
      this.setUser(authResponseDTO);
      this.reloadPage();
    }
    return this.authResponseDTO;
  }

  public reloadPage(): void {
    if (this.cordovaService.isAngularApp) {
      window.location.reload();
    } else {
      window.location.href = 'index.html';
    }
  }

  public async logout(): Promise<void> {
    const authRes = new AuthRequestDTO();
    authRes.tokenDeviceId = localStorage.getItem(FCM);
    try {
      this.removeInfoLogin();
      await this.authService.insertAuthLogout(authRes);
    } catch {
      throw new Error('No se puede cerrar la sesión en este momento, intente nuevamente más tarde.');
    } finally {
      this.loaderService.hide();
    }
  }

  public removeInfoLogin(): void {
    this.pushService.stop();
    /*   if (this.modalService.hasOpenModals()) {
              this.modalService.dismissAll();
          } */
    // clear token remove user from local storage to log user out
    this.authResponseDTO = null;
    localStorage.removeItem(CURRENT_USER_WEB);
    localStorage.removeItem(CURRENT_COMPANY);
    localStorage.removeItem(SCROLL);
    localStorage.removeItem(USER_PERMISSION_FLAGS);
    localStorage.removeItem(LAST_CLOCK_IN_OUT);
    localStorage.removeItem(CHAT_AUTH);
    this.cacheService.clearAllCache();
  }

  private setUser(authResponseDTO: AuthResponseDTO, save: boolean = true): void {
    this.authResponseDTO = authResponseDTO;
    // store username and jwt token in local storage to keep user logged in between page refreshes

    if (save) {
      localStorage.setItem(CURRENT_USER_WEB, JSON.stringify(this.authResponseDTO));
    }

    this.setSentryUser();
    this.termsAcceptedSubject.next(this.authResponseDTO?.termsAccepted);
    this.loggedEventSubject.next(this.authResponseDTO);
  }

  private setSentryUser(): void {
    // Para mantener la confidencialidad, solo enviaremos el id
    Sentry.withScope((scope) => {
      scope.setUser({
        id: `${this.authResponseDTO?.id}` ?? null,
      });
    });
  }
}
