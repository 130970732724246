import { ErrorHandler, NgZone, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TimeoutError } from 'rxjs';
import { b64DecodeUnicode } from 'src/app/shared/lib/ngx-neo-components-mat/public_api';
import { NgxNeoModalMatService } from 'src/app/shared/lib/ngx-neo-modal-mat/public_api';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../../helpers/auth/authentication.service';
import { CordovaService } from '../cordova/cordova.service';
import * as Sentry from '@sentry/angular';
import { MatDialog } from '@angular/material/dialog';
import {
  ServerErrorDialogComponent,
  ServerErrorDialogData,
} from 'src/app/shared/lib/ngx-neo-frontend-mat/components/server-error-dialog/server-error-dialog.component';
import { StatusPageService } from 'src/app/shared/status-page/status-page.service';
import { SnackBarService } from 'src/app/shared/snack-bar/snack-bar.service';
import { HeaderAppService } from 'src/app/core/header/header-app.service';

export class BaseErrorHandler implements ErrorHandler {
  public originalAlert: any;
  private authenticationService: AuthenticationService;
  private router: Router;
  private cordovaService: CordovaService;
  private headerService: HeaderAppService;
  private zone: NgZone;
  private dialogService: MatDialog;
  private neoModalService: NgxNeoModalMatService;
  private statusPageService: StatusPageService;
  private snackBar: SnackBarService;

  constructor() {
    this.loadServices();
  }

  async handleError(wrapperError: any): Promise<void> {
    const originalError = wrapperError.rejection ? wrapperError.rejection : wrapperError;

    if (!environment.production) {
      console.error(originalError);
    }

    this.handleErrorDialog(originalError);

    if (originalError.status === 0 || originalError.status === 404) {
      this.zone.run(() => this.headerService.notifyWithoutConnection());
      this.statusPageService.checkStatus().subscribe((status) => {
        if (this.statusPageService.systemDown(status)) {
          this.zone.run(async () => await this.router.navigate(['/maintenance']));
        }
      });

      return;
    }

    if (originalError.status === 410) {
      await this.authenticationService.refreshToken();
      this.authenticationService.reloadPage();
      return;
    }

    if (
      originalError.message?.includes('Unauthorized') ||
      originalError.message?.includes('connection being closed') ||
      (originalError.status && originalError.status === 401)
    ) {
      if (!this.router.url.startsWith('/login') && !this.router.url.startsWith('/demo') && !this.router.url.startsWith('/ios-landing')) {
        const authResponseDTO = this.authenticationService?.authResponseDTO;
        this.headerService.dispose();
        if (this.cordovaService.isIOSApp) {
          this.zone.run(async () => await this.router.navigate(['/ios-landing']));
        } else {
          if (authResponseDTO?.userName === 'demo@naaloo.com') {
            this.zone.run(async () => await this.router.navigate(['/demo']));
          } else {
            this.zone.run(async () => await this.router.navigate(['/login']));
          }
        }
      }
    } else if (originalError.headers) {
      if (!originalError.ok && originalError.status !== 401) {
        this.originalAlert(originalError.statusText);
      }
    } else {
      const message: string = originalError.message ? originalError.message : originalError.toString();
      console.error(message, originalError.stack);

      if (
        message !== 'Error parsing handshake response: Error: Expected a handshake response from the server.' &&
        message?.includes('WebSocket') === false
      ) {
        if (!originalError.handled) {
          if (!/^Loading chunk.*/.test(originalError.message ?? '')) {
            this.originalAlert(message);
          }
        }
      }
    }
  }

  private loadServices(): void {
    this.headerService = inject(HeaderAppService);
    this.zone = inject(NgZone);
    this.originalAlert = inject(NgxNeoModalMatService).originalAlert;
    this.dialogService = inject(MatDialog);
    this.authenticationService = inject(AuthenticationService);
    this.router = inject(Router);
    this.cordovaService = inject(CordovaService);
    this.neoModalService = inject(NgxNeoModalMatService);
    this.statusPageService = inject(StatusPageService);
    this.snackBar = inject(SnackBarService);
  }

  private handleErrorDialog(error: any): void {
    let errorMessage: string;

    if (error instanceof TimeoutError) {
      error.message = 'La conexión está demorando demasiado, intente nuevamente más tarde.';
    } else {
      if (error.headers) {
        if (error.status === 0 || error.status === 404 || error.status === 401 || error.status === 410) {
          errorMessage = '';
        } else if (error.status === 504) {
          this.snackBar.showError('GENERAL.INESTABLE_CONNECTION_RETRY');
        } else if (error.status >= 500) {
          errorMessage = 'Temporalmente esta funcionalidad no está disponible, reintenta en unos minutos.';
        } else {
          errorMessage = error?.statusText;
        }
        error.ok = true; // avisa al manejador de errores principal que ya fue informado el error al usuario.
      } else {
        errorMessage = error.message ? error.message : error.toString();
      }
    }

    if (errorMessage) {
      if (errorMessage.startsWith('data:txt;base64,')) {
        errorMessage = b64DecodeUnicode(errorMessage.substring(16));
      }

      if (error.status === undefined) {
        Sentry.captureException(error);
      }

      if (error.status >= 500) {
        Sentry.captureException(error);
        this.zone.run(() => {
          this.serverErrorDialog(errorMessage);
        });
      } else {
        this.zone.run(async () => {
          if (error.ok) {
            this.neoModalService.warning(errorMessage);
          } else {
            await this.neoModalService.error(errorMessage);
          }
          error.handled = true;
        });
      }
    }
  }

  private serverErrorDialog(errorMessage: string): void {
    this.dialogService.open(ServerErrorDialogComponent, {
      hasBackdrop: true,
      width: '640px',
      height: '360px',
      panelClass: 'no-border-dialog',
      data: {
        errorMessage,
      } as ServerErrorDialogData,
    });
  }
}
