import { Injectable } from '@angular/core';
import { NgxNeoModalMatComponent, AlertResult, AlertButton } from './ngx-neo-modal-mat.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class NgxNeoModalMatService {
  public template: any;
  public originalAlert: any;

  constructor(
    private dialog: MatDialog,
    private translateService: TranslateService,
  ) {
    this.originalAlert = window.alert;
    window.alert = async (msg) => {
      await this.alert(msg);
    };
  }

  private async openModal(config: any, hasBackdrop = true): Promise<any> {
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        const dialogRef = this.dialog.open(NgxNeoModalMatComponent, {
          hasBackdrop: hasBackdrop,
          data: { config },
        });

        const result = await dialogRef.afterClosed().toPromise();
        resolve(result);
      }, 140);
    });
  }

  /**
   * Show message. Can be use as window.alert
   */
  public async alert(msg: string, autofocus = false): Promise<void> {
    const config = {
      title: {
        visibility: false,
      },
      message: this.translateService.instant(msg),
      button: {
        cancel: {
          visibility: false,
        },
        accept: {
          autofocus,
        },
      },
    };
    await this.openModal(config);
  }

  /**
   *
   * Show succsessfull  message
   */
  public async success(msg: string, autofocus = true): Promise<AlertResult> {
    const title = this.translateService.instant('MODALS.GOOD_JOB');
    const config = {
      title: {
        text: title,
      },
      message: this.translateService.instant(msg),
      type: 'success',
      button: {
        cancel: {
          visibility: false,
        },
        accept: {
          autofocus,
        },
      },
    };
    return this.openModal(config);
  }

  /**
   *
   * Warning message
   */
  public async warning(msg: string, autofocus = false): Promise<AlertResult> {
    const title = this.translateService.instant('MODALS.ATTENTION');
    const config = {
      title: {
        text: title,
      },
      message: this.translateService.instant(msg),
      type: 'warning',
      button: {
        cancel: {
          visibility: false,
        },
        accept: {
          autofocus,
        },
      },
    };

    return this.openModal(config, true);
  }

  /**
   *
   * Error message
   */
  public async error(msg: string, autofocus = true): Promise<AlertResult> {
    const title = this.translateService.instant('MODALS.ERROR');
    const config = {
      title: {
        text: title,
      },
      message: this.translateService.instant(msg),
      type: 'error',
      button: {
        cancel: {
          visibility: false,
        },
        accept: {
          autofocus,
        },
      },
    };
    return this.openModal(config);
  }

  /**
   *
   * Information message
   */
  public async info(msg: string, autofocus = true): Promise<AlertResult> {
    const title = this.translateService.instant('MODALS.IMPORTANT_INFO');
    const config = {
      title: {
        text: title,
      },
      message: this.translateService.instant(msg),
      type: 'info',
      button: {
        cancel: {
          visibility: false,
        },
        accept: {
          autofocus,
        },
      },
    };
    return this.openModal(config);
  }

  /**
   *
   * Show decission modal with question and two options for accept or reject.
   */
  public async decision(
    questionMsg: string,
    successMsg?: string,
    cancelMsg?: string,
    autofocus = AlertButton.Cancel,
    acceptLabel?: string,
    cancelLabel?: string,
    title?: string,
  ): Promise<AlertResult> {
    const buttonAcceptFocus = autofocus === AlertButton.Accept;
    const buttonCancelFocus = autofocus === AlertButton.Cancel;

    const text = title ?? this.translateService.instant('MODALS.CONFIRMATION_REQUEST');

    const config = {
      title: {
        text,
      },
      message: this.translateService.instant(questionMsg),
      type: 'question',
      button: {
        cancel: {
          autofocus: buttonCancelFocus,
          text: cancelLabel,
        },
        accept: {
          autofocus: buttonAcceptFocus,
          text: acceptLabel,
        },
      },
    };

    return this.openModal(config).then((value) => {
      if (value?.ButtonResponse === AlertButton.Accept) {
        if (successMsg && successMsg !== '') {
          this.success(successMsg);
        }
      } else {
        if (cancelMsg) {
          this.info(cancelMsg);
        }
      }
      return Promise.resolve(value ?? AlertButton.Cancel);
    });
  }

  /**
   *
   * Input text message
   */
  public async input(
    title: string,
    placeholder: string,
    successMsg?: string,
    autofocus = true,
    buttonAutofocus: AlertButton = AlertButton.Accept,
    required = true,
  ): Promise<AlertResult> {
    const buttonAcceptFocus = buttonAutofocus === AlertButton.Accept;
    const buttonCancelFocus = buttonAutofocus === AlertButton.Cancel;

    const config = {
      title: {
        text: this.translateService.instant(title),
      },
      message: '',
      input: {
        placeholder,
        visibility: true,
        autofocus,
      },
      button: {
        accept: {
          required: required,
          autofocus: buttonAcceptFocus,
        },
        cancel: {
          autofocus: buttonCancelFocus,
        },
      },
    };

    return this.openModal(config).then((value) => {
      if (value?.ButtonResponse === AlertButton.Accept) {
        if (successMsg) {
          this.success(successMsg);
        }
      } else if (!value) {
        value = new AlertResult(AlertButton.Cancel, null);
      }
      return Promise.resolve(value);
    });
  }

  public async yesNoCancel(title: string, message: string, buttonFocus: AlertButton): Promise<AlertResult> {
    return this.custom(title, message, AlertButton.Yes | AlertButton.No | AlertButton.Cancel, buttonFocus);
  }

  private async custom(title: string, message: string, buttons: AlertButton, buttonFocus: AlertButton): Promise<AlertResult> {
    const buttonAccept: boolean = (buttons & AlertButton.Accept) > 0;
    const buttonYes: boolean = (buttons & AlertButton.Yes) > 0;
    const buttonCancel: boolean = (buttons & AlertButton.Cancel) > 0;
    const buttonNo: boolean = (buttons & AlertButton.No) > 0;
    const buttonRetry: boolean = (buttons & AlertButton.Retry) > 0;

    const buttonAcceptFocus: boolean = buttonFocus === AlertButton.Accept;
    const buttonYesFocus: boolean = buttonFocus === AlertButton.Yes;
    const buttonCancelFocus: boolean = buttonFocus === AlertButton.Cancel;
    const buttonNoFocus: boolean = buttonFocus === AlertButton.No;
    const buttonRetryFocus: boolean = buttonFocus === AlertButton.Retry;

    const config = {
      title: {
        text: title,
      },
      message,
      button: {
        yes: {
          visibility: buttonYes,
          autofocus: buttonYesFocus,
        },
        no: {
          visibility: buttonNo,
          autofocus: buttonNoFocus,
        },
        retry: {
          visibility: buttonRetry,
          autofocus: buttonRetryFocus,
        },
        cancel: {
          visibility: buttonCancel,
          autofocus: buttonCancelFocus,
        },
        accept: {
          visibility: buttonAccept,
          autofocus: buttonAcceptFocus,
        },
      },
    };

    return this.openModal(config);
  }

  private isObject(item): boolean {
    return item && typeof item === 'object' && !Array.isArray(item);
  }

  // this will lead to infinite recursion on circular references
  private mergeDeep(target, ...sources): any {
    if (!sources.length) {
      return target;
    }
    const source = sources.shift();

    if (this.isObject(target) && this.isObject(source)) {
      for (const key in source) {
        if (this.isObject(source[key])) {
          if (!target[key]) {
            Object.assign(target, { [key]: {} });
          }
          this.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }

    return this.mergeDeep(target, ...sources);
  }
}
