import { Injectable, WritableSignal, computed, signal } from '@angular/core';
import { CommentReactionDTO } from '@api/interfaces/comment-reaction.interface';
import { CurrentReactionDTO } from '@api/interfaces/current-reaction.interface';
import { DateTime } from 'luxon';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { NewCommentReactionDTO } from 'src/app/ModelDTO/DTO/newCommentReaction.DTO';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { UserTeammateFile } from 'src/app/shared/circle-image/user-legajo.interface';
import { Reaction } from 'src/app/shared/reactions/reaction';
import { parseStringReactions } from 'src/app/shared/shared-functions';

export interface ReactionInformation {
  value: string;
  timestamp: DateTime;
  teammates: number[];
  teammatesFullInformation: UserTeammateFile[];
}

@Injectable()
export class ReactionService {
  public $reactions: WritableSignal<Reaction[]> = signal([]);
  public $currentReaction: WritableSignal<string> = signal('');
  public $tooltipReactionText: WritableSignal<string> = signal('');
  public $reactionLimitReached = computed(() => this.$reactions().length >= 10);

  private reactionTooltipEmojiCache = new Map<string, ReactionInformation>();
  private reactionsCache: { timestamp: DateTime; reactions: CommentReactionDTO[] } | undefined = undefined;
  private getReactionsEndpoint: () => Observable<CommentReactionDTO[]>;
  private updateReactionsEndpoint: (dto: NewCommentReactionDTO) => Observable<CurrentReactionDTO>;
  private deleteReactionsEndpoint: (emoji: string) => Observable<CurrentReactionDTO>;

  constructor(private headerService: HeaderAppService) {}

  public initialize(
    getReactionsEndpoint: () => Observable<CommentReactionDTO[]>,
    updateReactionsEndpoint: (dto: NewCommentReactionDTO) => Observable<CurrentReactionDTO>,
    deleteReactionsEndpoint: (emoji: string) => Observable<CurrentReactionDTO>,
  ): void {
    this.getReactionsEndpoint = getReactionsEndpoint;
    this.updateReactionsEndpoint = updateReactionsEndpoint;
    this.deleteReactionsEndpoint = deleteReactionsEndpoint;
  }

  public loadEmojiReactions(emoji: string): Observable<void> {
    const now = DateTime.now();
    const emojiCache = this.reactionTooltipEmojiCache.get(emoji);

    if (emojiCache && now < emojiCache.timestamp) {
      this.$tooltipReactionText.set(emojiCache.value);
      return EMPTY;
    }

    return this.loadReactions().pipe(
      map((reactions) => {
        reactions.forEach((element) => {
          const teammates = this.reactionTooltipEmojiCache.get(element.emoji)?.teammates ?? [];
          const teammatesFullInformation = this.reactionTooltipEmojiCache.get(element.emoji)?.teammatesFullInformation ?? [];
          let tooltipReactionText = this.reactionTooltipEmojiCache.get(element.emoji)?.value ?? '';
          tooltipReactionText = `${element.teammateName} ${element.createDateTime.toLocaleString(DateTime.DATETIME_SHORT)}'\n'${tooltipReactionText}`;

          const teammateFullInformation = {
            id: `${element.teammateId}`,
            teammateFileId: element.teammateId,
            imgSource: element.teammateImage,
            nombreCompleto: element.teammateName,
          };

          this.reactionTooltipEmojiCache.set(element.emoji, {
            value: tooltipReactionText,
            timestamp: now.plus({ seconds: 10 }),
            teammates: [...teammates, element.teammateId],
            teammatesFullInformation: [...teammatesFullInformation, teammateFullInformation],
          });
        });

        this.$tooltipReactionText.set(this.reactionTooltipEmojiCache.get(emoji).value);
      }),
    );
  }

  public loadReactions(): Observable<CommentReactionDTO[]> {
    const now = DateTime.now();

    if (this.reactionsCache && now < this.reactionsCache.timestamp) {
      return of(this.reactionsCache.reactions);
    }

    return this.getReactionsEndpoint().pipe(
      tap((reactions) => {
        this.reactionsCache = {
          timestamp: DateTime.now().plus({ seconds: 10 }),
          reactions,
        };
      }),
      map(() => this.reactionsCache.reactions),
    );
  }

  public handleReaction(emoji: string): Observable<CurrentReactionDTO> {
    const alreadyAddedReaction = this.$reactions().some((r) => r.emoji === emoji);
    const emojiCache = this.reactionTooltipEmojiCache?.get(emoji);
    const addedByCurrentUser =
      emojiCache?.teammates?.some((id) => this.headerService.personalLegajoId === id) || this.$currentReaction() === emoji;
    const previousReaction = this.$currentReaction();

    if (!alreadyAddedReaction || !addedByCurrentUser) {
      const dto = new NewCommentReactionDTO();
      dto.emoji = emoji;

      this.addLocalReaction(emoji, 1);
      this.$currentReaction.set(emoji);

      return this.updateReactionsEndpoint(dto).pipe(
        tap((res) => {
          this.$reactions.set(parseStringReactions(res.reactions));
        }),
        catchError(() => {
          this.addLocalReaction(emoji, -1);
          this.$currentReaction.set(previousReaction);
          return EMPTY;
        }),
      );
    }

    this.addLocalReaction(emoji, -1);
    this.$currentReaction.set('');

    return this.deleteReactionsEndpoint(emoji).pipe(
      tap((res) => {
        this.$reactions.set(parseStringReactions(res?.reactions ?? ''));
        this.$currentReaction.set(res?.currentReaction ?? '');
      }),
      catchError(() => {
        this.addLocalReaction(emoji, 1);
        this.$currentReaction.set(previousReaction);
        return EMPTY;
      }),
    );
  }

  private addLocalReaction(emoji: string, quantity: number): void {
    const currentReaction = this.$reactions().find((reaction) => reaction.emoji === emoji);
    if (currentReaction !== undefined) {
      currentReaction.quantity += quantity;
      if (currentReaction.quantity === 0) {
        this.$reactions.update((reactions) => reactions.filter((x) => x.emoji !== currentReaction.emoji));
      }
    } else {
      this.$reactions.update((reactions) => [
        ...reactions,
        {
          emoji,
          quantity: 1,
        },
      ]);
    }

    const emojiCache = this.reactionTooltipEmojiCache.get(emoji);
    let tooltipText = emojiCache?.value ?? '';
    let teammatesId = emojiCache?.teammates ?? [];
    let teammatesFullInformation = emojiCache?.teammatesFullInformation ?? [];

    if (quantity > 0) {
      const name = this.headerService.userLogged.fullName;
      const addedTeammate = `${name} ${DateTime.now().toLocaleString(DateTime.DATETIME_SHORT)}\n`;

      teammatesId.push(this.headerService.personalLegajoId);
      teammatesFullInformation.push({
        id: `${this.headerService.personalLegajoId}`,
        imgSource: this.headerService.userLogged.image,
        nombreCompleto: name,
        teammateFileId: this.headerService.personalLegajoId,
      });
      tooltipText += addedTeammate;
    } else {
      teammatesId = teammatesId.filter((id) => id !== this.headerService.personalLegajoId);
      teammatesFullInformation = teammatesFullInformation.filter(
        (teammate) => teammate.teammateFileId !== this.headerService.personalLegajoId,
      );
      const teammatesText = tooltipText.split('\n');
      tooltipText = teammatesText.filter((text) => !text.includes(this.headerService.userLogged.fullName)).join('\n');
    }

    this.reactionTooltipEmojiCache.set(emoji, {
      value: tooltipText,
      teammates: teammatesId,
      timestamp: DateTime.now().plus({ seconds: 10 }),
      teammatesFullInformation,
    });
  }
}
