import { Injectable } from '@angular/core';
import { ComentarioDTO, ComentarioVisibilidad } from 'src/app/ModelDTO/DTO';
import { TranslateService } from '@ngx-translate/core';
import { ComentarioBase } from './comentario-base';
import { ComentariosService } from './comentarios.service';

@Injectable({
  providedIn: 'root',
})
export class ComentariosPerfilUsuarioHandler extends ComentarioBase {
  public adminView: boolean;

  constructor(
    private comentarioService: ComentariosService,
    private translateService: TranslateService,
  ) {
    super();
  }

  public async editMessage(id: number, mensaje: ComentarioDTO): Promise<void> {
    await this.comentarioService.editarMensajesPerfilUsuario(id, mensaje);
  }

  public async deleteMessage(id: number, mensaje: ComentarioDTO): Promise<void> {
    await this.comentarioService.borrarMensajesPerfilUsuario(id, mensaje);
  }

  public async sendMessage(id: number, mensaje: FormData): Promise<void> {
    await this.comentarioService.enviarMensajesPerfilUsuario(id, mensaje);
  }
  public async onInit(id: number): Promise<Array<ComentarioDTO>> {
    const comentario = await this.comentarioService.obtenerMensajesPerfilUsuario(id);
    return comentario;
  }

  public isPrivateDefault(): boolean {
    return true;
  }

  public getTitle(): string {
    return this.translateService.instant('GENERAL.NOTES');
  }

  public needPadding(): boolean {
    return false;
  }

  public canEditMessage(): boolean {
    return this.adminView;
  }

  public canDeleteMessage(): boolean {
    return this.adminView;
  }

  public getPlaceHolder(tipoVisibilidad: ComentarioVisibilidad): string {
    if (tipoVisibilidad === ComentarioVisibilidad.Publico) {
      return this.translateService.instant('COMMENTS.WRITE_NOTE');
    }
    return this.translateService.instant('COMMENTS.WRITE_PRIVATE_NOTE');
  }

  public getNoDataString(): string {
    return this.translateService.instant(this.adminView ? 'COMMENTS.NO_ADMIN_NOTES' : 'COMMENTS.NO_NOTES');
  }
}
