import { Component } from '@angular/core';
import { SidebarService } from './sidebar.service';

@Component({
  selector: 'neo-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  public openSate$ = this.sidebarService.openSate$;

  constructor(private sidebarService: SidebarService) {}
}
