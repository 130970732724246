<div class="d-flex flex-column gap-1 mx-2 p-relative" [ngClass]="conPadding ? 'pl-6 pr-6 pb-6' : ''">
  <label class="label-quill" *ngIf="titulo?.length > 0">{{ titulo }}</label>
  <quill-editor
    #quilleditor
    class="form-control mb-0"
    [modules]="quillModule"
    [placeholder]="placeholder"
    [(ngModel)]="comentario.mensaje"
    bounds="quill-editor"
  >
  </quill-editor>
  <app-select-puntaje *ngIf="comentarioHandler.allowStars()" (rating)="selectRating($event)" #selectPuntaje></app-select-puntaje>
  <app-file-selector *ngIf="showFileSelector" [(files)]="filesToAdd"></app-file-selector>
  <div class="d-flex flex-row mb-2 align-items-center justify-content-between">
    <div class="d-flex justify-content-start mx-2 align-items-center">
      <div *ngIf="comentarioHandler.showPrivate()">
        <mat-slide-toggle color="primary" [checked]="privadoDefault" (change)="toggleVisibilidad()">
          {{ 'GENERAL.PRIVATE' | translate }}
        </mat-slide-toggle>
        <small class="grey-text-color ml-2 mr-1 d-none d-lg-block"> {{ 'GENERAL.ADMINS_MENTIONED' | translate }}</small>
      </div>
      <button *ngIf="!showFileSelector" mat-button color="primary" (click)="showFileSelector = !showFileSelector">
        <span class="d-none d-lg-inline-flex"> {{ 'GENERAL.ATTACH_FILE' | translate }}</span>
        <mat-icon fontSet="material-symbols-outlined" iconPositionEnd>attach_file</mat-icon>
      </button>
    </div>
    <div class="d-flex justify-content-end mt-2">
      <button mat-raised-button color="primary" [disabled]="!comentario?.mensaje?.length" [command]="enviarCmd" [commandValue]="comentario">
        {{ 'COMMENTS.SAVE_ACTION' | translate }}
      </button>
    </div>
  </div>
  <div class="d-flex flex-column gap-1">
    <app-comentarios-lista [id]="id" [comentarioHandler]="comentarioHandler"></app-comentarios-lista>
  </div>
</div>
