export const MAT_ICONS = [
  'act-ethically',
  'avi',
  'chatgpt',
  'company-view-switch',
  'constantly-innovate',
  'csv',
  'doc',
  'excellence-in-your-work',
  'facebook',
  'feedback',
  'feedback-extra-1',
  'feedback-extra-2',
  'feedback-extra-3',
  'feedback-extra-4',
  'feedback-extra-5',
  'file',
  'goal-reached',
  'google-calendar',
  'google-maps',
  'google-maps-grey',
  'google-meet',
  'have-fun',
  'ics',
  'instagram',
  'jpg',
  'kudos',
  'linkedin',
  'mp3',
  'mp4',
  'pdf',
  'png',
  'ppt',
  'spotify',
  'team-player',
  'think-big',
  'twitter',
  'txt',
  'user-view-switch',
  'xls',
  'gran-estratega',
  'goat',
  'siempre-entrenando',
  'lider-del-equipo',
  'defiende-la-camiseta',
  'no-se-le-pasa-una',
];
