import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
  ActionsEntrega,
  DetalleEntregaModalComponent,
} from 'src/app/app-common/entregas/detalle-entrega-modal/detalle-entrega-modal.component';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { EstadoEntrega, PersonalEntregaDTO, TipoEntregaDTO } from 'src/app/ModelDTO/DTO';
import { Command, ICommand } from 'src/app/shared/lib/ngx-neo-directives-mat/public_api';
import { AlertButton, NgxNeoModalMatService } from 'src/app/shared/lib/ngx-neo-modal-mat/public_api';
import { SnackBarService } from 'src/app/shared/snack-bar/snack-bar.service';
import { compare } from 'src/app/shared/shared-functions';
import { ListadoDeEntregasViewModel } from 'src/app/layout/administrador/administrador-entregas/listado-de-entregas-agrupadas/listado-de-entregas-view.model';
import { ListadoDeEntregasService } from 'src/app/layout/administrador/administrador-entregas/listado-de-entregas.service';
import { DeliveryListFiltersModalComponent } from 'src/app/layout/administrador/administrador-entregas/delivery-list-filters-modal/delivery-list-filters-modal.component';
import {
  DeliveryListFilters,
  DeliveryListFiltersData,
} from 'src/app/layout/administrador/administrador-entregas/delivery-list-filters-modal/delivery-list-filters.interface';

export const DUED_RETURN = 'DUE_RETURN';

enum ColumnNames {
  Personal = 'Personal',
  Tipo = 'Tipo',
  CodReferencia = 'CodReferencia',
  Entrega = 'Entrega',
  Vencimiento = 'Vencimiento',
  Estado = 'Estado',
}
@Component({
  selector: 'app-listado-de-entregas',
  templateUrl: './listado-de-entregas.component.html',
  styleUrls: ['./listado-de-entregas.component.scss'],
})
export class ListadoDeEntregasComponent implements OnInit, OnDestroy {
  public readonly ColumnNames = ColumnNames;
  public displayedColumns: string[] = [
    ColumnNames.Personal,
    ColumnNames.Tipo,
    ColumnNames.CodReferencia,
    ColumnNames.Entrega,
    ColumnNames.Estado,
    ColumnNames.Vencimiento,
  ];
  public estadoEnum = EstadoEntrega;
  public small: boolean;
  public filtroCmd: ICommand = new Command(() => this.viewModel.filtrar(), new BehaviorSubject(true), true);
  public detalleEntregaCmd: ICommand = new Command((value) => this.verDetalleEntrega(value), new BehaviorSubject(true), false);
  public exportarEntregasCmd: ICommand = new Command(() => this.exportarEntregas(), new BehaviorSubject(true), true);

  private entregasXconvenio: Map<number, TipoEntregaDTO[]>;
  private currentFilters: DeliveryListFilters;
  private subscription = new Subscription();

  constructor(
    private headerService: HeaderAppService,
    public viewModel: ListadoDeEntregasViewModel,
    public listadoDeEntregasService: ListadoDeEntregasService,
    public activeRoute: ActivatedRoute,
    public router: Router,
    private dialog: MatDialog,
    private neoModalService: NgxNeoModalMatService,
    private snackBar: SnackBarService,
  ) {
    this.entregasXconvenio = new Map<number, TipoEntregaDTO[]>();
  }

  public async ngOnInit(): Promise<void> {
    this.currentFilters = {
      from: this.viewModel.desde,
      to: this.viewModel.hasta,
      state: this.viewModel.estadoEntrega,
      type: this.viewModel.tipoDeEntrega?.id > 0 ? this.viewModel.tipoDeEntrega : undefined,
    };

    await this.viewModel.loadData();
    this.subscription.add(
      this.headerService.smallScreen$.subscribe((small) => {
        this.small = small;
      }),
    );

    this.viewModel.tiposDeEntrega.forEach((type) => {
      const currentList = this.entregasXconvenio.get(type.convenio.id) ?? [];
      this.entregasXconvenio.set(type.convenio.id, [...currentList, type]);
    });

    this.subscription.add(
      this.activeRoute.queryParams.subscribe((params) => {
        this.handleParams(params);
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public sortData(sort: Sort): void {
    const data = this.viewModel.entregasFiltradas.slice();
    if (!sort.active || !sort.direction?.length) {
      this.viewModel.entregasFiltradas = data;
      return;
    }

    this.viewModel.entregasFiltradas = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case ColumnNames.Entrega:
          return compare(a.fechaPactada?.getTime(), b.fechaPactada?.getTime(), isAsc);
        case ColumnNames.Estado:
          return compare(this.estadoEnum[a.estado], this.estadoEnum[b.estado], isAsc);
        case ColumnNames.Personal:
          return compare(a.personalLegajo.nombreCompleto, b.personalLegajo.nombreCompleto, isAsc);
        case ColumnNames.Tipo:
          return compare(a.entrega.nombre, b.entrega.nombre, isAsc);
        case ColumnNames.CodReferencia:
          return compare(a.codigoDeReferencia, b.codigoDeReferencia, isAsc);
        case ColumnNames.Vencimiento:
          return compare(a.vencimientoAproximado?.getTime(), b.vencimientoAproximado?.getTime(), isAsc);
        default:
          return 0;
      }
    });
  }

  public openModalFilter(): void {
    const data: DeliveryListFiltersData = {
      ...this.currentFilters,
      entregasXconvenio: this.entregasXconvenio,
      deliveryStates: this.viewModel.estadosEntregas,
    };
    this.dialog
      .open(DeliveryListFiltersModalComponent, { data, panelClass: 'full-size-sm-600-lg-modal' })
      .afterClosed()
      .subscribe((filters: DeliveryListFilters) => {
        if (filters) {
          this.viewModel.desde = filters.from;
          this.viewModel.hasta = filters.to;
          this.viewModel.estadoEntrega = filters.state;
          this.viewModel.tipoDeEntrega = filters.type;
          this.viewModel.userSelected = filters.teammate;
          this.currentFilters = filters;
          this.filtroCmd.execute();
        }
      });
  }

  public async exportarEntregas(): Promise<void> {
    await this.viewModel.exportar();
  }

  public verDetalleEntrega(entregaDTO: PersonalEntregaDTO): void {
    const dialogRef = this.dialog.open(DetalleEntregaModalComponent, {
      disableClose: false,
      width: this.small ? '95%' : '600px',
      maxWidth: '95%',
      data: entregaDTO,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.actionEntrega === ActionsEntrega.DarDeBaja) {
          this.eliminarEntrega(result.entregaDTO);
        } else if (result.actionEntrega === ActionsEntrega.CambiarEstado) {
          this.cambiarEstadoEntrega(result.entregaDTO).then((update) => {
            if (update) {
              // eslint-disable-next-line no-param-reassign
              entregaDTO.estado = result.entregaDTO.estado;
            }
          });
        }
      }
    });
  }

  private async eliminarEntrega(entrega: PersonalEntregaDTO): Promise<void> {
    const res = await this.neoModalService.decision('¿Estás seguro que deseas eliminar la entrega?');
    if (res.ButtonResponse === AlertButton.Accept) {
      await this.listadoDeEntregasService.eliminarEntrega(entrega);
      const index = this.viewModel.entregasFiltradas.findIndex((x) => x.id === entrega.id);
      this.viewModel.entregasFiltradas.splice(index, 1);
      this.viewModel.entregasFiltradas = [...this.viewModel.entregasFiltradas];
    }
  }

  private async cambiarEstadoEntrega(entrega: PersonalEntregaDTO): Promise<boolean> {
    const res = await this.neoModalService.decision('¿Estás seguro que deseas cambiar el estado de la entrega?');
    if (res.ButtonResponse === AlertButton.Accept) {
      await this.listadoDeEntregasService.cambiarEstadoDeEntrega(entrega);
      await this.viewModel.filtrar();
      this.snackBar.showInfo('GENERAL.SUCCESSFUL_MODIFICATION');
      return true;
    }
    return false;
  }

  private async handleParams(params: Params): Promise<void> {
    this.viewModel.estadoEntrega = params.status ?? EstadoEntrega[EstadoEntrega.No_definido];
    await this.viewModel.filtrar();
  }
}
