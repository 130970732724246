import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import { LinkPublicoDTO } from 'src/app/ModelDTO/DTO';
import { ComentarioDTO } from 'src/app/ModelDTO/DTO/comentario.DTO';
import { ReclutamientoCandidatoDTO } from 'src/app/ModelDTO/DTO/reclutamientoCandidato.DTO';
import { ReclutamientoCandidatoNuevaFaseDTO } from 'src/app/ModelDTO/DTO/reclutamientoCandidatoNuevaFase.DTO';
import { ReclutamientoCandidatoParaFaseDTO } from 'src/app/ModelDTO/DTO/reclutamientoCandidatoParaFase.DTO';
import { ReclutamientoCandidatoRecomendadoDTO } from 'src/app/ModelDTO/DTO/reclutamientoCandidatoRecomendado.DTO';
import { ReclutamientoFaseCandidatoDTO } from 'src/app/ModelDTO/DTO/reclutamientoFaseCandidato.DTO';
import { AuditLogEntryDTO, DataDTO, ImageEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { Constants } from '../shared/constants';

@Injectable({
   providedIn: 'root'
})
export class ReclutamientoCandidatosServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getReclutamientoCandidatosIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/reclutamientoCandidatos/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getReclutamientoCandidatosId(id: number): Promise<ReclutamientoCandidatoDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/reclutamientoCandidatos/' + id));
         if (!res) { return null; }
         const resDTO = new ReclutamientoCandidatoDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getReclutamientoCandidatosIdComentarios(id: number): Promise<Array<ComentarioDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/reclutamientoCandidatos/' + id + '/comentarios'));
         const resJson = res.data;
         const resDTO = new Array<ComentarioDTO>();
         for (const item of resJson) {
            const itemDTO = new ComentarioDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertReclutamientoCandidatosIdComentario(id: number, stream: FormData): Promise<void> {

         await lastValueFrom(this.http.post(Constants.apiURL + '/reclutamientoCandidatos/' + id + '/comentario', stream));

   }

   public async getReclutamientoCandidatosEmail(email: string): Promise<ReclutamientoCandidatoDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/reclutamientoCandidatos/email' + '?email=' + email));
         if (!res) { return null; }
         const resDTO = new ReclutamientoCandidatoDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async insertReclutamientoCandidatos(reclutamientoCandidatoInputDTO: FormData): Promise<ReclutamientoCandidatoDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/reclutamientoCandidatos/', reclutamientoCandidatoInputDTO));
         const resDTO = new ReclutamientoCandidatoDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateReclutamientoCandidatosId(id: number, reclutamientoCandidatoInputDTO: FormData): Promise<ReclutamientoCandidatoDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/reclutamientoCandidatos/' + id, reclutamientoCandidatoInputDTO));
         const resDTO = new ReclutamientoCandidatoDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async insertReclutamientoCandidatosCandidateIdImage(id: number, imageEntityDTO: ImageEntityDTO): Promise<void> {

         await lastValueFrom(this.http.post(Constants.apiURL + '/reclutamientoCandidatos/candidate/' + id + '/Image', imageEntityDTO));

   }

   public async insertReclutamientoCandidatosIdFase(id: number, reclutamientoCandidatoNuevaFaseDTO: ReclutamientoCandidatoNuevaFaseDTO): Promise<void> {

         await lastValueFrom(this.http.post(Constants.apiURL + '/reclutamientoCandidatos/' + id + '/fase', reclutamientoCandidatoNuevaFaseDTO));

   }

   public async deleteReclutamientoCandidatosId(id: number): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/reclutamientoCandidatos/' + id));

   }

   public async deleteReclutamientoCandidatosIdOfertaIDOFERTAPostulaciones(id: number, iDOferta: number): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/reclutamientoCandidatos/' + id + '/oferta/' + iDOferta + '/postulaciones'));

   }

   public async insertReclutamientoCandidatosIdRecomendados(id: number): Promise<void> {

         await lastValueFrom(this.http.post(Constants.apiURL + '/reclutamientoCandidatos/' + id + '/recomendados', null));

   }

   public async deleteReclutamientoCandidatosIdRecomendados(id: number): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/reclutamientoCandidatos/' + id + '/recomendados'));

   }

   public async getReclutamientoCandidatosRecomendados(pageNumber: number, pageSize: number, fechaDesde: Date, fechaHasta: Date, searchValue: string = '', sortActive: number = 0, isAsc: boolean = false): Promise<Array<ReclutamientoCandidatoRecomendadoDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/reclutamientoCandidatos/recomendados' + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&fechaDesde=' + fechaDesde?.toISOString() + '&fechaHasta=' + fechaHasta?.toISOString() + '&searchValue=' + searchValue + '&sortActive=' + sortActive + '&isAsc=' + isAsc));
         const resJson = res.data;
         const resDTO = new Array<ReclutamientoCandidatoRecomendadoDTO>();
         for (const item of resJson) {
            const itemDTO = new ReclutamientoCandidatoRecomendadoDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getReclutamientoCandidatosRecomendadosId(id: number): Promise<ReclutamientoCandidatoDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/reclutamientoCandidatos/recomendados/' + id));
         if (!res) { return null; }
         const resDTO = new ReclutamientoCandidatoDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getReclutamientoCandidatosRecomendadosIdCvPublicLink(id: number): Promise<LinkPublicoDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/reclutamientoCandidatos/recomendados/' + id + '/cv-public-link'));
         if (!res) { return null; }
         const resDTO = new LinkPublicoDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getReclutamientoCandidatosCommunityId(id: number): Promise<ReclutamientoCandidatoDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/reclutamientoCandidatos/community/' + id));
         if (!res) { return null; }
         const resDTO = new ReclutamientoCandidatoDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async insertReclutamientoCandidatosRecomendadosIdTalento(id: number, reclutamientoCandidatoDTO: ReclutamientoCandidatoDTO): Promise<void> {

         await lastValueFrom(this.http.post(Constants.apiURL + '/reclutamientoCandidatos/recomendados/' + id + '/talento', reclutamientoCandidatoDTO));

   }

   public insertReclutamientoCandidatosRecomendadosIdImportar(id: number): Observable<object> {
         return this.http.post(Constants.apiURL + '/reclutamientoCandidatos/recomendados/' + id + '/importar', null);
   }

   public async getReclutamientoCandidatosOfertaIdFaseIDFASE(id: number, iDFase: number, pageNumber: number, pageSize: number, fechaDesde: Date, fechaHasta: Date, searchValue: string = '', sortActive: number = 0, isAsc: boolean = false): Promise<Array<ReclutamientoCandidatoParaFaseDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/reclutamientoCandidatos/oferta/' + id + '/fase/' + iDFase + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&fechaDesde=' + fechaDesde?.toISOString() + '&fechaHasta=' + fechaHasta?.toISOString() + '&searchValue=' + searchValue + '&sortActive=' + sortActive + '&isAsc=' + isAsc));
         const resJson = res.data;
         const resDTO = new Array<ReclutamientoCandidatoParaFaseDTO>();
         for (const item of resJson) {
            const itemDTO = new ReclutamientoCandidatoParaFaseDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getReclutamientoCandidatosOfertaId(id: number, pageNumber: number, pageSize: number, fechaDesde: Date, fechaHasta: Date, searchValue: string = '', sortActive: number = 0, isAsc: boolean = false, rejectionReasonId = 0): Promise<Array<ReclutamientoCandidatoParaFaseDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/reclutamientoCandidatos/oferta/' + id + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&fechaDesde=' + fechaDesde?.toISOString() + '&fechaHasta=' + fechaHasta?.toISOString() + '&searchValue=' + searchValue + '&sortActive=' + sortActive + '&isAsc=' + isAsc + '&rejectionReasonId=' + rejectionReasonId));
         const resJson = res.data;
         const resDTO = new Array<ReclutamientoCandidatoParaFaseDTO>();
         for (const item of resJson) {
            const itemDTO = new ReclutamientoCandidatoParaFaseDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getReclutamientoCandidatosIdOfertaIDOFERTA(id: number, iDOferta: number): Promise<ReclutamientoFaseCandidatoDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/reclutamientoCandidatos/' + id + '/oferta/' + iDOferta));
         if (!res) { return null; }
         const resDTO = new ReclutamientoFaseCandidatoDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateReclutamientoCandidatosIdOfertaIDOFERTAPostulacionesUnreject(id: number, iDOferta: number): Promise<void> {

       await lastValueFrom(this.http.put(Constants.apiURL + '/reclutamientoCandidatos/' + id + '/oferta/' + iDOferta + '/postulaciones/unreject', null));

 }

 public async updateReclutamientoCandidatosIdComentariosIDCOMENTARIO(id: number, iDComentario: number, comentarioDTO: ComentarioDTO): Promise<ComentarioDTO> {
      const res = await lastValueFrom(this.http.put(Constants.apiURL + '/reclutamientoCandidatos/' + id + '/comentarios/' + iDComentario, comentarioDTO));
      const resDTO = new ComentarioDTO();
      resDTO.PrepareDTO(res);
      return resDTO;
}

public async deleteReclutamientoCandidatosIdComentariosIDCOMENTARIO(id: number, iDComentario: number): Promise<void> {
      await lastValueFrom(this.http.delete(Constants.apiURL + '/reclutamientoCandidatos/' + id + '/comentarios/' + iDComentario));
}

}
