import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxMaskModule } from 'ngx-mask';
import { QuillModule } from 'ngx-quill';
import { CircleImageComponent } from 'src/app/shared/circle-image/circle-image.component';
import { LoadingDirective } from 'src/app/shared/directives/loading.directive';
import { NgxNeoDirectivesModule } from 'src/app/shared/lib/ngx-neo-directives-mat/public_api';
import { SelectorAreaComponent } from 'src/app/shared/selector-area/selector-area.component';
import { PuntajeViewComponent } from 'src/app/layout/reclutamiento/components/puntaje-view/puntaje-view.component';
import { SelectPuntajeComponent } from 'src/app/layout/reclutamiento/components/select-puntaje/select-puntaje.component';
import { SelectAccountModalComponent } from 'src/app/login/select-account-modal/select-account-modal.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { AttachmentSelectorComponent } from './attachment-selector/attachment-selector.component';
import { CalendarHeaderComponent } from './calendar-header/calendar-header.component';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { TrimDirective } from './directives/trim.directive';
import { EmpleadosFiltrosModalComponent } from './empleados-filtros-modal/empleados-filtros-modal.component';
import { NgIfFeatureFlagDirective } from './feature-flags/ng-if-feature-flag.directive';
import { NgIfNotFeatureFlagDirective } from './feature-flags/ng-if-not-feature-flag.directive';
import { FileChipsComponent } from './file-chips/file-chips.component';
import { FooterPublicComponent } from './footer-public/footer-public.component';
import { FormMatMenuExecutionsComponent } from './form-mat-menu-executions/form-mat-menu-executions.component';
import { HeaderImageComponent } from './header-image/header-image/header-image.component';
import { IconSelectorComponent } from './icon-selector/icon-selector.component';
import { IncludeHolidaysSaturdaySundayComponent } from './include-holidays-saturday-sunday/include-holidays-saturday-sunday.component';
import { NgxDatePipe } from './lib/ngx-neo-pipes/ngx-date.pipe';
import { NgxNeoPipesModule } from './lib/ngx-neo-pipes/ngx-neo-pipes.module';
import { PasswordStrengthComponent } from './password-strength/password-strength.component';
import { RefreshPasswordDialogComponent } from './refresh-password-dialog/refresh-password-dialog.component';
import { SeleccionarProfileImgComponent } from './seleccionar-profile-img/seleccionar-profile-img.component';
import { SelectAndCropComponent } from './select-and-crop/select-and-crop.component';
import { SignaturePreviewComponent } from './signature-preview/signature-preview.component';
import { SpinnerModule } from './spinner/spinner.module';
import { WarningCardComponent } from './warning-card/warning-card.component';

@NgModule({
  declarations: [
    SelectAndCropComponent,
    AttachmentSelectorComponent,
    SelectAccountModalComponent,
    FileChipsComponent,
    NgIfNotFeatureFlagDirective,
    DragAndDropDirective,
    CalendarHeaderComponent,
    SignaturePreviewComponent,
    IconSelectorComponent,
    PasswordStrengthComponent,
    IncludeHolidaysSaturdaySundayComponent,
    HeaderImageComponent,
    EmpleadosFiltrosModalComponent,
    RefreshPasswordDialogComponent,
    PuntajeViewComponent,
    SelectPuntajeComponent,
    SeleccionarProfileImgComponent,
    TrimDirective,
    FooterPublicComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ImageCropperModule,
    NgxNeoDirectivesModule,
    ReactiveFormsModule,
    NgxMaskModule,
    QuillModule,
    PdfViewerModule,
    NgxNeoPipesModule,
    SpinnerModule,
    TranslateModule,
    CircleImageComponent,
    WarningCardComponent,
    LoadingDirective,
    NgxDatePipe,
    FormMatMenuExecutionsComponent,
    NgIfFeatureFlagDirective,
    MatTooltipModule,
    SelectorAreaComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatMenuModule,
    MatTableModule,
    MatDialogModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatSortModule,
  ],
  exports: [
    SelectAndCropComponent,
    AttachmentSelectorComponent,
    SelectAccountModalComponent,
    FileChipsComponent,
    NgIfFeatureFlagDirective,
    NgIfNotFeatureFlagDirective,
    DragAndDropDirective,
    SignaturePreviewComponent,
    IconSelectorComponent,
    PasswordStrengthComponent,
    IncludeHolidaysSaturdaySundayComponent,
    HeaderImageComponent,
    EmpleadosFiltrosModalComponent,
    RefreshPasswordDialogComponent,
    PuntajeViewComponent,
    SelectPuntajeComponent,
    SeleccionarProfileImgComponent,
    TrimDirective,
    FooterPublicComponent,
  ],
})
export class SharedModule {}
