<div class="d-flex flex-column flex-fill">
  <div class="d-flex flex-column flex-fill">
    <div class="d-flex flex-row justify-content-between pl-2 my-2">
      <div class="d-flex flex-row">
        <app-circle-image [width]="40" [center]="false" [personalLegajo]="comment.personalLegajo"> </app-circle-image>
        <div class="d-flex flex-column pl-2">
          <small class="font-small">
            {{ comment.personalLegajo.nombreCompleto }}
            <i *ngIf="comment.fechaEditado">
              &nbsp;({{ 'COMMENTS.EDIT_ON' | translate }} {{ comment.fechaEditado | ngxdate: 'fullDateShortTime24' }})
            </i>
            <mat-icon fontSet="material-symbols-outlined" class="md-18 px-2" *ngIf="comment.visibilidad === 2">lock</mat-icon>
          </small>
          <div class="d-flex flex-row">
            <small *ngIf="comment.stars" class="primary-color"> {{ '★'.repeat(comment.stars).padEnd(5, '☆') }}&nbsp; </small>
            <small class="light-gray-text-color">
              {{ 'COMMENTS.SENT_ON' | translate }} {{ comment.fecha | ngxdate: 'fullDateShortTime24' }}
            </small>
          </div>
          <div class="d-flex flex-column my-2">
            <quill-view-html (click)="clickComment($event)" [content]="comment.mensaje"></quill-view-html>
            <app-file-chips class="mt-4" *ngIf="comment.adjuntos?.length > 0" [attachments]="comment.adjuntos" [noFilesMessage]="''">
            </app-file-chips>
          </div>
          <div class="d-flex flex-wrap align-items-center">
            <app-reaction-list (emojiClicked)="clickedEmoji($event)">
              <div [matTooltip]="reactionTooltipKey | translate" class="material-xsmall">
                <button
                  mat-stroked-button
                  class="add-reaction-button mt-1"
                  [disabled]="reactionService.$reactionLimitReached()"
                  [matMenuTriggerFor]="emojiMenu"
                  #emojiMenuTrigger="matMenuTrigger"
                >
                  <mat-icon fontSet="material-symbols-outlined">add_reaction</mat-icon>
                </button>
              </div>
            </app-reaction-list>
            <mat-menu class="width-custom" #emojiMenu="matMenu" [hasBackdrop]="true">
              <div (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
                <emoji-mart
                  (emojiSelect)="clickedEmoji($event.emoji.native)"
                  [showPreview]="false"
                  [isNative]="true"
                  [totalFrequentLines]="1"
                  [darkMode]="false"
                  [i18n]="currentLanguage | ngxEmojiTranslate"
                ></emoji-mart>
              </div>
            </mat-menu>
          </div>
        </div>
      </div>

      <div *ngIf="comentarioHandler.canEditMessage() && comentarioHandler.canDeleteMessage()" class="d-flex">
        <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="comment.canDelete || comment.canEdit">
          <mat-icon fontSet="material-symbols-outlined">more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu" class="width-custom">
          <button mat-menu-item (click)="editComment()" *ngIf="comment.canEdit">{{ 'GENERAL.EDIT' | translate }}</button>
          <button mat-menu-item (click)="deleteComment()" *ngIf="comment.canDelete">
            {{ 'GENERAL.DELETE' | translate }}
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
  <div *ngIf="comment.inEdition" class="d-flex flex-column flex-fill mx-2 gap-1 flex-fill">
    <quill-editor #quilleditor class="form-control" [modules]="quillModule" [(ngModel)]="comment.mensaje" bounds="quill-editor">
    </quill-editor>
    <div class="d-flex flex-row mb-2 align-items-center justify-content-end">
      <button class="ml-2" mat-raised-button color="primary" (click)="saveComment()">
        {{ 'GENERAL.SAVE' | translate }}
      </button>
      <button class="ml-2" mat-raised-button (click)="comment.inEdition = undefined">
        {{ 'GENERAL.CANCEL' | translate }}
      </button>
    </div>
  </div>
</div>
