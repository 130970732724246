import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';

export class PendingTaskUserDTO implements IEntityDTO {

   ownerId = 0;
   ownerName = '';
   ownerLastname = '';
   ownerImage = '';
   pendingTasks = 0;
   overdueTasks = 0;
   nextDue: Date;
   id = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.ownerId != null) { this.ownerId = jsonObj.ownerId; }
      if (jsonObj.ownerName != null) { this.ownerName = jsonObj.ownerName; }
      if (jsonObj.ownerLastname != null) { this.ownerLastname = jsonObj.ownerLastname; }
      if (jsonObj.ownerImage != null) { this.ownerImage = jsonObj.ownerImage; }
      if (jsonObj.pendingTasks != null) { this.pendingTasks = jsonObj.pendingTasks; }
      if (jsonObj.overdueTasks != null) { this.overdueTasks = jsonObj.overdueTasks; }
      if (jsonObj.nextDue != null) { this.nextDue = new Date(jsonObj.nextDue); }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
