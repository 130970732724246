import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComentarioDTO } from 'src/app/ModelDTO/DTO/comentario.DTO';
import { CommentReactionDTO } from 'src/app/ModelDTO/DTO/commentReaction.DTO';
import { NewCommentReactionDTO } from 'src/app/ModelDTO/DTO/newCommentReaction.DTO';
import { AuditLogEntryDTO, DataDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { Constants } from '../shared/constants';
import { lastValueFrom } from 'rxjs';

@Injectable({
   providedIn: 'root'
})
export class CommentServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getCommentsIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/comments/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getCommentsIdReactions(id: number): Promise<Array<CommentReactionDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/comments/' + id + '/reactions'));
         const resJson = res.data;
         const resDTO = new Array<CommentReactionDTO>();
         for (const item of resJson) {
            const itemDTO = new CommentReactionDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async updateCommentsIdReactions(id: number, newCommentReactionDTO: NewCommentReactionDTO): Promise<ComentarioDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/comments/' + id + '/reactions', newCommentReactionDTO));
         const resDTO = new ComentarioDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deleteCommentsIdReactionsEMOJIID(id: number, emojiId: string): Promise<ComentarioDTO> {

         const res = await lastValueFrom(this.http.delete(Constants.apiURL + '/comments/' + id + '/reactions/' + emojiId));
         const resDTO = new ComentarioDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

}
