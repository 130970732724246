import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PersonalLegajoBasicoDTO as PersonalLegajoBasicoDTOInterface } from '@api/interfaces/personal-legajo-basico.interface';
import { ReclutamientoCandidatoDTO as ReclutamientoCandidatoDTOInterface } from '@api/interfaces/reclutamiento-candidato.interface';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { PerfilEmpleadoSharedService } from 'src/app/app-common/perfil-empleado-shared/perfil-empleado-shared.service';
import { OrganigramaService } from 'src/app/layout/administrador/colaboradores/organigrama.service';
import { PersonalExtendModel } from 'src/app/layout/administrador/colaboradores/PersonalExtend.model';
import { PersonalModelDTO } from 'src/app/ModelDTO';
import { PersonalDTO, PersonalLegajoBasicoDTO, PersonalLegajoDTO } from 'src/app/ModelDTO/DTO';
import { ReclutamientoCandidatoDTO } from 'src/app/ModelDTO/DTO/reclutamientoCandidato.DTO';
import { PersonalServiceBackend } from 'src/app/ServiceBackend';
import { Command, ICommand, NgxNeoDirectivesModule } from 'src/app/shared/lib/ngx-neo-directives-mat/public_api';
import { ProfileImageThumbnailUrlPipe } from 'src/app/shared/pipes/profile-image-thumbnail-url.pipe';
import { UserTeammateFile } from './user-legajo.interface';

@Component({
  selector: 'app-circle-image',
  templateUrl: './circle-image.component.html',
  styleUrls: ['./circle-image.component.scss'],
  standalone: true,
  imports: [CommonModule, NgxNeoDirectivesModule, MatTooltipModule, TranslateModule],
  providers: [ProfileImageThumbnailUrlPipe],
})
export class CircleImageComponent {
  @Input() public width = 100;
  @Input() public fontSize = 'medium';
  @Input() public circle = true;
  @Input() public center = false;
  @Input() public invalid = false;

  @Input() public stopPropagation = true;
  @Input() public openProfile = true;
  @Input() public borderColor = 'whitesmoke';
  @Input() public borderWidth = '3px';
  @Input() public showLabel = true;

  @Input() public set imageSource(value: string) {
    this.image = value;
    this.updateImageSource();
  }

  @Input() public set personalLegajo(
    personalLegajo:
      | PersonalLegajoBasicoDTO
      | PersonalLegajoDTO
      | PersonalExtendModel
      | PersonalDTO
      | ReclutamientoCandidatoDTO
      | ReclutamientoCandidatoDTOInterface
      | PersonalLegajoBasicoDTOInterface
      | UserTeammateFile,
  ) {
    this.user = personalLegajo;
    if (this.user) {
      if (this.user.nombreCompleto) {
        const split = (this.user.nombreCompleto as string).split(' ');
        this.initials = split[0].substring(0, 1) + (split.length > 1 ? split[1].substring(0, 1) : '');
      } else {
        this.initials = (this.user.apellido?.substring(0, 1) ?? '') + (this.user.nombre?.substring(0, 1) ?? '');
      }
    }
    this.updateImageSource();
  }

  public initials: string;
  public user;
  public backgroundImage: string;

  public openDetailsCmd: ICommand = new Command(() => this.openDetails(), new BehaviorSubject(true), true);

  public get imageSource(): string {
    return this.image;
  }

  private image: string;

  constructor(
    private dialog: MatDialog,
    private perfilEmpleadoSharedService: PerfilEmpleadoSharedService,
    private organigramaService: OrganigramaService,
    private profileImagePipe: ProfileImageThumbnailUrlPipe,
    private personalServiceBackend: PersonalServiceBackend,
  ) {}

  public async openDetails(): Promise<void> {
    if (!this.user?.id || !this.openProfile) return;
    this.dialog.closeAll();
    let personal: PersonalModelDTO;
    if (this.user instanceof PersonalLegajoBasicoDTO || this.user instanceof PersonalLegajoDTO) {
      personal = new PersonalModelDTO(await this.personalServiceBackend.getPersonalLegajoId(this.user.id));
    } else if (this.user.teammateFileId) {
      personal = new PersonalModelDTO(await this.personalServiceBackend.getPersonalLegajoId(this.user.teammateFileId));
    } else {
      personal = await this.perfilEmpleadoSharedService.obtenerPersonal(this.user.id);
    }
    this.organigramaService.openPersonalDetails(personal.getEntityDTO());
  }

  public getFullName(): string {
    if (this.user?.nombreCompleto) {
      return this.user.nombreCompleto;
    }

    if (this.user?.nombre && this.user?.apellido) {
      return `${this.user?.nombre} ${this.user?.apellido}`;
    }

    return '';
  }

  public updateImageSource(): void {
    this.backgroundImage = `url(${this.imageSource ? this.imageSource : this.profileImagePipe.transform(this.user, this.width)})`;
  }
}
