import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AddresseesDTO, AssignmentType } from 'src/app/ModelDTO/DTO';
import { CambioDTO } from 'src/app/ModelDTO/DTO/cambio.DTO';
import { CambiosHistoricosDTO } from 'src/app/ModelDTO/DTO/cambiosHistoricos.DTO';
import { ComentarioDTO } from 'src/app/ModelDTO/DTO/comentario.DTO';
import { ConfiguracionFirmaDigitalPosicionDTO } from 'src/app/ModelDTO/DTO/configuracionFirmaDigitalPosicion.DTO';
import { ContingenciaDTO } from 'src/app/ModelDTO/DTO/contingencia.DTO';
import { CuentaCorrienteDTO } from 'src/app/ModelDTO/DTO/cuentaCorriente.DTO';
import { EstadisticasFirmasDTO } from 'src/app/ModelDTO/DTO/estadisticasFirmas.DTO';
import { FichajeBasicoDTO } from 'src/app/ModelDTO/DTO/fichajeBasico.DTO';
import { IPersonalOrganigramaDTO } from 'src/app/ModelDTO/DTO/iPersonalOrganigrama.DTO';
import { PersonalFeedbackDTO } from 'src/app/ModelDTO/DTO/personalFeedback.DTO';
import { PersonalFeedbackResumenDTO } from 'src/app/ModelDTO/DTO/personalFeedbackResumen.DTO';
import { PersonalLegajoDTO } from 'src/app/ModelDTO/DTO/personalLegajo.DTO';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { ReferidoDTO } from 'src/app/ModelDTO/DTO/referido.DTO';
import { SaldoCuentaCorrienteDTO } from 'src/app/ModelDTO/DTO/saldoCuentaCorriente.DTO';
import { TurnosDiaAgenteDTO } from 'src/app/ModelDTO/DTO/turnosDiaAgente.DTO';
import { AuditLogEntryDTO, AuthResponseDTO, DataDTO, NamedBlobDTO, NotificationDTO, SignatureType } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { Constants } from '../shared/constants';
import { IDataFilteredOptions } from '../shared/teammates-selector/advance-teammate-selector/advance-teammate-selector.component';

@Injectable({
   providedIn: 'root'
})
export class PersonalLegajoServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getPersonalLegajosIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/personalLegajos/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getPersonalLegajos(activos: boolean = false, orgfilterActive: boolean = false): Promise<Array<PersonalLegajoBasicoDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/personalLegajos/' + '?activos=' + activos + '&orgfilterActive=' + orgfilterActive));
         const resJson = res.data;
         const resDTO = new Array<PersonalLegajoBasicoDTO>();
         for (const item of resJson) {
            const itemDTO = new PersonalLegajoBasicoDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getPersonalLegajosXLSX(activos: boolean = false, orgfilterActive: boolean = false, namedBlob: NamedBlobDTO = null): Promise<Blob> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/personalLegajos/' + '?activos=' + activos + '&orgfilterActive=' + orgfilterActive + '&BLOB=XLSX', { observe: 'response', responseType: 'blob' }));
         const resDTO = new Blob([res.body], { type: 'application/text' });
         if (namedBlob) {
            namedBlob.setBlobNameFromHttpResponse(res);
            namedBlob.blob = res.body;
         }
         return resDTO;
   }

   public async getPersonalLegajosPorCargoidCargo(idCargo: number): Promise<Array<PersonalLegajoBasicoDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/personalLegajos/porCargo/' + idCargo));
         const resJson = res.data;
         const resDTO = new Array<PersonalLegajoBasicoDTO>();
         for (const item of resJson) {
            const itemDTO = new PersonalLegajoBasicoDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getPersonalLegajosPorAreaIDAREA(idArea: number): Promise<Array<PersonalLegajoDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/personalLegajos/porArea/' + idArea));
         const resJson = res.data;
         const resDTO = new Array<PersonalLegajoDTO>();
         for (const item of resJson) {
            const itemDTO = new PersonalLegajoDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getPersonalLegajosAutoComplete(contiene: string, pageSize: number, todos: boolean = false): Promise<Array<PersonalLegajoBasicoDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/personalLegajos/autoComplete' + '?contiene=' + contiene + '&pageSize=' + pageSize + '&todos=' + todos));
         const resJson = res.data;
         const resDTO = new Array<PersonalLegajoBasicoDTO>();
         for (const item of resJson) {
            const itemDTO = new PersonalLegajoBasicoDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getPersonalLegajosSearch(searchValue: string, all: boolean = false): Promise<Array<PersonalLegajoBasicoDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/personalLegajos/search' + '?searchValue=' + searchValue + '&all=' + all));
         const resJson = res.data;
         const resDTO = new Array<PersonalLegajoBasicoDTO>();
         for (const item of resJson) {
            const itemDTO = new PersonalLegajoBasicoDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getPersonalLegajosAutoCompleteDniCuil(contiene: string, pageSize: number): Promise<Array<PersonalLegajoBasicoDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/personalLegajos/autoCompleteDniCuil' + '?contiene=' + contiene + '&pageSize=' + pageSize));
         const resJson = res.data;
         const resDTO = new Array<PersonalLegajoBasicoDTO>();
         for (const item of resJson) {
            const itemDTO = new PersonalLegajoBasicoDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async updatePersonalLegajosIdCambioRol(id: number, personalLegajoBasicoDTO: PersonalLegajoBasicoDTO): Promise<void> {

         await lastValueFrom(this.http.put(Constants.apiURL + '/personalLegajos/' + id + '/cambioRol', personalLegajoBasicoDTO));

   }

   public async updatePersonalLegajosIdEstadoOwner(id: number, personalLegajoBasicoDTO: PersonalLegajoBasicoDTO): Promise<void> {

         await lastValueFrom(this.http.put(Constants.apiURL + '/personalLegajos/' + id + '/estadoOwner', personalLegajoBasicoDTO));

   }

   public async insertPersonalLegajosIdInvalidarToken(id: number): Promise<void> {

         await lastValueFrom(this.http.post(Constants.apiURL + '/personalLegajos/' + id + '/invalidarToken', null));

   }

   public async getPersonalLegajosIdContingencia(id: number): Promise<ContingenciaDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/personalLegajos/' + id + '/contingencia'));
         if (!res) { return null; }
         const resDTO = new ContingenciaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getPersonalLegajosIdHistorialCambios(id: number): Promise<CambiosHistoricosDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/personalLegajos/' + id + '/historialCambios'));
         if (!res) { return null; }
         const resDTO = new CambiosHistoricosDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updatePersonalLegajosIdHistorialCambiosIDCambio(id: number, iDCambio: number, cambioDTO: CambioDTO): Promise<CambioDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/personalLegajos/' + id + '/historialCambios/' + iDCambio, cambioDTO));
         const resDTO = new CambioDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deletePersonalLegajosIdHistorialCambiosIDCambio(id: number, iDCambio: number): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/personalLegajos/' + id + '/historialCambios/' + iDCambio));

   }

   public async getPersonalLegajosTurnosPropiosFECHA(fecha: string): Promise<Array<TurnosDiaAgenteDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/personalLegajos/turnosPropios/' + fecha));
         const resJson = res.data;
         const resDTO = new Array<TurnosDiaAgenteDTO>();
         for (const item of resJson) {
            const itemDTO = new TurnosDiaAgenteDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertPersonalLegajosNotificar(notificationToPersonalsDTO: FormData): Promise<void> {

         await lastValueFrom(this.http.post(Constants.apiURL + '/personalLegajos/notificar/', notificationToPersonalsDTO));

   }

   public async getPersonalLegajosFiltered(options: IDataFilteredOptions): Promise<Array<PersonalLegajoBasicoDTO>> {
      const { areaId = 0, sectorId = 0, contractTypeId = 0, officeId = 0 } = options;
      const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/personalLegajos/filtered' + '?areaId=' + areaId +
         '&sectorId=' + sectorId +
         '&contractTypeId=' + contractTypeId +
         '&officeId=' + officeId));
      const resJson = res.data;
      const resDTO = new Array<PersonalLegajoBasicoDTO>();
      for (const item of resJson) {
         const itemDTO = new PersonalLegajoBasicoDTO();
         itemDTO.PrepareDTO(item);
         resDTO.push(itemDTO);
      }
      return resDTO;
}

   public async getPersonalLegajosOrganigrama(area: number = 0, sector: number = 0, oficina: number = 0, equipo: boolean = false): Promise<IPersonalOrganigramaDTO> {

         const res = await lastValueFrom(this.http.get<IPersonalOrganigramaDTO>(Constants.apiURL + '/personalLegajos/organigrama' + '?area=' + area + '&sector=' + sector + '&oficina=' + oficina + '&equipo=' + equipo));
         if (!res) { return null; }
         return res;

   }

   public async getPersonalLegajosIDCargoSupervisorSugerido(iDCargo: number): Promise<PersonalLegajoBasicoDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/personalLegajos/' + iDCargo + '/supervisorSugerido'));
         if (!res) { return null; }
         const resDTO = new PersonalLegajoBasicoDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deletePersonalLegajosIdDNI(id: number, dNI: string): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/personalLegajos/' + id + '/' + dNI));

   }

   public async insertPersonalLegajosIdDeclaracionDomicilio(id: number): Promise<void> {

         await lastValueFrom(this.http.post(Constants.apiURL + '/personalLegajos/' + id + '/declaracion-domicilio', null));

   }

   public async getPersonalLegajosComunicadoIDCOMUNICADO(idComunicado: number): Promise<Array<NotificationDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/personalLegajos/comunicado/' + idComunicado));
         const resJson = res.data;
         const resDTO = new Array<NotificationDTO>();
         for (const item of resJson) {
            const itemDTO = new NotificationDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getPersonalLegajosIdArchivosConFirmas(id: number, fechaDesde: Date, fechaHasta: Date, period: Date, pageSize: number, pageNumber: number, vistaColaborador: boolean = false, sinArchivos: boolean = false, documentTypeId: number = 0, type: SignatureType = undefined, signaturesDone: boolean = false): Promise<EstadisticasFirmasDTO> {
      const res = await lastValueFrom(this.http.get(Constants.apiURL + '/personalLegajos/' + id + '/archivosConFirmas' + '?fechaDesde=' + fechaDesde?.toISOString() +
         '&fechaHasta=' + fechaHasta?.toISOString() +
         '&period=' + period?.toISOString() +
         '&pageSize=' + pageSize +
         '&pageNumber=' + pageNumber +
         '&vistaColaborador=' + vistaColaborador +
         '&sinArchivos=' + sinArchivos +
         '&documentTypeId=' + documentTypeId +
         '&type=' + type +
         '&signaturesDone=' + signaturesDone));
      if (!res) { return null; }
      const resDTO = new EstadisticasFirmasDTO();
      resDTO.PrepareDTO(res);
      return resDTO;
}

   public async insertPersonalLegajosIdAttachmentsIdAttachmentRecordarFirma(id: number, idAttachment: number, notificationDTO: NotificationDTO): Promise<void> {

         await lastValueFrom(this.http.post(Constants.apiURL + '/personalLegajos/' + id + '/attachments/' + idAttachment + '/recordarFirma', notificationDTO));

   }

   public async insertPersonalLegajosRecordarFirmas(notificationDTO: NotificationDTO): Promise<void> {

         await lastValueFrom(this.http.post(Constants.apiURL + '/personalLegajos/recordarFirmas', notificationDTO));

   }

   public async insertPersonalLegajosPropiaFirmaJPEG(configuracionFirmaDigitalPosicionDTO: ConfiguracionFirmaDigitalPosicionDTO, namedBlob: NamedBlobDTO = null): Promise<Blob> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/personalLegajos/propiaFirma' + '?BLOB=true', configuracionFirmaDigitalPosicionDTO, { observe: 'response', responseType: 'blob' }));
         const resDTO = new Blob([res.body], { type: 'image/jpeg' });
         if (namedBlob) {
            namedBlob.setBlobNameFromHttpResponse(res);
            namedBlob.blob = res.body;
         }
         return resDTO;
   }

   public async insertPersonalLegajosIdUserCertificate(id: number): Promise<PersonalLegajoDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/personalLegajos/' + id + '/user-certificate', null));
         const resDTO = new PersonalLegajoDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getPersonalLegajosIdComentarios(id: number): Promise<Array<ComentarioDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/personalLegajos/' + id + '/comentarios'));
         const resJson = res.data;
         const resDTO = new Array<ComentarioDTO>();
         for (const item of resJson) {
            const itemDTO = new ComentarioDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertPersonalLegajosIdComentarios(id: number, stream: FormData): Promise<void> {

         await lastValueFrom(this.http.post(Constants.apiURL + '/personalLegajos/' + id + '/comentarios', stream));

   }

   public async updatePersonalLegajosIdComentariosIDCOMENTARIO(id: number, iDComentario: number, comentarioDTO: ComentarioDTO): Promise<ComentarioDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/personalLegajos/' + id + '/comentarios/' + iDComentario, comentarioDTO));
         const resDTO = new ComentarioDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deletePersonalLegajosIdComentariosIDCOMENTARIO(id: number, iDComentario: number): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/personalLegajos/' + id + '/comentarios/' + iDComentario));

   }

   public async insertPersonalLegajosVistaFichaje(): Promise<AuthResponseDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/personalLegajos/vistaFichaje', null));
         const resDTO = new AuthResponseDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updatePersonalLegajosIdFichajesIDFICHAJE(id: number, iDFichaje: number, fichajeBasicoDTO: FichajeBasicoDTO): Promise<FichajeBasicoDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/personalLegajos/' + id + '/fichajes/' + iDFichaje, fichajeBasicoDTO));
         const resDTO = new FichajeBasicoDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getPersonalLegajosIdCuentasCorrientes(id: number): Promise<Array<CuentaCorrienteDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/personalLegajos/' + id + '/cuentasCorrientes'));
         const resJson = res.data;
         const resDTO = new Array<CuentaCorrienteDTO>();
         for (const item of resJson) {
            const itemDTO = new CuentaCorrienteDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertPersonalLegajosIdCuentasCorrientes(id: number, cuentaCorrienteDTO: CuentaCorrienteDTO): Promise<void> {

         await lastValueFrom(this.http.post(Constants.apiURL + '/personalLegajos/' + id + '/cuentasCorrientes', cuentaCorrienteDTO));

   }

   public async getPersonalLegajosIdSaldoAusenciaIDAusencia(id: number, iDAusencia: number): Promise<SaldoCuentaCorrienteDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/personalLegajos/' + id + '/saldoAusencia/' + iDAusencia));
         if (!res) { return null; }
         const resDTO = new SaldoCuentaCorrienteDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getPersonalLegajosFeedbackRecibidos(pageNumber: number, pageSize: number): Promise<Array<PersonalFeedbackDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/personalLegajos/feedback/recibidos' + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize));
         const resJson = res.data;
         const resDTO = new Array<PersonalFeedbackDTO>();
         for (const item of resJson) {
            const itemDTO = new PersonalFeedbackDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getPersonalLegajosFeedbackEnviados(pageNumber: number, pageSize: number): Promise<Array<PersonalFeedbackDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/personalLegajos/feedback/enviados' + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize));
         const resJson = res.data;
         const resDTO = new Array<PersonalFeedbackDTO>();
         for (const item of resJson) {
            const itemDTO = new PersonalFeedbackDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getPersonalLegajosIdFeedbackResumen(id: number): Promise<Array<PersonalFeedbackResumenDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/personalLegajos/' + id + '/feedback/resumen'));
         const resJson = res.data;
         const resDTO = new Array<PersonalFeedbackResumenDTO>();
         for (const item of resJson) {
            const itemDTO = new PersonalFeedbackResumenDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getPersonalLegajosIdFeedbackRecibidos(id: number, pageNumber: number, pageSize: number): Promise<Array<PersonalFeedbackDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/personalLegajos/' + id + '/feedback/recibidos' + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize));
         const resJson = res.data;
         const resDTO = new Array<PersonalFeedbackDTO>();
         for (const item of resJson) {
            const itemDTO = new PersonalFeedbackDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getPersonalLegajosIdFeedbackEnviados(id: number, pageNumber: number, pageSize: number): Promise<Array<PersonalFeedbackDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/personalLegajos/' + id + '/feedback/enviados' + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize));
         const resJson = res.data;
         const resDTO = new Array<PersonalFeedbackDTO>();
         for (const item of resJson) {
            const itemDTO = new PersonalFeedbackDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertPersonalLegajosReferir(referidoDTO: ReferidoDTO): Promise<ReferidoDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/personalLegajos/referir', referidoDTO));
         const resDTO = new ReferidoDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async insertPersonalLegajosWorkDayidWorkDayBulk(idWorkDay: number, assignmentType: AssignmentType, addresseesDTO: AddresseesDTO): Promise<void> {
       await lastValueFrom(this.http.post(Constants.apiURL + '/personalLegajos/work-day/' + idWorkDay + '/bulk' + '?assignmentType=' + assignmentType, addresseesDTO));
   }


   public async insertPersonalLegajosIdCertificadoEmpleoIdCargo(id: number, idCargo: number): Promise<void> {

         await lastValueFrom(this.http.post(Constants.apiURL + '/personalLegajos/' + id + '/certificado-empleo/' + idCargo, null));

   }

   public async updatePersonalLegajosContractDueContractId(contractId: number): Promise<void> {

       await lastValueFrom(this.http.put(Constants.apiURL + '/personalLegajos/contract-due/' + contractId, null));

 }

}
