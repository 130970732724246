import { Injectable } from '@angular/core';
import {
  SolicitudEntregaDTO,
  EstadoAprobacionSolicitud,
  PersonalEntregaDTO,
  EntregaAgrupadaDTO,
  TipoUnidadDevolucion,
  EstadoEntrega,
  TipoEntregaDTO,
  PersonalDTO,
} from 'src/app/ModelDTO/DTO';
import { PersonalEntregaServiceBackend } from 'src/app/ServiceBackend/personalEntrega.ServiceBackend';
import { SolicitudEntregaServiceBackend } from 'src/app/ServiceBackend';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { FileDownloaderService } from 'src/app/shared/services/fileDownloader.service';

@Injectable({
  providedIn: 'root',
})
export class ListadoDeEntregasService {
  public get PersonalAlta(): PersonalDTO {
    return this.personalAlta;
  }
  public set PersonalAlta(p: PersonalDTO) {
    this.personalAlta = p;
  }

  private personalAlta: PersonalDTO;

  constructor(
    private fileDownloaderService: FileDownloaderService,
    private personalEntrega: PersonalEntregaServiceBackend,
    private solicitudEntregaServiceBackend: SolicitudEntregaServiceBackend
  ) {}

  public async responderSolicitud(solicitud: SolicitudEntregaDTO): Promise<void> {
    await this.solicitudEntregaServiceBackend.updateSolicitudEntregaIdEstado(solicitud.id, solicitud);
  }

  public async obtenerSolicitudesFiltradas(
    pageNumber: number,
    pageSize: number,
    desde: Date,
    hasta: Date,
    estado: EstadoAprobacionSolicitud,
    idUser: number
  ): Promise<Array<SolicitudEntregaDTO>> {
    const res = await this.solicitudEntregaServiceBackend.getSolicitudEntregaFiltradas(desde, hasta, pageNumber, pageSize, estado, idUser);
    return res;
  }

  public async obtenerEntregasFiltradas(
    desde: Date,
    hasta: Date,
    estado: EstadoAprobacionSolicitud,
    tipoDeEntrega: TipoEntregaDTO,
    idUser: number,
    sinRechazos?: boolean,
    dueReturn?: boolean
  ): Promise<Array<PersonalEntregaDTO>> {
    let res = await this.personalEntrega.getEntregaPersonal(desde, hasta, estado, idUser, tipoDeEntrega.id, sinRechazos, dueReturn);
    res = res.filter((x) => !(x.conSolicitud && x.estado === EstadoEntrega.Rechazado));
    return res;
  }

  public async exportarEntregasFiltradasEnExcel(
    desde: Date,
    hasta: Date,
    estado: EstadoAprobacionSolicitud,
    tipoDeEntrega: TipoEntregaDTO,
    idUser: number,
    sinRechazos?: boolean
  ): Promise<void> {
    const blob = new NamedBlobDTO();
    await this.personalEntrega.getEntregaPersonalXLSX(desde, hasta, estado, idUser, tipoDeEntrega.id, sinRechazos, blob);
    this.fileDownloaderService.saveAs(blob);
  }

  public async agregarEntrega(idPersonal: number, formEntrega: FormData): Promise<PersonalEntregaDTO> {
    const res = await this.personalEntrega.insertEntregaPersonalIDPersonal(idPersonal, formEntrega);
    return res;
  }

  public async agregarEntregaMasivaCategoria(idCategoria: number, idSubcategoria: number, entrega: PersonalEntregaDTO): Promise<void> {
    await this.personalEntrega.insertEntregaMasivoIdCategoriaIdSubategoria(idCategoria, idSubcategoria, entrega);
  }

  public async agregarEntregaMasivaCargo(idCargo: number, entrega: PersonalEntregaDTO): Promise<void> {
    await this.personalEntrega.insertEntregaCargoMasivoIdCargo(idCargo, entrega);
  }

  public async eliminarEntrega(entrega: PersonalEntregaDTO): Promise<void> {
    await this.personalEntrega.deleteEntregaIdPersonalIDPersonal(entrega.id, entrega.personalLegajo.id);
  }

  public async eliminarSolicitudEntrega(entrega: SolicitudEntregaDTO): Promise<void> {
    await this.solicitudEntregaServiceBackend.deleteSolicitudEntregaIdSolicitudCancelar(entrega.id);
  }

  public async bajaDeEntrega(entrega: PersonalEntregaDTO): Promise<void> {
    entrega.estado = EstadoEntrega.Cancelado;
    await this.personalEntrega.updateEntregaIdCambioEstado(entrega.id, entrega);
  }

  public async obtenerEntregasAgrupadas(tipoDev: TipoUnidadDevolucion): Promise<Array<EntregaAgrupadaDTO>> {
    const res = await this.personalEntrega.getEntregaAgrupadas(tipoDev);
    return res;
  }

  public async cambiarEstadoDeEntrega(personalEntregaDTO: PersonalEntregaDTO): Promise<void> {
    await this.personalEntrega.updateEntregaIdCambioEstado(personalEntregaDTO.id, personalEntregaDTO);
  }

  public clonEntregaReducido(personalEntregaDTO: PersonalEntregaDTO): PersonalEntregaDTO {
    // Ni object assign ni prepareDTO sirven para clonar en profundidad.
    // Esto es un clon reducido. Solo necesito mostrar estos datos y modificar sólo el estado.
    const newPE = new PersonalEntregaDTO();
    newPE.id = personalEntregaDTO.id;
    newPE.descripcion = personalEntregaDTO.descripcion;
    newPE.entrega = personalEntregaDTO.entrega;
    newPE.estado = personalEntregaDTO.estado;
    newPE.conSolicitud = personalEntregaDTO.conSolicitud;
    newPE.entregaEfectiva = personalEntregaDTO.entregaEfectiva;
    newPE.vencimientoAproximado = personalEntregaDTO.vencimientoAproximado;
    newPE.personalLegajo = personalEntregaDTO.personalLegajo;
    newPE.fechaPactada = personalEntregaDTO.fechaPactada;
    return newPE;
  }

  public getRequestById(id: number): Promise<SolicitudEntregaDTO> {
    return this.solicitudEntregaServiceBackend.getSolicitudEntregaPorSolicitudId(id);
  }

  public dispose(): void {
    this.personalAlta = null;
  }
}
