import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

const CACHED_ENDPOINTS = {
  '/indicadores/poblacion': 300, // Se usa en dashboard y se recargan demasiadas veces, por eso 5 minutos
  '/indicadores/edades': 300, // Se usa en dashboard y se recargan demasiadas veces, por eso 5 minutos
  '/evento/proximos': 300, // Se usa en dashboard y se recargan demasiadas veces, por eso 5 minutos
  '/biblioteca/publicos/ultimos/3': 300, // Se usa en dashboard y se recargan demasiadas veces, por eso 5 minutos
  '/ausencias/siguientes?estado=2&pageSize=5': 60, // Se usa en dashboard y se recargan demasiadas veces, por eso 5 minutos
  '/indicadores/ausencias/totalesPorTipo': 60, // Se usa en dashboard y se recargan demasiadas veces, por eso 5 minutos
  '/empresas/0/status': 600, // Se usa en dashboard y se recargan demasiadas veces, por eso 5 minutos
  '/indicadores/antiguedad': 60, // Se usa en analítica y se recargan pocas veces, por eso solo 1 minuto
  '/indicadores/ausenciasPorMes': 60, // Se usa en analítica y se recargan pocas veces, por eso solo 1 minuto
  '/indicadores/bajastipo': 60, // Se usa en analítica y se recargan pocas veces, por eso solo 1 minuto
  '/indicadores/ausencias': 60, // Se usa en analítica y se recargan pocas veces, por eso solo 1 minuto
  '/indicadores/estados': 60, // Se usa en analítica y se recargan pocas veces, por eso solo 1 minuto
  '/indicadores/art': 60, // Se usa en analítica y se recargan pocas veces, por eso solo 1 minuto
  '/indicadores/rotacion': 60, // Se usa en analítica y se recargan pocas veces, por eso solo 1 minuto
  '/user/notifications/totalWithoutRead?type=0': 30, // Se usa en dashboard pero no esta bueno que permanezca mucho desactualizado porque son notificaciones
  '/tipoFeedback/': 300, // Se usa en distintos lugares y es algo que no debería cambiar todo el tiempo.
  '/paises/': 300, // No debería cambiar todo el tiempo.
  '/nivelEstudio/': 300, // No debería cambiar todo el tiempo.
  '/feriados/': 300, // No debería cambiar todo el tiempo.
  '/timezones/': 3600, // No debería cambiar todo el tiempo.
  '/news-format/': 3600, // No debería cambiar todo el tiempo.
  '/personalAreas/': 300, // Se usa en los filtros, no deberia cambiar tanto.
  '/oficinas/': 300, // Se usa en los filtros, no deberia cambiar tanto.
  '/cargos/': 300, // Se usa en los filtros, no deberia cambiar tanto.
  '/business-name/': 300, // Se usa en los filtros, no deberia cambiar tanto.
  '/modalidad/': 300, // Se usa en los filtros, no deberia cambiar tanto.
  '/convenioColectivo/': 300, // Se usa en los filtros, no deberia cambiar tanto.
  '/workDays/': 300, // Se usa en los filtros, no deberia cambiar tanto.
  '/teammates/recipients': 300, // Se usa en los filtros, no deberia cambiar tanto.
  '/recruitment-tag/?searchValue=': 300, // No debería cambiar todo el tiempo.
  '/usuariosroles/': 300, // No debería cambiar todo el tiempo.
  '/recruitment-rejection-reason/': 300, // No debería cambiar todo el tiempo.
  '/reclutamientoOfertas/dashboard/general-info': 300, // No debería cambiar todo el tiempo.
};
export type cacheKeys = keyof typeof CACHED_ENDPOINTS;

@Injectable({
  providedIn: 'root',
})
export class AppCacheService {
  private cache: Map<cacheKeys, HttpResponse<any>> = new Map();

  public getCachedEndpoints(): { [key: string]: number } {
    return { ...CACHED_ENDPOINTS };
  }

  public getCache(key: cacheKeys): any | undefined {
    return this.cache.get(key);
  }
  public setCache(key: cacheKeys, value: HttpResponse<any>): void {
    this.cache.set(key, value);
  }

  public clearCache(key: cacheKeys): void {
    this.cache.delete(key);
  }

  public clearAllCache(): void {
    this.cache = new Map();
  }
}
