import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TipoNotificacion } from '@api/enums/tipo-notificacion.enum';
import { NotificationAttachmentsDTO } from '@api/interfaces/notification-attachments.interface';
import { TranslateService } from '@ngx-translate/core';
import { EventosService } from 'src/app/app-common/calendario-eventos/eventos.service';
import { DetalleNotificacionComponent } from 'src/app/app-common/detalle-notificacion/detalle-notificacion.component';
import { DocumentTabEnum } from 'src/app/app-common/documentacion-shared/document-tab.enum';
import { ModalGoalChangedComponent, ModalGoalChangedData } from 'src/app/app-common/modal-goal-changed/modal-goal-changed.component';
import { SectionsEmployee } from 'src/app/app-common/perfil-empleado-shared/perfil-empleado-enums';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { AdminTrainingTabs } from 'src/app/layout/administrador/administrador-capacitaciones/admin-training-tabs.enum';
import { CuestionariosService } from 'src/app/layout/administrador/administrador-cuestionarios/cuestionarios.service';
import { DeliverySectionEnum } from 'src/app/layout/administrador/administrador-entregas/delivery-section.enum';
import { DUED_RETURN } from 'src/app/layout/administrador/administrador-entregas/listado-de-entregas/listado-de-entregas.component';
import { PerformanceOptionsQueryParams } from 'src/app/layout/user/performance/performance.component';
import { UserPerformanceTab } from 'src/app/layout/user/performance/user-performance-tabs.enum';
import { EstadoCapacitacion, TipoCategoriaDocumento } from 'src/app/ModelDTO/DTO';
import { NotificationService } from 'src/app/shared/lib/ngx-neo-components-mat/public_api';
import { NotificationDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { NgxNeoModalMatService } from 'src/app/shared/lib/ngx-neo-modal-mat/public_api';
import { classToJson } from 'src/app/shared/shared-functions';
import { NotificacionesService } from './notificaciones.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationRouterService {
  public small: boolean;

  constructor(
    private notificaciones: NotificacionesService,
    private notificationService: NotificationService,
    private cuestionariosService: CuestionariosService,
    private router: Router,
    private neoModalService: NgxNeoModalMatService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private headerService: HeaderAppService,
    private eventService: EventosService,
  ) {
    this.headerService.smallScreen$.subscribe((small) => {
      this.small = small;
    });
  }

  public async redirigirSegunNotificacion(notificacion: NotificationDTO, vistaEmpresa: boolean, personalLegajoId: number): Promise<void> {
    if (!this.headerService.IsLogged()) {
      return;
    }

    if (!notificacion.openDateTime) {
      // eslint-disable-next-line no-param-reassign
      notificacion.openDateTime = new Date();
      if (this.notificationService.notificationsNotSeen !== 0) {
        this.notificationService.ClearBell();
      }
    }
    const prefijo = vistaEmpresa ? '/admin' : '/user';
    switch (notificacion.notificationType) {
      case TipoNotificacion.NuevoEvento:
      case TipoNotificacion.EventoModificado:
      case TipoNotificacion.NuevoComentarioEvento:
        this.eventService.openEventById(notificacion.serviceId);
        break;
      case TipoNotificacion.NuevaAusencia:
        this.router.navigate([`${prefijo}/ausencias`]);
        break;
      case TipoNotificacion.NuevaSolicitudAusencia:
        this.router.navigate(['admin/ausencias'], {
          queryParams: {
            tab: 1,
            request: notificacion.serviceId,
          },
        });
        break;
      case TipoNotificacion.CambioEstadoSolicitudAusencia:
        this.router.navigate(['user/ausencias', notificacion.serviceId]);
        break;
      case TipoNotificacion.NuevaEntrega:
        if (vistaEmpresa) {
          this.router.navigate([`${prefijo}/entregas`], {
            queryParams: { tab: DeliverySectionEnum.DeliveriesList },
          });
        } else {
          this.router.navigate([`${prefijo}/entregas`]);
        }
        break;
      case TipoNotificacion.NuevasEntregasPendientes:
        this.router.navigate([`${prefijo}/entregas`]);
        break;
      case TipoNotificacion.NuevaSolicitudEntrega: {
        const tab = vistaEmpresa ? DeliverySectionEnum.Requests : DeliverySectionEnum.Pending;
        const selectedRequestId = notificacion.serviceId;
        this.router.navigate([`${prefijo}/entregas`], {
          queryParams: { tab, selectedRequestId },
        });
        break;
      }
      case TipoNotificacion.CambioEstadoSolicitudEntrega: {
        const tab = vistaEmpresa ? DeliverySectionEnum.Requests : DeliverySectionEnum.Pending;
        this.router.navigate([`${prefijo}/entregas`], {
          queryParams: { tab },
        });
        break;
      }
      case TipoNotificacion.CambioEstadoSolicitudCapacitacion:
        this.router.navigate([`${prefijo}/capacitaciones`]);
        // Una solicitud aprobada es una capacitacion pendiente
        break;
      case TipoNotificacion.NuevaCapacitacion:
        if (vistaEmpresa) {
          this.router.navigate([`${prefijo}/capacitaciones`], {
            queryParams: { tab: AdminTrainingTabs.Trainings },
          });
        } else {
          this.router.navigate([`${prefijo}/capacitaciones`]);
        }
        break;
      case TipoNotificacion.NuevaSolicitudCapacitacion:
        this.router.navigate([`${prefijo}/capacitaciones`], {
          queryParams: { tab: AdminTrainingTabs.Requests, selectedRequestId: notificacion.serviceId },
        });
        break;
      case TipoNotificacion.NuevasCapacitacionesPendientes:
        this.router.navigate([`${prefijo}/capacitaciones`], {
          queryParams: {
            tab: AdminTrainingTabs.Trainings,
            status: EstadoCapacitacion.Pendiente,
          },
        });
        break;
      case TipoNotificacion.DevolucionesVencidas: {
        const queryParams = {
          tab: DeliverySectionEnum.DeliveriesList,
          status: DUED_RETURN,
        };
        this.router.navigate([`${prefijo}/entregas`], {
          queryParams,
        });
        break;
      }
      case TipoNotificacion.EmpleadoDevolucionVencida:
        this.router.navigate([`${prefijo}/entregas`]);
        break;
      case TipoNotificacion.NuevaSancion:
        if (vistaEmpresa) {
          this.router.navigate([`${prefijo}/sanciones/detalle`, notificacion.serviceId]);
        } else {
          this.router.navigate([`${prefijo}/sanciones/detalle`, notificacion.serviceId]);
        }
        break;
      case TipoNotificacion.NuevoComentarioSancion:
        if (vistaEmpresa) {
          this.router.navigate([`${prefijo}/sanciones/detalle`, notificacion.serviceId]);
        } else {
          this.router.navigate([`${prefijo}/sanciones/detalle`, notificacion.serviceId]);
        }
        break;
      case TipoNotificacion.NuevoFeedback:
        this.router.navigate([`${prefijo}/feedback/detalle`, notificacion.serviceId]);
        break;
      case TipoNotificacion.NuevoFeedbackMuroDeLaFama:
        this.router.navigate([`${prefijo}/feedback`], {
          queryParams: { tab: vistaEmpresa ? 1 : 2 },
        });
        break;
      case TipoNotificacion.NuevoComentarioFeedback:
        this.router.navigate([`${prefijo}/feedback/detalle`, notificacion.serviceId]);
        break;
      case TipoNotificacion.NuevoDocumento:
        this.router.navigate([`${prefijo}/documentacion/documento/`, notificacion.serviceId], {
          queryParams: {
            type: TipoCategoriaDocumento.Colaborador,
          },
        });
        break;
      case TipoNotificacion.NuevoDocumentoEmpresa:
        this.router.navigate([`${prefijo}/documentacion/documento/`, notificacion.serviceId], {
          queryParams: {
            type: TipoCategoriaDocumento.Empresa,
          },
        });
        break;
      case TipoNotificacion.NuevoDocumentoPublico:
        this.router.navigate([`${prefijo}/documentacion/documento/`, notificacion.serviceId], {
          queryParams: {
            type: TipoCategoriaDocumento.P1ublico,
          },
        });
        break;
      case TipoNotificacion.NuevoCuestionario:
        this.router.navigate([`${prefijo}/cuestionarios/formulario`, notificacion.serviceId]);
        break;
      case TipoNotificacion.CuestionarioDesaprobado:
        if (vistaEmpresa) {
          this.cuestionariosService.notificationServiceId = notificacion.serviceId;
          this.router.navigate([`${prefijo}/cuestionarios`]);
        } else {
          this.neoModalService.alert('INBOX.MODE_CONTROL');
        }
        break;
      case TipoNotificacion.FirmaSolicitada:
        if (notificacion.serviceId > 0) {
          this.router.navigate([`${prefijo}/documentacion/previsualizacion/${notificacion.serviceId}`], {
            queryParams: { owner: personalLegajoId },
          });
        } else {
          this.router.navigate([`${prefijo}/documentacion/signatures`], {
            queryParams: { type: DocumentTabEnum.Signatures },
          });
        }
        break;
      case TipoNotificacion.FirmaRealizada:
        // Usamos serviceId2 porque el backend manda el id de la persona que firmo
        this.router.navigate([`${prefijo}/documentacion/previsualizacion/${notificacion.serviceId}`], {
          queryParams: { owner: notificacion.serviceId2 },
        });
        break;
      case TipoNotificacion.FirmaPendiente:
        this.router.navigate([`${prefijo}/documentacion/previsualizacion/${notificacion.serviceId}`], {
          queryParams: { owner: personalLegajoId },
        });
        break;
      case TipoNotificacion.NuevoComentarioPerfil:
        this.router.navigate(['/user/perfil-usuario'], {
          queryParams: { userMenu: SectionsEmployee.Notas },
        });
        break;
      case TipoNotificacion.DomicilioRequerido:
        this.router.navigate(['/user/perfil-usuario'], {
          queryParams: { userMenu: SectionsEmployee.DatosPersonales, tab: 2 },
        });
        break;
      case TipoNotificacion.NuevoComentarioComunicado:
        {
          const userNotification = await this.notificaciones.obtenerUserNotificacionPorIdDeNotificacion(notificacion.serviceId);
          if (userNotification) {
            const notificacionInterface = classToJson<NotificationDTO, NotificationAttachmentsDTO>(userNotification);
            this.dialog.open(DetalleNotificacionComponent, {
              data: notificacionInterface,
              disableClose: true,
              width: this.small ? '95%' : '75%',
            });
          } else {
            const message = this.translateService.instant('INBOX.COMMUNICATION_NOT_FOUND');
            await this.neoModalService.warning(message);
          }
        }
        break;
      case TipoNotificacion.NuevoComentarioAusencia:
        if (this.headerService.isUserTypesUser) {
          this.router.navigate([`user/ausencias`], {
            queryParams: {
              tab: 2,
              absence: notificacion.serviceId,
            },
          });
        } else {
          this.router.navigate([`admin/ausencias/${notificacion.serviceId}`]);
        }
        break;
      case TipoNotificacion.NuevoComentarioACandidato:
        this.router.navigate(['/reclutamiento/detalle-candidato/', notificacion.serviceId]);
        break;
      case TipoNotificacion.NotificationNeedHelp:
      case TipoNotificacion.No_definido:
        {
          const notificacionInterface = classToJson<NotificationDTO, NotificationAttachmentsDTO>(notificacion);
          this.dialog.open(DetalleNotificacionComponent, {
            data: notificacionInterface,
            disableClose: true,
            width: this.small ? '95%' : '75%',
          });
        }
        break;
      case TipoNotificacion.VencimientoContrato:
        this.router.navigate(['/admin/organigrama'], {
          queryParams: { modal: true, userModal: notificacion.serviceId, userMenu: SectionsEmployee.DatosLaborales },
        });
        break;
      case TipoNotificacion.DueTasks:
        this.router.navigate(['/user/tasks/']);
        break;
      case TipoNotificacion.NewTasks:
        {
          let url = '';
          if (notificacion.serviceId > 0) {
            url = `/user/tasks/${notificacion.serviceId}`;
          } else {
            url = '/user/tasks';
          }
          this.router.navigate([url]);
        }
        break;
      case TipoNotificacion.MergeAccount:
        this.router.navigate(['/mi-cuenta']);
        break;
      case TipoNotificacion.NuevoComentarioTarea:
        if (vistaEmpresa) {
          this.router.navigate([`${prefijo}/tasks/detail/`, notificacion.serviceId]);
        } else {
          this.router.navigate([`${prefijo}/tasks/`, notificacion.serviceId]);
        }
        break;
      case TipoNotificacion.ComunicadosSinLeer:
        this.router.navigate(['/user/comunicados']);
        break;
      case TipoNotificacion.NuevoCuestionarioCompetencias:
        this.router.navigate(['/user/performance'], {
          queryParams: { tab: UserPerformanceTab.Evaluations },
        });
        break;
      case TipoNotificacion.NewPostulatedCandidate:
        this.router.navigate([`/reclutamiento/oferta/${notificacion.serviceId}/candidato/${notificacion.serviceId2}`]);
        break;
      case TipoNotificacion.GoalEdition: {
        const data: ModalGoalChangedData = {
          notification: classToJson(notificacion),
          titleKey: 'PERFORMANCE.GOALS.GOAL_MODIFICATION',
          acceptAction: () => {
            this.router.navigate(['/user/tasks/', notificacion.serviceId]);
          },
        };
        this.dialog.open(ModalGoalChangedComponent, {
          data,
          disableClose: true,
        });
        break;
      }
      case TipoNotificacion.SubordinatedGoalEdition: {
        const data: ModalGoalChangedData = {
          notification: classToJson(notificacion),
          titleKey: 'PERFORMANCE.GOALS.EMPLOYEE_GOAL_MODIFICATION',
          acceptAction: () => {
            this.router.navigate(['/admin/tasks/detail/', notificacion.serviceId]);
          },
        };
        this.dialog.open(ModalGoalChangedComponent, {
          data,
          disableClose: true,
        });
        break;
      }
      case TipoNotificacion.NewProfileEditions:
        this.router.navigate(['/admin/organigrama/historial-cambios']);
        break;
      case TipoNotificacion.ReevaluateGoal: {
        const queryParams: PerformanceOptionsQueryParams = {
          tab: UserPerformanceTab.Evaluations,
          option: 'goals',
          goal: notificacion.serviceId,
        };

        const data: ModalGoalChangedData = {
          notification: classToJson(notificacion),
          titleKey: 'PERFORMANCE.GOALS.REEVALUATE_GOAL',
          acceptAction: () => {
            this.router.navigate(['/user/performance'], { queryParams });
          },
        };

        this.dialog.open(ModalGoalChangedComponent, {
          data,
          disableClose: true,
        });
        break;
      }
      case TipoNotificacion.MentionInSocialPost: {
        this.router.navigate(['/user/feed'], {
          queryParams: { post: notificacion.serviceId },
        });
        break;
      }
      case TipoNotificacion.NewCommentSocialPost: {
        this.router.navigate(['/user/feed'], {
          queryParams: { post: notificacion.serviceId },
        });
        break;
      }
      default:
        break;
    }
  }
}
