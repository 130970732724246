import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AbsenceCommentHandler } from 'src/app/app-common/comentarios/absence-comments-handler';
import { UserAusenciasService } from 'src/app/layout/user/user-ausencias/user-ausencias.service';
import { SolicitudAusenciaModelDTO } from 'src/app/ModelDTO';
import { EstadoAprobacionSolicitud } from 'src/app/ModelDTO/DTO';
import { Command, ICommand } from 'src/app/shared/lib/ngx-neo-directives-mat/public_api';
import { AlertButton, NgxNeoModalMatService } from 'src/app/shared/lib/ngx-neo-modal-mat/public_api';

export interface RespuestaSolicitudDetalleAusenciaModal {
  editar: boolean;
  clonar: boolean;
  deleted: boolean;
  solicitud: SolicitudAusenciaModelDTO;
}

@Component({
  selector: 'app-detalle-solicitud-ausencia-modal',
  templateUrl: './detalle-solicitud-ausencia-modal.component.html',
})
export class DetalleSolicitudAusenciaModalComponent {
  public readonly EstadoAprobacionSolicitud = EstadoAprobacionSolicitud;

  public solicitudAusencia: SolicitudAusenciaModelDTO;

  public get pendingRequest(): boolean {
    return this.solicitudAusencia.Ausencia.estado === EstadoAprobacionSolicitud.Pendiente;
  }

  public get approvedRequest(): boolean {
    return this.solicitudAusencia.Ausencia.estado === EstadoAprobacionSolicitud.Aprobado;
  }

  public eliminarSolicitudCmd: ICommand = new Command(() => this.eliminarSolicitud(), new BehaviorSubject(true), false);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SolicitudAusenciaModelDTO,
    private dialogRef: MatDialogRef<DetalleSolicitudAusenciaModalComponent>,
    private neoModalService: NgxNeoModalMatService,
    private userAusenciasService: UserAusenciasService,
    private router: Router,
    public comentarioHandler: AbsenceCommentHandler,
  ) {
    this.solicitudAusencia = data;
  }

  public chipColor(): string {
    const dto = this.solicitudAusencia.AusenciaModel.getEntityDTO();

    switch (dto.estado) {
      case EstadoAprobacionSolicitud.Aprobado:
        return 'success';
      case EstadoAprobacionSolicitud.Pendiente:
        return 'accent';
      case EstadoAprobacionSolicitud.Rechazado:
        return 'danger';
      default:
        return '';
    }
  }

  public close(): void {
    this.dialogRef.close({ editar: false, deleted: false });
  }

  public editar(): void {
    this.dialogRef.close({ editar: true, solicitud: this.solicitudAusencia });
  }

  public eliminar(): void {
    this.dialogRef.close({ editar: false, deleted: true });
  }

  public addDocumentation(): void {
    this.router.navigate(['user/ausencias/edit-request', this.data.Id]);
    this.dialogRef.close();
  }

  private async eliminarSolicitud() {
    const res = await this.neoModalService.decision('Se eliminará la solicitud');
    if (res.ButtonResponse === AlertButton.Accept) {
      await this.userAusenciasService.eliminarSolicitudPorId(this.solicitudAusencia.AusenciaModel.Id);
      this.eliminar();
    }
  }
}
