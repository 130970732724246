import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AddresseesDTO, ComentarioDTO, PersonalTaskGoalsDTO, TaskGoalInformationDTO } from 'src/app/ModelDTO/DTO';
import { PendingTaskUserDTO } from 'src/app/ModelDTO/DTO/pendingTaskUser.DTO';
import { TaskElementDTO } from 'src/app/ModelDTO/DTO/taskElement.DTO';
import { AuditLogEntryDTO, DataDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { Constants } from '../shared/constants';

@Injectable({
   providedIn: 'root'
})
export class TaskServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getTasksIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/tasks/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getTasksIdPDF(id: number, namedBlob: NamedBlobDTO = null): Promise<Blob> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/tasks/' + id + '/PDF' + '?BLOB=true', { observe: 'response', responseType: 'blob' }));
                  const resDTO = new Blob([res.body], { type: 'application/pdf'});
         if (namedBlob) {
            namedBlob.setBlobNameFromHttpResponse(res);
            namedBlob.blob = res.body;
         }
         return resDTO;
   }

   public async getTasks(): Promise<Array<TaskElementDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/tasks/'));
         const resJson = res.data;
         const resDTO = new Array<TaskElementDTO>();
         for (const item of resJson) {
            const itemDTO = new TaskElementDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertTasks(taskElementDTO: TaskElementDTO): Promise<TaskElementDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/tasks/', taskElementDTO));
         const resDTO = new TaskElementDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deleteTasksId(id: number): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/tasks/' + id));

   }

   public async getTasksId(id: number): Promise<TaskElementDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/tasks/' + id));
         if (!res) { return null; }
         const resDTO = new TaskElementDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateTasksId(id: number, taskElementDTO: TaskElementDTO): Promise<TaskElementDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/tasks/' + id, taskElementDTO));
         const resDTO = new TaskElementDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getTasksCurrentUser(pageNumber: number, pageSize: number, startDate: Date, endDate: Date, createdByMe: boolean = false, includeCompleted: boolean = false, sortActive: number = 0, isAsc: boolean = false): Promise<Array<TaskElementDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/tasks/currentUser' + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&startDate=' + startDate?.toISOString() + '&endDate=' + endDate?.toISOString() + '&createdByMe=' + createdByMe + '&includeCompleted=' + includeCompleted + '&sortActive=' + sortActive + '&isAsc=' + isAsc));
         const resJson = res.data;
         const resDTO = new Array<TaskElementDTO>();
         for (const item of resJson) {
            const itemDTO = new TaskElementDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getTasksPendingByTeammate(pageNumber: number, pageSize: number, teammateId: number = 0, sortActive: number = 0, isAsc: boolean = false): Promise<Array<PendingTaskUserDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/tasks/pendingByTeammate' + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&teammateId=' + teammateId + '&sortActive=' + sortActive + '&isAsc=' + isAsc));
         const resJson = res.data;
         const resDTO = new Array<PendingTaskUserDTO>();
         for (const item of resJson) {
            const itemDTO = new PendingTaskUserDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getTasksTeammatesteammateId(teammateId: number, pageNumber: number, pageSize: number, startDate: Date, endDate: Date, includeCompleted: boolean = false, sortActive: number = 0, isAsc: boolean = false): Promise<Array<TaskElementDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/tasks/teammates/' + teammateId + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&startDate=' + startDate?.toISOString() + '&endDate=' + endDate?.toISOString() + '&includeCompleted=' + includeCompleted + '&sortActive=' + sortActive + '&isAsc=' + isAsc));
         const resJson = res.data;
         const resDTO = new Array<TaskElementDTO>();
         for (const item of resJson) {
            const itemDTO = new TaskElementDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getTasksTemplates( filterGoals: boolean = false,): Promise<Array<TaskElementDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/tasks/templates'+'?filterGoals='+filterGoals));
         const resJson = res.data;
         const resDTO = new Array<TaskElementDTO>();
         for (const item of resJson) {
            const itemDTO = new TaskElementDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertTasksTeammatesteammateId(teammateId: number, taskElementDTO: TaskElementDTO): Promise<TaskElementDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/tasks/teammates/' + teammateId, taskElementDTO));
         const resDTO = new TaskElementDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async insertTasksTemplates(taskElementDTO: TaskElementDTO): Promise<TaskElementDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/tasks/templates', taskElementDTO));
         const resDTO = new TaskElementDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateTasksTemplateId(id: number, taskElementDTO: TaskElementDTO): Promise<TaskElementDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/tasks/template/' + id, taskElementDTO));
         const resDTO = new TaskElementDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getTasksTemplateId(id: number): Promise<TaskElementDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/tasks/template/' + id));
         if (!res) { return null; }
         const resDTO = new TaskElementDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deleteTasksTemplateId(id: number): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/tasks/template/' + id));

   }

   public async insertTasksTemplatestemplateIdTeammateteammateId(templateId: number, teammateId: number, taskElementDTO: TaskElementDTO): Promise<void> {

         await lastValueFrom(this.http.post(Constants.apiURL + '/tasks/templates/' + templateId + '/teammate/' + teammateId, taskElementDTO));

   }

   public async insertGoalsGoalIdBulk(templateId: number, teammates: AddresseesDTO): Promise<void> {

         await lastValueFrom(this.http.post(Constants.apiURL + '/tasks/goals/' + templateId + '/bulk' , teammates));

   }

   public async updateTasksIdStatusChange(id: number, taskElementDTO: TaskElementDTO): Promise<TaskElementDTO> {

        const res = await lastValueFrom(this.http.put(Constants.apiURL + '/tasks/' + id + '/statusChange', taskElementDTO));
        if (!res) { return null; }
         const resDTO = new TaskElementDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }


   public async updateTasksIdEvaluate(id: number, goalResultDTO: TaskGoalInformationDTO): Promise<TaskElementDTO> {
    const res = await lastValueFrom(this.http.put(Constants.apiURL + '/tasks/' + id + '/evaluate', goalResultDTO));
    const resDTO = new TaskElementDTO();
    resDTO.PrepareDTO(res);
    return resDTO;
  }

   public async getTaskIdComentarios(id: number): Promise<Array<ComentarioDTO>> {
    const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/tasks/' + id + '/comentarios'));
    const resJson = res.data;
    const resDTO = new Array<ComentarioDTO>();
    for (const item of resJson) {
       const itemDTO = new ComentarioDTO();
       itemDTO.PrepareDTO(item);
       resDTO.push(itemDTO);
    }
    return resDTO;
   }

   public async insertTaskIdComentarios(id: number, stream: FormData): Promise<void> {
    await lastValueFrom(this.http.post(Constants.apiURL + '/tasks/' + id + '/comentarios', stream));
   }

  public async updateTaskIdComentariosIDCOMENTARIO(id: number, iDComentario: number, comentarioDTO: ComentarioDTO): Promise<ComentarioDTO> {
    const res = await lastValueFrom(this.http.put(Constants.apiURL + '/tasks/' + id + '/comentarios/' + iDComentario, comentarioDTO));
    const resDTO = new ComentarioDTO();
    resDTO.PrepareDTO(res);
    return resDTO;
  }

  public async getTasksGoalsResults(personal: number = undefined, year: number = undefined, strategicDriver: number = undefined, overallEvaluationId: number = undefined, withoutOverallEvaluation: boolean = false): Promise<PersonalTaskGoalsDTO[]> {
      const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/tasks/goals' + '?personal=' + personal +
         '&year=' + year +
         '&strategicDriver=' + strategicDriver +
         '&overallEvaluationId=' + overallEvaluationId +
         '&withoutOverallEvaluation=' + withoutOverallEvaluation));
      const resJson = res.data;
      const resDTO = new Array<PersonalTaskGoalsDTO>();
      for (const item of resJson) {
         const itemDTO = new PersonalTaskGoalsDTO();
         itemDTO.PrepareDTO(item);
         resDTO.push(itemDTO);
      }
      return resDTO;
}

  public async getTasksCurrentUserGoalsResults(year: number = undefined, strategicDriver: number = undefined, overallEvaluationId: number = undefined): Promise<Array<PersonalTaskGoalsDTO>> { 
         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/tasks/currentUser/goals' + '?year=' + year +
            '&strategicDriver=' + strategicDriver +
            '&overallEvaluationId=' + overallEvaluationId));
         const resJson = res.data;
         const resDTO = new Array<PersonalTaskGoalsDTO>();
         for (const item of resJson) {
            const itemDTO = new PersonalTaskGoalsDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;
   }

  public async deleteTaskIdComentariosIDCOMENTARIO(id: number, iDComentario: number): Promise<void> {
    await lastValueFrom(this.http.delete(Constants.apiURL + '/tasks/' + id + '/comentarios/' + iDComentario));
  }

}
