<h1 mat-dialog-title class="d-flex flew-row justify-content-between">
  <span>{{ 'GENERAL.APPLY_FILTERS' | translate }} </span>
  <span class="dialog-close-icon material-symbols-outlined" mat-dialog-close> close </span>
</h1>

<div mat-dialog-content>
  <form id="filtersForm" [formGroup]="filtersForm" (ngSubmit)="filter()">
    <div class="d-flex flex-row align-items-center gap-1 mt-1">
      <neo-date-selector
        class="flex-fill"
        formClass="w-100"
        [label]="'GENERAL.SINCE' | translate"
        [required]="true"
        [(date)]="from"
        [onlyValidDate]="true"
        [enableClear]="true"
      >
      </neo-date-selector>
      <neo-date-selector
        #fecH
        class="flex-fill"
        formClass="w-100"
        [label]="'GENERAL.UNTIL' | translate"
        [required]="true"
        [min]="from"
        [(date)]="to"
        [onlyValidDate]="true"
        [enableClear]="true"
      >
      </neo-date-selector>
    </div>
    <div class="d-flex flex-row align-items-center gap-1">
      <mat-form-field class="flex-fill">
        <mat-label>{{ 'GENERAL.STATUS' | translate }}</mat-label>
        <mat-select formControlName="state">
          <mat-option *ngFor="let estado of data.deliveryStates | arrayTranslate: 'ENUM.DELIVERIES.DELIVERY_STATUS'" [value]="estado.value">
            {{ estado.value === 'No_definido' ? ('GENERAL.ALL' | translate) : estado.label }}</mat-option
          >
          <mat-option [value]="DUED_RETURN">
            {{ 'DELIVERIES.PENDING_RETURN' | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="flex-fill">
        <mat-label>{{ 'DELIVERIES.ITEM' | translate }}</mat-label>
        <mat-select [compareWith]="compareId" formControlName="type">
          <mat-optgroup
            *ngFor="let item of data.entregasXconvenio | keyvalue"
            [label]="nombreConvenio(item?.value?.length ? item.value[0].convenio : undefined)"
          >
            <mat-option *ngFor="let tipo of item.value" [value]="tipo"> {{ tipo.nombre }}</mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
    </div>
    <neo-completer-mat
      name="completer-user"
      formControlName="teammate"
      [datasource]="personalSearch"
      [openOnFocus]="true"
      [formFieldClass]="'w-100'"
      [minSearchLength]="3"
      [label]="'GENERAL.SEARCH_EMPLOYEE' | translate"
      [autoHighlight]="true"
      [fillHighlighted]="false"
      [clearUnselected]="false"
      [textNoResults]="'GENERAL.NO_EMPLOYEE_FOUND' | translate"
      [textSearching]="'GENERAL.SEARCHING' | translate"
    >
    </neo-completer-mat>
  </form>
</div>
<div mat-dialog-actions class="d-flex flex-row justify-content-end">
  <button type="button" mat-button color="primary" (click)="clear()">
    {{ 'GENERAL.CLEAR_FILTERS' | translate }}
  </button>
  <button form="filtersForm" mat-stroked-button color="primary" type="submit">
    {{ 'GENERAL.APPLY_FILTERS' | translate }}
  </button>
</div>
