/* eslint-disable prefer-template */
/* eslint-disable no-useless-concat */
import { convertJsonDates } from 'src/app/shared/shared-functions';
import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs'
import { Constants } from 'src/app/shared/constants';
import { DataDTO } from 'src/app/api/interfaces/data.interface';

import { AuditLogEntryDTO } from 'src/app/api/interfaces/audit-log-entry.interface';
import { ImageEntityDTO } from 'src/app/api/interfaces/image-entity.interface';
import { NameDetailDTO } from 'src/app/api/interfaces/name-detail.interface';
import { AttachmentUaalooDTO } from 'src/app/api/interfaces/attachment-uaaloo.interface';
import { ComentarioDTO } from 'src/app/api/interfaces/comentario.interface';
import { CommentReactionDTO } from 'src/app/api/interfaces/comment-reaction.interface';
import { CurrentReactionDTO } from 'src/app/api/interfaces/current-reaction.interface';
import { EventFlatDTO } from 'src/app/api/interfaces/event-flat.interface';
import { EventoDTO } from 'src/app/api/interfaces/evento.interface';
import { NewCommentReactionDTO } from 'src/app/api/interfaces/new-comment-reaction.interface';
import { TipoEventoDTO } from 'src/app/api/interfaces/tipo-evento.interface';
import { EventSummaryDTO } from '@api/interfaces/event-summary.interface';

@Injectable({
   providedIn: 'root'
})
export class EventoBackendService {

   constructor(protected http: HttpClient) { }


   public getEventoIdAuditory(id: number): Observable<AuditLogEntryDTO[]> {
      return this.http.get<DataDTO<AuditLogEntryDTO>>(Constants.apiURL + '/evento/' + id + '/Auditory').pipe(map((res) => convertJsonDates(res.data) as AuditLogEntryDTO[]));
   }

   public getEvento(): Observable<EventoDTO[]> {
      return this.http.get<DataDTO<EventoDTO>>(Constants.apiURL + '/evento/').pipe(map((res) => convertJsonDates(res.data) as EventoDTO[]));
   }

   public deleteEventoId(id: number, deleteFromDb: boolean = false): Observable<void> {
      return this.http.delete<void>(Constants.apiURL + '/evento/' + id + '?deleteFromDb=' + deleteFromDb);
   }
   public getEventoId(id: number): Observable<EventoDTO> {
      return this.http.get<EventoDTO>(Constants.apiURL + '/evento/' + id).pipe(map((res) => convertJsonDates(res) as EventoDTO ));
   }

   public insertEvento(stream: FormData): Observable<EventoDTO> {
      return this.http.post<EventoDTO>(Constants.apiURL + '/evento/', stream).pipe(map((res) => convertJsonDates(res) as EventoDTO ));
   }

   public updateEventoId(id: number, stream: FormData): Observable<EventoDTO> {
      return this.http.put<EventoDTO>(Constants.apiURL + '/evento/' + id, stream).pipe(map((res) => convertJsonDates(res) as EventoDTO ));
   }

   public getEventoProximos(): Observable<EventSummaryDTO[]> {
      return this.http.get<DataDTO<EventSummaryDTO>>(Constants.apiURL + '/evento/proximos').pipe(map((res) => convertJsonDates(res.data) as EventSummaryDTO[]));
   }

   public getEventoTipos(): Observable<TipoEventoDTO> {
      return this.http.get<TipoEventoDTO>(Constants.apiURL + '/evento/tipos').pipe(map((res) => convertJsonDates(res) as TipoEventoDTO ));
   }

   public getEventoFiltrado(params: { fechaDesde: Date, fechaHasta: Date, pageNumber: number, pageSize: number, isDesc?: boolean }, ): Observable<EventSummaryDTO[]> {
      const { fechaDesde, fechaHasta, pageNumber, pageSize, isDesc = false } = params;
      return this.http.get<DataDTO<EventSummaryDTO>>(Constants.apiURL + '/evento/filtrado' + '?fechaDesde=' + (fechaDesde?.toISOString() ?? '') +
            '&fechaHasta=' + (fechaHasta?.toISOString() ?? '') +
            '&pageNumber=' + pageNumber +
            '&pageSize=' + pageSize +
            '&isDesc=' + isDesc).pipe(map((res) => convertJsonDates(res.data) as EventSummaryDTO[]));
   }

   public insertEventoIdImage(id: number, imageEntityDTO: ImageEntityDTO): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/evento/' + id + '/Image', imageEntityDTO);
   }
   public getEventoIdComentarios(id: number): Observable<ComentarioDTO[]> {
      return this.http.get<DataDTO<ComentarioDTO>>(Constants.apiURL + '/evento/' + id + '/comentarios').pipe(map((res) => convertJsonDates(res.data) as ComentarioDTO[]));
   }

   public insertEventoIdComentarios(id: number, stream: FormData): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/evento/' + id + '/comentarios', stream);
   }
   public updateEventoIdComentariosIDCOMENTARIO(id: number, iDComentario: number, comentarioDTO: ComentarioDTO): Observable<ComentarioDTO> {
      return this.http.put<ComentarioDTO>(Constants.apiURL + '/evento/' + id + '/comentarios/' + iDComentario, comentarioDTO).pipe(map((res) => convertJsonDates(res) as ComentarioDTO ));
   }

   public deleteEventoIdComentariosIDCOMENTARIO(id: number, iDComentario: number): Observable<void> {
      return this.http.delete<void>(Constants.apiURL + '/evento/' + id + '/comentarios/' + iDComentario);
   }
   public getEventoIdReactions(id: number): Observable<CommentReactionDTO[]> {
      return this.http.get<DataDTO<CommentReactionDTO>>(Constants.apiURL + '/evento/' + id + '/reactions').pipe(map((res) => convertJsonDates(res.data) as CommentReactionDTO[]));
   }

   public updateEventoIdReactions(id: number, newCommentReactionDTO: NewCommentReactionDTO): Observable<CommentReactionDTO> {
      return this.http.put<CommentReactionDTO>(Constants.apiURL + '/evento/' + id + '/reactions', newCommentReactionDTO).pipe(map((res) => convertJsonDates(res) as CommentReactionDTO ));
   }

   public deleteEventoIdReactionsEMOJIID(id: number, emojiId: string): Observable<CommentReactionDTO> {
      return this.http.delete<CommentReactionDTO>(Constants.apiURL + '/evento/' + id + '/reactions/' + emojiId).pipe(map((res) => convertJsonDates(res) as CommentReactionDTO ));
   }
   public getEventoIdAdjuntos(id: number): Observable<AttachmentUaalooDTO[]> {
      return this.http.get<DataDTO<AttachmentUaalooDTO>>(Constants.apiURL + '/evento/' + id + '/adjuntos').pipe(map((res) => convertJsonDates(res.data) as AttachmentUaalooDTO[]));
   }

   public insertEventoChatgptDescription(nameDetailDTO: NameDetailDTO): Observable<NameDetailDTO> {
      return this.http.post<NameDetailDTO>(Constants.apiURL + '/evento/chat-gpt/description', nameDetailDTO).pipe(map((res) => convertJsonDates(res) as NameDetailDTO ));
   }

   public insertEventoGooglecalendar(): Observable<NameDetailDTO> {
      return this.http.post<NameDetailDTO>(Constants.apiURL + '/evento/google-calendar', null).pipe(map((res) => convertJsonDates(res) as NameDetailDTO ));
   }

   public insertEventoGooglecalendarSuscribe(): Observable<NameDetailDTO> {
      return this.http.post<NameDetailDTO>(Constants.apiURL + '/evento/google-calendar/suscribe', null).pipe(map((res) => convertJsonDates(res) as NameDetailDTO ));
   }

   public getEventoGooglecalendar(): Observable<NameDetailDTO[]> {
      return this.http.get<DataDTO<NameDetailDTO>>(Constants.apiURL + '/evento/google-calendar').pipe(map((res) => convertJsonDates(res.data) as NameDetailDTO[]));
   }
   
   public getEventoGooglecalendarSuscribes(): Observable<NameDetailDTO[]> {
      return this.http.get<DataDTO<NameDetailDTO>>(Constants.apiURL + '/evento/google-calendar/suscribes').pipe(map((res) => convertJsonDates(res.data) as NameDetailDTO[]));
   }

}
