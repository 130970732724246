import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ComentarioDTO } from 'src/app/ModelDTO/DTO';
import { NewCommentReactionDTO } from 'src/app/ModelDTO/DTO/newCommentReaction.DTO';
import {
  FeedbackServiceBackend,
  PersonalAusenciaServiceBackend,
  PersonalLegajoServiceBackend,
  SancionServiceBackend,
} from 'src/app/ServiceBackend';
import { CommentServiceBackend } from 'src/app/ServiceBackend/comment.ServiceBackend';
import { ComunicadoServiceBackend } from 'src/app/ServiceBackend/comunicado.ServiceBackend';
import { EventoServiceBackend } from 'src/app/ServiceBackend/evento.ServiceBackend';
import { ReclutamientoCandidatosServiceBackend } from 'src/app/ServiceBackend/reclutamientoCandidatos.ServiceBackend';
import { TaskServiceBackend } from 'src/app/ServiceBackend/task.ServiceBackend';

@Injectable({
  providedIn: 'root',
})
export class ComentariosService {
  public comentarios = new Array<ComentarioDTO>();
  public comentariosCambio = new Subject<void>();
  constructor(
    private comunicadosServiceBackend: ComunicadoServiceBackend,
    private eventoServiceBackend: EventoServiceBackend,
    private personalLegajoServiceBackend: PersonalLegajoServiceBackend,
    private sancionesServiceBackend: SancionServiceBackend,
    private feedbackServiceBackend: FeedbackServiceBackend,
    private reclutamientoCandidatosServiceBackend: ReclutamientoCandidatosServiceBackend,
    private ausenciaServiceBackend: PersonalAusenciaServiceBackend,
    private commentServiceBackend: CommentServiceBackend,
    private taskServiceBackend: TaskServiceBackend
  ) {}

  public async enviarMensajeEnComunicadosAdmin(id: number, comentario: FormData): Promise<void> {
    await this.comunicadosServiceBackend.insertNotificationsIdComentarios(id, comentario);
    this.comentariosCambio.next();
  }
  public async obtenerMensajesEnComunicadosAdmin(id: number): Promise<Array<ComentarioDTO>> {
    return this.comunicadosServiceBackend.getNotificationsIdComentarios(id);
  }
  public async editarMensajeEnComunicadosAdmin(id: number, comentario: ComentarioDTO): Promise<void> {
    await this.comunicadosServiceBackend.updateNotificationsIdComentariosIDCOMENTARIO(id, comentario.id, comentario);
    this.comentariosCambio.next();
  }
  public async borrarMensajeEnComunicadosAdmin(id: number, comentario: ComentarioDTO): Promise<void> {
    await this.comunicadosServiceBackend.deleteNotificationsIdComentariosIDCOMENTARIO(id, comentario.id);
    this.comentariosCambio.next();
  }

  public async enviarMensajeEnComunicadosUser(id: number, comentario: FormData): Promise<void> {
    await this.comunicadosServiceBackend.insertUserNotificationsIdComentarios(id, comentario);
    this.comentariosCambio.next();
  }
  public async obtenerMensajesEnComunicadosUser(id: number): Promise<Array<ComentarioDTO>> {
    return this.comunicadosServiceBackend.getUserNotificationsIdComentarios(id);
  }
  public async editarMensajeEnComunicadosUser(id: number, comentario: ComentarioDTO): Promise<void> {
    await this.comunicadosServiceBackend.updateUserNotificationsIdComentariosIDCOMENTARIO(id, comentario.id, comentario);
    this.comentariosCambio.next();
  }
  public async borrarMensajeEnComunicadosUser(id: number, comentario: ComentarioDTO): Promise<void> {
    await this.comunicadosServiceBackend.deleteUserNotificationsIdComentariosIDCOMENTARIO(id, comentario.id);
    this.comentariosCambio.next();
  }

  public async enviarMensajesEnEventos(id: number, comentario: FormData): Promise<void> {
    await this.eventoServiceBackend.insertEventoIdComentarios(id, comentario);
    this.comentariosCambio.next();
  }
  public async obtenerMensajesEnEventos(id: number): Promise<Array<ComentarioDTO>> {
    return this.eventoServiceBackend.getEventoIdComentarios(id);
  }
  public async editarMensajesEnEventos(id: number, comentario: ComentarioDTO): Promise<void> {
    await this.eventoServiceBackend.updateEventoIdComentariosIDCOMENTARIO(id, comentario.id, comentario);
    this.comentariosCambio.next();
  }
  public async borrarMensajesEnEventos(id: number, comentario: ComentarioDTO): Promise<void> {
    await this.eventoServiceBackend.deleteEventoIdComentariosIDCOMENTARIO(id, comentario.id);
    this.comentariosCambio.next();
  }

  public async enviarMensajesPerfilUsuario(id: number, comentario: FormData): Promise<void> {
    await this.personalLegajoServiceBackend.insertPersonalLegajosIdComentarios(id, comentario);
    this.comentariosCambio.next();
  }
  public async obtenerMensajesPerfilUsuario(id: number): Promise<Array<ComentarioDTO>> {
    return this.personalLegajoServiceBackend.getPersonalLegajosIdComentarios(id);
  }
  public async editarMensajesPerfilUsuario(id: number, comentario: ComentarioDTO): Promise<void> {
    await this.personalLegajoServiceBackend.updatePersonalLegajosIdComentariosIDCOMENTARIO(id, comentario.id, comentario);
    this.comentariosCambio.next();
  }
  public async borrarMensajesPerfilUsuario(id: number, comentario: ComentarioDTO): Promise<void> {
    await this.personalLegajoServiceBackend.deletePersonalLegajosIdComentariosIDCOMENTARIO(id, comentario.id);
    this.comentariosCambio.next();
  }

  public async enviarComentariosEnFeedback(id: number, comentario: FormData): Promise<void> {
    await this.feedbackServiceBackend.insertFeedbackIdComentarios(id, comentario);
    this.comentariosCambio.next();
  }
  public async obtenerComentariosEnFeedback(id: number): Promise<Array<ComentarioDTO>> {
    return this.feedbackServiceBackend.getFeedbackIdComentarios(id);
  }
  public async editarComentariosEnFeedback(id: number, comentario: ComentarioDTO): Promise<void> {
    await this.feedbackServiceBackend.updateFeedbackIdComentariosIDCOMENTARIO(id, comentario.id, comentario);
    this.comentariosCambio.next();
  }
  public async borrarComentariosEnFeedback(id: number, comentario: ComentarioDTO): Promise<void> {
    await this.feedbackServiceBackend.deleteFeedbackIdComentariosIDCOMENTARIO(id, comentario.id);
    this.comentariosCambio.next();
  }

  public async enviarComentariosEnSancion(id: number, comentario: FormData): Promise<void> {
    await this.sancionesServiceBackend.insertComplianceSancionIdComentarios(id, comentario);
    this.comentariosCambio.next();
  }
  public async obtenerComentariosEnSancion(id: number): Promise<Array<ComentarioDTO>> {
    return this.sancionesServiceBackend.getComplianceSancionIdComentarios(id);
  }
  public async editarComentariosEnSancion(id: number, comentario: ComentarioDTO): Promise<void> {
    await this.sancionesServiceBackend.updateComplianceSancionIdComentariosIDCOMENTARIO(id, comentario.id, comentario);
    this.comentariosCambio.next();
  }
  public async borrarComentariosEnSancion(id: number, comentario: ComentarioDTO): Promise<void> {
    await this.sancionesServiceBackend.deleteComplianceSancionIdComentariosIDCOMENTARIO(id, comentario.id);
    this.comentariosCambio.next();
  }

  public async obtenerComentariosCandidato(id: number): Promise<Array<ComentarioDTO>> {
    return this.reclutamientoCandidatosServiceBackend.getReclutamientoCandidatosIdComentarios(id);
  }

  public async enviarComentarioACandidato(id: number, comentario: FormData): Promise<void> {
    await this.reclutamientoCandidatosServiceBackend.insertReclutamientoCandidatosIdComentario(id, comentario);
    this.comentariosCambio.next();
  }

  public async deleteCommentToCandidate(id: number, comment: ComentarioDTO): Promise<void> {
    await this.reclutamientoCandidatosServiceBackend.deleteReclutamientoCandidatosIdComentariosIDCOMENTARIO(id, comment.id);
    this.comentariosCambio.next();
  }

  public async editCommentToCandidate(id: number, comment: ComentarioDTO): Promise<void> {
    await this.reclutamientoCandidatosServiceBackend.updateReclutamientoCandidatosIdComentariosIDCOMENTARIO(id, comment.id, comment);
    this.comentariosCambio.next();
  }

  public async saveAbsenceComment(id: number, comentario: FormData): Promise<void> {
    await this.ausenciaServiceBackend.insertAusenciasIdComentarios(id, comentario);
    this.comentariosCambio.next();
  }
  public async getAbssenceComments(id: number): Promise<Array<ComentarioDTO>> {
    return this.ausenciaServiceBackend.getAusenciasIdComentarios(id);
  }
  public async editAbsenceComment(id: number, comentario: ComentarioDTO): Promise<void> {
    await this.ausenciaServiceBackend.updateAusenciasIdComentariosIDCOMENTARIO(id, comentario.id, comentario);
    this.comentariosCambio.next();
  }
  public async deleteAbsenceComment(id: number, comentario: ComentarioDTO): Promise<void> {
    await this.ausenciaServiceBackend.deleteAusenciasIdComentariosIDCOMENTARIO(id, comentario.id);
    this.comentariosCambio.next();
  }

  public async saveTaskComment(id: number, comentario: FormData): Promise<void> {
    await this.taskServiceBackend.insertTaskIdComentarios(id, comentario);
    this.comentariosCambio.next();
  }
  public async getTaskComments(id: number): Promise<Array<ComentarioDTO>> {
    return this.taskServiceBackend.getTaskIdComentarios(id);
  }
  public async editTaskComment(id: number, comentario: ComentarioDTO): Promise<void> {
    await this.taskServiceBackend.updateTaskIdComentariosIDCOMENTARIO(id, comentario.id, comentario);
    this.comentariosCambio.next();
  }
  public async deleteTaskComment(id: number, comentario: ComentarioDTO): Promise<void> {
    await this.taskServiceBackend.deleteTaskIdComentariosIDCOMENTARIO(id, comentario.id);
    this.comentariosCambio.next();
  }

  public async updateCommentsIdReactions(id: number, newCommentReactionDTO: NewCommentReactionDTO): Promise<ComentarioDTO> {
    return this.commentServiceBackend.updateCommentsIdReactions(id, newCommentReactionDTO);
  }

  public async deleteCommentsIdReactionsEMOJIID(id: number, emojiId: string): Promise<ComentarioDTO> {
    return this.commentServiceBackend.deleteCommentsIdReactionsEMOJIID(id, emojiId);
  }
}
