import { Component, Input, NgZone, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CommentExtended } from 'src/app/app-common/comentarios/commentExtended';
import { IQuillMention } from 'src/app/app-common/comentarios/quill-mention.interface';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { OrganigramaService } from 'src/app/layout/administrador/colaboradores/organigrama.service';
import { ComentarioDTO } from 'src/app/ModelDTO/DTO';
import { Command, ICommand } from 'src/app/shared/lib/ngx-neo-directives-mat/public_api';
import { AlertButton, NgxNeoModalMatService } from 'src/app/shared/lib/ngx-neo-modal-mat/public_api';
import { PersonalEntityService } from 'src/app/shared/services/entities/personal-entity.service';
import { SnackBarService } from 'src/app/shared/snack-bar/snack-bar.service';
import { quillModuloMinimal } from 'src/app/shared/shared-functions';
import { ComentariosService } from 'src/app/app-common/comentarios/comentarios.service';
import { IComentarios } from 'src/app/app-common/comentarios/icomentarios';

@Component({
  selector: 'app-comentarios-lista',
  templateUrl: './comentarios-lista.component.html',
  styleUrls: ['./comentarios-lista.component.scss'],
})
export class ComentariosListaComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public comentarioHandler: IComentarios;
  @Input() public set id(value: number) {
    this.idPersonal = value;
  }

  public comentarios = new Array<CommentExtended>();
  public textoSinData = '';
  public quillModule: unknown;
  public saveEditedCmd: ICommand = new Command((value) => this.saveEdited(value), new BehaviorSubject(true), true);
  public deleteCmd: ICommand = new Command((value) => this.delete(value), new BehaviorSubject(true), true);
  public get id(): number {
    return this.idPersonal;
  }

  private idPersonal: number;
  private sub = new Subscription();

  constructor(
    private comentarioService: ComentariosService,
    private neoModalService: NgxNeoModalMatService,
    private headerService: HeaderAppService,
    private organigramaService: OrganigramaService,
    private personalService: PersonalEntityService,
    private snackBar: SnackBarService,
    private zone: NgZone,
  ) {}

  public ngOnInit(): void {
    this.textoSinData = this.comentarioHandler.getNoDataString();
    this.quillModule = {
      ...quillModuloMinimal,
      mention: {
        allowedChars: /^[A-Za-z\s]*$/,
        minChars: 3,
        isolateCharacter: true,
        dataAttributes: ['role'],
        onSelect: (item: any, insertItem: (arg0: any) => void): void => {
          insertItem(item);
          this.zone.run(() => {
            this.mentionAlert();
          });
        },
        source: async (searchTerm: string, renderList: (arg0: IQuillMention[]) => void): Promise<void> => {
          const teammates = await this.searchTeammate(searchTerm);
          renderList(teammates);
        },
      },
    };
  }

  public ngOnChanges(): void {
    if (this.comentarioHandler && this.id > 0) {
      this.getComentarios();
    }
  }

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  public edit(comentario: CommentExtended): void {
    // eslint-disable-next-line no-param-reassign
    comentario.inEdition = true;
  }

  public async saveEdited(comentario: ComentarioDTO): Promise<void> {
    if (comentario?.mensaje) {
      await this.comentarioHandler.editMessage(this.id, comentario);
    }
  }

  public async clickedComment($event: any): Promise<void> {
    if ($event.target?.getAttribute) {
      const id = $event.target.getAttribute('data-id');
      await this.organigramaService.openPersonalDetailsById(id, false);
    }
  }

  public async delete(comentario: ComentarioDTO): Promise<void> {
    if (comentario?.mensaje) {
      const res = await this.neoModalService.decision('COMMENTS.REMOVAL_REQUEST');
      if (res.ButtonResponse === AlertButton.Accept) {
        await this.comentarioHandler.deleteMessage(this.id, comentario);
      }
    }
  }

  private async searchTeammate(term: string): Promise<IQuillMention[]> {
    const filteredTeamamtes = await this.personalService.getEntities(term, true);
    return filteredTeamamtes?.map((x) => ({
      id: x.id.toString(),
      value: x.nombreCompleto,
      role: x.esAdmin.toString(),
    }));
  }

  private mentionAlert(): void {
    this.snackBar.showInfo('COMMENTS.NO_NOTIFICATION_EDITION');
  }

  private async getComentarios(): Promise<void> {
    this.comentarios = await this.comentarioHandler.init(this.id, this.headerService.personalLegajoId, this.headerService.isAdmin());
    this.sub.add(
      this.comentarioService.comentariosCambio.subscribe(async () => {
        this.comentarios = await this.comentarioHandler.init(this.id, this.headerService.personalLegajoId, this.headerService.isAdmin());
      }),
    );
  }
}
