import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { RecaptchaService } from 'src/app/core/recaptcha/recaptcha.service';
import { CandidatoPostuladoOfertaDTO, CandidatoPostuladoUserValidationDTO } from 'src/app/ModelDTO/DTO';
import { ReclutamientoCampo } from 'src/app/ModelDTO/DTO/reclutamientoCampo.ENUM';
import { Command, ICommand } from 'src/app/shared/lib/ngx-neo-directives-mat/public_api';
import { FileDBDTO, ImageEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { CandidateImageData } from 'src/app/shared/seleccionar-profile-img/seleccionar-profile-img.component';
import { ReclutamientoCandidatoParaPostulacionDTO } from 'src/app/ModelDTO/DTO/reclutamientoCandidatoParaPostulacion.DTO';
import { ReclutamientoPublicoService } from 'src/app/public/reclutamiento-publico.service';
import { ReclutamientoOfertaPublicaDTO } from '@api/interfaces/reclutamiento-oferta-publica.interface';
import { ReclutamientoPublicoBackendService } from '@api/services/reclutamiento-publico-backend.service';

@Component({
  selector: 'app-oferta',
  templateUrl: './oferta.component.html',
  styleUrls: ['./oferta.component.scss'],
})
export class OfertaComponent implements OnInit {
  public ofertaPublica: ReclutamientoOfertaPublicaDTO;
  public postulatedCandidateForm: UntypedFormGroup;
  public validateEmailForm: UntypedFormGroup;
  public files: File[] = [];
  public imageData: CandidateImageData;
  public candidateProfilePicture: ImageEntityDTO = new ImageEntityDTO();
  public hideForm = false;
  public candidato: ReclutamientoCandidatoParaPostulacionDTO;
  public postulatedCandidateDTO: CandidatoPostuladoOfertaDTO;
  public successfulApplication = false;
  public loadingCandidate = false;

  public submitFormCmd: ICommand = new Command(() => this.onSubmit(), new BehaviorSubject(true), true);
  public validateEmailCmd: ICommand = new Command(() => this.validateEmail(), new BehaviorSubject(true), true);

  public get skillsFormArray(): UntypedFormArray {
    return this.postulatedCandidateForm.get('skills') as UntypedFormArray;
  }

  public get imageControl(): AbstractControl {
    return this.postulatedCandidateForm.get('candidate.image');
  }

  public get loadedOffer(): boolean {
    return this.ofertaPublica !== undefined && this.ofertaPublica !== null;
  }

  private offerToken: string;

  constructor(
    public viewportScroller: ViewportScroller,
    public reclutamientoPublicoService: ReclutamientoPublicoService,
    private reclutamientoBackendService: ReclutamientoPublicoBackendService,
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private recaptchaService: RecaptchaService,
    private meta: Meta,
  ) {
    this.initValidateEmailForm();
    this.initPostulatedCandidateForm();
    this.imageData = { imagen: '', changed: false };
  }

  public async ngOnInit(): Promise<void> {
    const applyFormAnchor = this.activatedRoute.snapshot.fragment;
    if (this.activatedRoute.snapshot.params?.token) {
      this.offerToken = this.activatedRoute.snapshot.params?.token;
      this.reclutamientoBackendService.getReclutamientoPublicoReclutamientoOfertaLINK(this.offerToken).subscribe((oferta) => {
        this.ofertaPublica = oferta;
        this.meta.addTag({ name: 'image', content: `${this.ofertaPublica.headerImage}`, property: 'og:image' });
        this.setValidatorsControls();
        if (this.ofertaPublica) {
          this.loadPublicOffer();
        }

        if (applyFormAnchor) {
          // eslint-disable-next-line no-restricted-globals
          if ('scrollRestoration' in history) {
            // eslint-disable-next-line no-restricted-globals
            history.scrollRestoration = 'manual';
          }
          setTimeout(() => this.viewportScroller.scrollToAnchor(applyFormAnchor));
        }
      });
    }

    if (this.activatedRoute.snapshot.queryParams?.userToken) {
      this.loadingCandidate = true;
      const { userToken } = this.activatedRoute.snapshot.queryParams;
      try {
        this.postulatedCandidateDTO = await this.reclutamientoPublicoService.getPostulatedCandidateAuthenticatedUser(userToken);
        if (this.postulatedCandidateDTO) {
          this.loadPostulatedCandidate();
        }
      } finally {
        this.loadingCandidate = false;
      }
    }
  }

  public filesChange(files: File[]): void {
    if (files?.length) {
      this.files = files;
      const file = new FileDBDTO();
      file.fileName = this.files[0].name;
      this.postulatedCandidateForm.get('cv').setValue(file);
    } else {
      this.postulatedCandidateForm.get('cv').setValue(null);
    }
  }

  private async validateEmail(): Promise<void> {
    if (this.validateEmailForm.valid) {
      const tokenRecaptcha = await this.recaptchaService.getToken('offerLink');

      const candidateUserValidation = new CandidatoPostuladoUserValidationDTO();
      candidateUserValidation.email = this.validateEmailForm.value.email;
      candidateUserValidation.offerToken = this.offerToken;
      candidateUserValidation.reCaptchaToken = tokenRecaptcha;
      await this.reclutamientoPublicoService.insertPostulatedCandidateValidateEmail(candidateUserValidation);
      this.hideForm = true;
    }
  }

  private async onSubmit(): Promise<void> {
    if (this.postulatedCandidateForm.valid) {
      const tokenRecaptcha = await this.recaptchaService.getToken('offerLink');
      const imageTokenRecaptcha = await this.recaptchaService.getToken('imageUpload');
      const { userToken } = this.activatedRoute.snapshot.queryParams;
      this.candidateProfilePicture.image = this.imageData.imagen;
      const formData = new FormData();
      const postulatedCandidateOffer = new CandidatoPostuladoOfertaDTO();
      postulatedCandidateOffer.PrepareDTO(this.postulatedCandidateForm.value);
      postulatedCandidateOffer.reCaptchaToken = tokenRecaptcha;
      if (!this.imageData.changed && postulatedCandidateOffer.id > 0) {
        postulatedCandidateOffer.candidate.profilePicture = this.imageData.imagen;
        this.candidateProfilePicture.image = null;
      }
      postulatedCandidateOffer.candidate.email = this.postulatedCandidateDTO.candidate.email;
      if (this.files.length) {
        formData.append(this.files[0].name, this.files[0]);
      }
      formData.append('json', JSON.stringify(postulatedCandidateOffer));
      if (this.postulatedCandidateDTO.id > 0) {
        await this.reclutamientoPublicoService.updatePostulatedCandidate(
          postulatedCandidateOffer.id,
          userToken,
          formData,
          this.candidateProfilePicture,
          imageTokenRecaptcha,
        );
      } else {
        await this.reclutamientoPublicoService.insertPostulatedCandidate(
          userToken,
          formData,
          this.candidateProfilePicture,
          imageTokenRecaptcha,
        );
      }
      this.successfulApplication = true;
    } else {
      this.postulatedCandidateForm.markAllAsTouched();
    }
  }

  private initValidateEmailForm(): void {
    this.validateEmailForm = this.formBuilder.group({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      offerToken: new UntypedFormControl('', [Validators.required]),
    });
  }

  private initPostulatedCandidateForm(): void {
    this.postulatedCandidateForm = this.formBuilder.group({
      id: new UntypedFormControl(0),
      coverLetter: new UntypedFormControl(''),
      cv: new UntypedFormControl(null),
      public: new UntypedFormControl(true),
      skills: this.formBuilder.array([]),
      candidate: new UntypedFormGroup({
        id: new UntypedFormControl(0),
        email: new UntypedFormControl('', [Validators.required, Validators.email]),
        firstName: new UntypedFormControl('', Validators.required),
        lastName: new UntypedFormControl('', Validators.required),
        phone: new UntypedFormControl(''),
        image: new UntypedFormControl(''),
        linkedIn: new UntypedFormControl(''),
      }),
    });
  }

  private loadPostulatedCandidate(): void {
    this.postulatedCandidateForm.patchValue({
      id: this.postulatedCandidateDTO.id,
      coverLetter: this.postulatedCandidateDTO.coverLetter,
      public: this.postulatedCandidateDTO.public,
      candidate: {
        id: this.postulatedCandidateDTO.candidate.id,
        email: this.postulatedCandidateDTO.candidate.email,
        firstName: this.postulatedCandidateDTO.candidate.firstName,
        lastName: this.postulatedCandidateDTO.candidate.lastName,
        phone: this.postulatedCandidateDTO.candidate.phone,
        linkedIn: this.postulatedCandidateDTO.candidate.linkedIn,
        image: this.postulatedCandidateDTO.candidate.profilePicture,
      },
    });

    this.imageData.imagen = this.postulatedCandidateDTO.candidate.profilePicture;
    this.imageData.changed = false;

    if (this.postulatedCandidateDTO.cv.id > 0) {
      const file = new File([], this.postulatedCandidateDTO.cv.fileName);
      this.files = [...this.files, file];
      this.postulatedCandidateForm.patchValue({
        cv: this.postulatedCandidateDTO.cv,
      });
    }

    if (this.postulatedCandidateDTO.skills?.length > 0) {
      this.skillsFormArray.controls.forEach((control) => {
        control.patchValue({
          id: this.postulatedCandidateDTO.skills.find((x) => control.value.skill === x.skill)?.id,
          score: this.postulatedCandidateDTO.skills.find((x) => control.value.skill === x.skill)?.score,
        });
      });
    }
    this.postulatedCandidateForm.get('candidate.email').disable();
  }

  private loadPublicOffer(): void {
    this.validateEmailForm.patchValue({
      offerToken: this.offerToken,
    });
    this.ofertaPublica.conocimientos.forEach((c) => {
      this.skillsFormArray.push(
        this.formBuilder.group({
          id: 0,
          skill: c.id,
          skillName: c.conocimiento,
          score: [0],
        }),
      );
    });
  }

  private setValidatorsControls(): void {
    this.postulatedCandidateForm.controls.candidate
      .get('phone')
      .setValidators(
        this.ofertaPublica?.campos.find((x) => x.campo === ReclutamientoCampo.Telefono)?.obligatorio ? Validators.required : null,
      );
    this.postulatedCandidateForm.controls.candidate
      .get('image')
      .setValidators(this.ofertaPublica?.campos.find((x) => x.campo === ReclutamientoCampo.Foto)?.obligatorio ? Validators.required : null);
    this.postulatedCandidateForm.controls.candidate
      .get('linkedIn')
      .setValidators(
        this.ofertaPublica?.campos.find((x) => x.campo === ReclutamientoCampo.Linkedin)?.obligatorio ? Validators.required : null,
      );
    this.postulatedCandidateForm.controls.cv.setValidators(
      this.ofertaPublica.campos.find((x) => x.campo === ReclutamientoCampo.CV)?.obligatorio ? Validators.required : null,
    );
    this.postulatedCandidateForm.controls.coverLetter.setValidators(
      this.ofertaPublica?.campos.find((x) => x.campo === ReclutamientoCampo.Carta)?.obligatorio ? Validators.required : null,
    );
    this.postulatedCandidateForm.updateValueAndValidity();
  }
}
