import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OfertaTipoJornadaPipe } from 'src/app/layout/reclutamiento/pipes/oferta-tipo-jornada.pipe';

@NgModule({
  declarations: [OfertaTipoJornadaPipe],
  imports: [CommonModule],
  exports: [OfertaTipoJornadaPipe],
})
export class ReclutamientoSharedModule {}
