import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private openSateSubject = new BehaviorSubject<boolean>(true);
  public openSate$ = this.openSateSubject.asObservable();

  public toggleSidebar(): void {
    this.openSateSubject.next(!this.openSateSubject.value);
  }

  public closeSidebar(): void {
    this.openSateSubject.next(false);
  }

  public openSidebar(): void {
    this.openSateSubject.next(true);
  }
}
