import { Component, Inject, ViewChild, TemplateRef, ViewContainerRef, HostListener, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PersonalModelDTO, CambiosHistoricosModelDTO, InformacionModelDTO } from 'src/app/ModelDTO';
import { PerfilEmpleadoSharedService } from 'src/app/app-common/perfil-empleado-shared/perfil-empleado-shared.service';
import { BehaviorSubject, map, Observable, Subscription } from 'rxjs';
import { ICommand, Command } from 'src/app/shared/lib/ngx-neo-directives-mat/public_api';
import { NgxNeoModalMatService } from 'src/app/shared/lib/ngx-neo-modal-mat/public_api';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { ImageEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { PersonalDTO, InformacionDTO, TipoCategoriaDocumento } from 'src/app/ModelDTO/DTO';
import { AltasEmpleadoEnum } from 'src/app/app-common/perfil-empleado-shared/detalle-empleado/detalle-empleado.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentacionSharedService } from 'src/app/app-common/documentacion-shared/documentacion-shared.service';
import { FeatureFlagService } from 'src/app/shared/feature-flags/feature-flags.service';
import { featureEdicionColaborador } from 'src/app/shared/feature-flags/feature-flag-provider.service';
import { DocumentacionSharedViewModel } from 'src/app/app-common/documentacion-shared/documentacion-shared-view.model';
import { DetalleEmpleadoService } from 'src/app/app-common/perfil-empleado-shared/detalle-empleado/detalle-empleado.service';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { EMAIL_VALIDATOR } from 'src/app/shared/reg-exp.constants';
import { TranslateService } from '@ngx-translate/core';
import { FeatureFlagHelper } from 'src/app/shared/feature-flags/feature-flag.helper';
import { SectionsEmployee } from 'src/app/app-common/perfil-empleado-shared/perfil-empleado-enums';
import { SnackBarService } from 'src/app/shared/snack-bar/snack-bar.service';
import { AppCacheService } from 'src/app/shared/lib/ngx-neo-frontend-mat/services/app-cache/app-cache.service';
import { FeedbackService } from 'src/app/layout/administrador/feedback/feedbacks.service';
import { ListadoDeCapacitacionesService } from 'src/app/layout/administrador/administrador-capacitaciones/listado-de-capacitaciones.service';
import { OrganigramaService } from 'src/app/layout/administrador/colaboradores/organigrama.service';
import { SancionesService } from 'src/app/layout/administrador/sanciones/sanciones.service';
import { ListadoDeEntregasService } from 'src/app/layout/administrador/administrador-entregas/listado-de-entregas.service';
import { AdministradorAusenciasService } from 'src/app/layout/administrador/administrador-ausencias/administrador-ausencias.service';
import { TeammatesListService } from 'src/app/layout/administrador/colaboradores/teammates-list.service';

export interface IModalPersonalData {
  teammateId: number;
  menuItem?: SectionsEmployee;
}

@Component({
  selector: 'app-detalle-empleado-modal',
  templateUrl: './detalle-empleado-modal.component.html',
  styleUrls: ['./detalle-empleado-modal.component.scss'],
})
export class DetalleEmpleadoModalComponent implements OnInit {
  @ViewChild('avisoEdicion', { static: true }) public avisoEdicion: TemplateRef<any>;
  @HostListener('document:click', ['$event']) public clickout(): void {
    this.disposeOverlay();
  }

  public personalModel: PersonalModelDTO;
  public edicion = false;

  public historial: CambiosHistoricosModelDTO;

  public cancelarCmd: ICommand = new Command(() => this.cancel(), new BehaviorSubject(true), false);
  public guardarEditarCmd: ICommand = new Command(() => this.saveOrEdit(), new BehaviorSubject(true), true);

  public contactForm: UntypedFormGroup;
  public selectedMenu: SectionsEmployee;

  public edicionColaboradorPermission$: Observable<boolean>;

  public get notificationAddressControl(): AbstractControl {
    return this.contactForm.controls.notificationAddress;
  }

  public get notificationLocationControl(): AbstractControl {
    return this.contactForm.controls.notificationLocation;
  }

  private overlayRef: OverlayRef;
  private subs = new Subscription();
  private employeeImage: ImageEntityDTO;

  constructor(
    private dialogRef: MatDialogRef<DetalleEmpleadoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IModalPersonalData,
    private perfilService: PerfilEmpleadoSharedService,
    public overlay: Overlay,
    private neoModalService: NgxNeoModalMatService,
    public viewContainerRef: ViewContainerRef,
    private headerService: HeaderAppService,
    private teammatesListService: TeammatesListService,
    private router: Router,
    private administradorAusenciasService: AdministradorAusenciasService,
    private administradorEntregasService: ListadoDeEntregasService,
    private administradorCapacitacionesService: ListadoDeCapacitacionesService,
    private administradorSancionesService: SancionesService,
    private administradorFeedbackService: FeedbackService,
    private documentacionSharedService: DocumentacionSharedService,
    private organigramaService: OrganigramaService,
    private featureService: FeatureFlagService,
    private documentacionViewModel: DocumentacionSharedViewModel,
    private detalleEmpleadoService: DetalleEmpleadoService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private snackBar: SnackBarService,
    private cacheService: AppCacheService,
  ) {
    this.initForm();
  }

  public ngOnInit(): void {
    this.perfilService.obtenerPersonal(this.data.teammateId).then((teammate) => {
      this.personalModel = teammate;
      this.perfilService.setPersonalModel(this.personalModel);

      this.subs.add(this.detalleEmpleadoService.altaEvento$.subscribe((tipo) => this.onAltaEvent(tipo)));
      const menuParam = this.route.snapshot.queryParamMap.get('userMenu');
      if (menuParam) {
        this.editUrl(Number(menuParam));
      } else {
        this.editUrl(SectionsEmployee.DatosPersonales);
      }
    });

    this.edicionColaboradorPermission$ = this.featureService.flags$.pipe(
      map(({ flags }) => FeatureFlagHelper.featureOn(featureEdicionColaborador, flags)),
    );

    this.edicion = false;

    this.selectedMenu = this.data.menuItem;
  }

  public cancel(): void {
    if (this.edicion) {
      this.perfilService.cancelarEdicionPersonal();
      this.edicion = false;
      this.contactForm.disable();
    } else {
      this.dialogRef.close();
    }
  }

  public detalleClicked($event: MouseEvent): void {
    if (($event.target as any).disabled && !this.edicion) {
      $event.preventDefault();
      $event.stopPropagation();
      this.disposeOverlay();
      const { x, y } = $event;
      const positionStrategy = this.overlay
        .position()
        .flexibleConnectedTo({ x, y })
        .withPositions([
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
        ]);

      this.overlayRef = this.overlay.create({
        positionStrategy,
        scrollStrategy: this.overlay.scrollStrategies.close(),
      });

      this.overlayRef.attach(new TemplatePortal(this.avisoEdicion, this.viewContainerRef));
    }
  }

  public async onImageChange(event: ImageEntityDTO): Promise<void> {
    this.employeeImage = event;
  }

  public onAltaEvent(tipoAlta: AltasEmpleadoEnum): void {
    this.dialogRef.close();
    // eslint-disable-next-line default-case
    switch (tipoAlta) {
      case AltasEmpleadoEnum.AltaDocumento:
        this.documentacionSharedService.InformacionAEditarModel = new InformacionModelDTO(new InformacionDTO());
        this.documentacionSharedService.InformacionAEditarModel.Personal.id = this.personalModel.Legajo.id;
        this.documentacionSharedService.InformacionAEditarModel.Personal.legajo = this.personalModel.Legajo.legajo;
        this.documentacionSharedService.InformacionAEditarModel.Categoria.PrepareDTO(this.documentacionViewModel.categoria);
        this.documentacionSharedService.InformacionAEditarModel.Categoria.visualizacion = TipoCategoriaDocumento.Colaborador;
        this.router.navigate(['/admin/documentacion/alta']);
        break;
      case AltasEmpleadoEnum.AltaAusencia:
        this.administradorAusenciasService.PersonalAlta = this.personalModel.getEntityDTO();
        this.router.navigate(['/admin/ausencias/alta']);
        break;
      case AltasEmpleadoEnum.AltaEntrega:
        this.administradorEntregasService.PersonalAlta = this.personalModel.getEntityDTO();
        this.router.navigate(['/admin/entregas/alta']);
        break;
      case AltasEmpleadoEnum.AltaSancion:
        this.administradorSancionesService.PersonalAlta = this.personalModel.getEntityDTO();
        this.router.navigate(['/admin/sanciones/alta-sancion']);
        break;
      case AltasEmpleadoEnum.AltaFeedback:
        this.administradorFeedbackService.PersonalAlta = this.personalModel.getEntityDTO();
        this.router.navigate(['/admin/feedback/dar-feedback']);
        break;
      case AltasEmpleadoEnum.AltaCapacitacion:
        this.administradorCapacitacionesService.PersonalAlta = this.personalModel.getEntityDTO();
        this.router.navigate(['/admin/capacitaciones/alta']);
        break;
    }
  }

  public menuSelected(option: SectionsEmployee): void {
    this.editUrl(option);
  }

  private initForm(): void {
    this.contactForm = new UntypedFormGroup({
      phone: new UntypedFormControl(''),
      accessEmail: new UntypedFormControl('', [Validators.pattern(EMAIL_VALIDATOR)]),
      companyEmail: new UntypedFormControl('', [Validators.pattern(EMAIL_VALIDATOR)]),
      currentAddress: new UntypedFormControl(''),
      currentCountry: new UntypedFormControl(),
      currentLocation: new UntypedFormControl(null),
      notificationAddress: new UntypedFormControl(''),
      notificationCountry: new UntypedFormControl(),
      notificationLocation: new UntypedFormControl(null),
      emergency: new UntypedFormGroup({
        emergencyContactName: new UntypedFormControl(''),
        emergencyContactPhone: new UntypedFormControl(''),
        emergencyContactEmail: new UntypedFormControl('', [Validators.pattern(EMAIL_VALIDATOR)]),
        emergencyContactAddress: new UntypedFormControl(''),
        emergencyContactLocation: new UntypedFormControl(null),
      }),
    });

    this.contactForm.disable();
  }

  private async saveOrEdit(): Promise<void> {
    if (!this.edicion) {
      this.edicion = true;
      this.contactForm.enable();
    } else {
      if (this.personalModel.Domicilio.direccion && this.personalModel.Domicilio.localidad.id > 0) {
        if (!this.notificationAddressControl.value || !this.notificationLocationControl.value) {
          await this.neoModalService.alert('PROFILE.NOTIFICATION_FIELDS_REMOVED_CONTROL');
          return;
        }
      }
      if (this.notificationLocationControl.value?.id > 0) {
        if (!this.notificationAddressControl.value) {
          await this.neoModalService.alert('PROFILE.NOTIFICATION_ADDRESS_CONTROL');
          return;
        }
      }
      const validContactInfo = await this.saveContactPersonal();
      if (validContactInfo) {
        const res: PersonalDTO = await this.perfilService.actualizarPersonal(this.personalModel.Id, this.personalModel.getEntityDTO());
        if (res && this.employeeImage) {
          const imageDTO = await this.perfilService.guardarImagenPersonal(this.personalModel.Id, this.employeeImage);
          if (!this.headerService.modoEmpresa() || this.headerService.personalLegajoId === this.personalModel.Id) {
            // Estoy cambiando mi propia imagen
            this.personalModel.Image = imageDTO.image;
            this.headerService.newProfileImage(imageDTO.image);
            res.image = imageDTO.image;
          }
        }

        // Actualizo los organigramas, de ser necesario y la tabla de los colaboradores
        this.organigramaService.edicionPersonal.next(true);
        this.teammatesListService.actualizarColaborador(res);
        this.dialogRef.close();
        this.snackBar.showInfo('GENERAL.SUCCESSFUL_MODIFICATION');
        this.cacheService.clearCache('/teammates/recipients');
      }
    }
  }

  private async saveContactPersonal(): Promise<boolean> {
    if (this.contactForm.valid) {
      this.personalModel.Telefonos = this.contactForm.value.phone;
      this.personalModel.Email = this.contactForm.value.accessEmail;
      this.personalModel.Legajo.emailEmpresa = this.contactForm.value.companyEmail;
      this.personalModel.DomicilioResidencia.direccion = this.contactForm.value.currentAddress;
      this.personalModel.DomicilioResidencia.localidad.provincia.pais = this.contactForm.value.currentCountry;
      this.personalModel.DomicilioResidencia.localidad = this.contactForm.value.currentLocation;
      this.personalModel.Domicilio.direccion = this.contactForm.value.notificationAddress;
      this.personalModel.Domicilio.localidad = this.contactForm.value.notificationLocation;
      this.personalModel.ContactoEmergencia.nombreCompleto = this.contactForm.value.emergency.emergencyContactName;
      this.personalModel.ContactoEmergencia.telefonos = this.contactForm.value.emergency.emergencyContactPhone;
      this.personalModel.ContactoEmergencia.email = this.contactForm.value.emergency.emergencyContactEmail;
      this.personalModel.ContactoEmergencia.domicilio.direccion = this.contactForm.value.emergency.emergencyContactAddress;
      this.personalModel.ContactoEmergencia.domicilio.localidad = this.contactForm.value.emergency.emergencyContactLocation;
      return true;
    }
    const message = this.translateService.instant('EMPLOYEES.INVALID_EMAIL');
    await this.neoModalService.alert(message);
    return false;
  }

  private editUrl(userMenu: SectionsEmployee): void {
    let queryParams: any = {
      modal: true,
      userModal: this.personalModel.Id,
      userMenu,
    };
    if (userMenu !== SectionsEmployee.Documentos) {
      queryParams = { ...queryParams, folderId: null };
    }
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge',
      skipLocationChange: false,
      replaceUrl: true,
      state: { userModal: true },
    });
  }

  private disposeOverlay(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }
}
