<div class="d-flex flex-column flex-grow-1 p-6">
  <div class="d-flex flex-row gap-1 align-items-center justify-content-end">
    <button color="primary" class="mb-20" mat-icon-button (click)="openModalFilter()">
      <mat-icon fontSet="material-symbols-outlined"> tune </mat-icon>
    </button>
    <button class="menu-button" mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon fontSet="material-symbols-outlined">more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button color="primary" mat-menu-item [command]="exportarEntregasCmd">
        <mat-icon fontSet="material-symbols-outlined" class="mr-2">cloud_download</mat-icon
        ><span>{{ 'DELIVERIES.EXPORT_DELIVERIES' | translate }}</span>
      </button>
      <button color="primary" mat-menu-item [routerLink]="['/admin/configuracion/tipos-de-entrega']">
        <mat-icon fontSet="material-symbols-outlined" class="mr-2">settings</mat-icon
        ><span>{{ 'SETTINGS.CRUD_AND_AUTOMATION_DELIVERY_ARTICLES' | translate }}</span>
      </button>
    </mat-menu>
  </div>

  <div class="hidden-sm touchable-table">
    <!--table-fixed-double -->
    <table mat-table [dataSource]="viewModel.entregasFiltradas" matSort (matSortChange)="sortData($event)">
      <ng-container [matColumnDef]="ColumnNames.Personal">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'GENERAL.EMPLOYEE' | translate }}</th>
        <td mat-cell *matCellDef="let entrega">
          <div class="d-flex flex-row align-items-center">
            <app-circle-image [width]="40" [personalLegajo]="entrega.personalLegajo" [center]="false" [stopPropagation]="true" class="pr-2">
            </app-circle-image>
            <label>{{ entrega.personalLegajo.nombreCompleto }}</label>
          </div>
        </td>
      </ng-container>
      <ng-container [matColumnDef]="ColumnNames.Tipo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DELIVERIES.DELIVERY_ITEM' | translate }}</th>
        <td mat-cell *matCellDef="let entrega">{{ entrega.entrega.nombre }}</td>
      </ng-container>
      <ng-container [matColumnDef]="ColumnNames.CodReferencia">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DELIVERIES.REFERENCE_CODE' | translate }}</th>
        <td mat-cell *matCellDef="let entrega">
          {{ entrega.codigoDeReferencia.length > 20 ? (entrega.codigoDeReferencia | slice: 0 : 17) + '...' : entrega.codigoDeReferencia }}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="ColumnNames.Estado">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'GENERAL.STATUS' | translate }}</th>
        <td mat-cell *matCellDef="let entrega">
          {{ 'ENUM.DELIVERIES.DELIVERY_STATUS.' + estadoEnum[entrega.estado] | translate }}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="ColumnNames.Entrega">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DELIVERIES.AGREE_DATE' | translate }}</th>
        <td mat-cell *matCellDef="let entrega">
          {{ entrega.fechaPactada | ngxdate: 'shortDateFull' }}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="ColumnNames.Vencimiento">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'GENERAL.DUE' | translate }}</th>
        <td mat-cell *matCellDef="let entrega">
          {{ entrega.vencimientoAproximado ? (entrega.vencimientoAproximado | ngxdate: 'shortDateFull') : '' }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let entrega; columns: displayedColumns" [command]="detalleEntregaCmd" [commandValue]="entrega"></tr>
    </table>
  </div>

  <div class="hidden-lg">
    <ng-container *ngIf="viewModel.entregasFiltradas.length > 0; else sinEntregas">
      <mat-card class="mb-3" *ngFor="let entrega of viewModel.entregasFiltradas">
        <mat-card-content>
          <a [command]="detalleEntregaCmd" [commandValue]="entrega">
            <div class="d-flex flex-row align-items-center">
              <div class="flex-fill mr-4">
                <div class="d-flex flex-row justify-content-between align-items-center">
                  <h3 class="primary-color little-margin">
                    {{ entrega.entrega.nombre }}
                  </h3>
                  <mat-chip-set>
                    <mat-chip
                      [color]="
                        entrega.estado === estadoEnum.Entregado ? 'primary' : entrega.estado === estadoEnum.Rechazado ? 'warn' : 'accent'
                      "
                    >
                      {{ estadoEnum[entrega.estado] | enumTranslate: 'ENUM.DELIVERIES.DELIVERY_STATUS' }}
                    </mat-chip>
                  </mat-chip-set>
                </div>
                <small class="no-margin"
                  >{{ 'DELIVERIES.REQUESTER' | translate }}: {{ entrega.personalLegajo.nombre }} -
                  {{ 'DELIVERIES.AGREE_DATE' | translate }}: {{ entrega.fechaPactada | ngxdate: 'shortDateFull' }}
                  {{ 'GENERAL.APPROXIMATE_DUE' | translate }}:
                  {{ entrega.vencimientoAproximado ? (entrega.vencimientoAproximado | ngxdate: 'shortDateFull') : '' }}</small
                >
              </div>
              <div class="flex-shrink-1">
                <span class="material-symbols-outlined primary-color">chevron_right</span>
              </div>
            </div>
          </a>
        </mat-card-content>
      </mat-card>
    </ng-container>
    <ng-template #sinEntregas>
      <app-warning-card [delay]="500" [mensaje]="'DELIVERIES.NO_DELIVERIES' | translate"></app-warning-card>
    </ng-template>
  </div>
</div>
