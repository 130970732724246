import { HubConnectionBuilder, HubConnection, IHttpConnectionOptions, HubConnectionState } from '@microsoft/signalr';
import { Injectable, Inject } from '@angular/core';
import { Subscription, Subject, timer, BehaviorSubject } from 'rxjs';
import { AppError } from '../exception-manager/app-error';
import { AuthResponseDTO } from '../../models/DTO/authResponse.DTO';
import { IEntityDTO } from '../../models/DTO/entity.DTO';
import { FrontEndConfigService, FrontEndConfig } from '../../FrontendConfig';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class PushService {
  private hubConnection: HubConnection;
  private DELAY_TIME = 30000;
  private timerSubscription: Subscription;
  private isStoped = false;
  private lastDisconnectTime: DateTime;

  public user: AuthResponseDTO;

  private pOnConnectedEvent = new BehaviorSubject<boolean>(false);

  public get shouldExcecuteReconnect(): boolean {
    const minutesAfterDisconnect = this.lastDisconnectTime?.diffNow('minutes').minutes ?? Infinity;
    return Math.abs(minutesAfterDisconnect) >= 10;
  }

  constructor(@Inject(FrontEndConfigService) private Constants: FrontEndConfig) {
    const thisAux = this;
    const options: IHttpConnectionOptions = {
      accessTokenFactory() {
        return thisAux.user.token;
      },
    };
    this.hubConnection = new HubConnectionBuilder().withUrl(this.Constants.apiURL + '/signalr', options).build();

    this.hubConnection.onclose((error) => {
      this.lastDisconnectTime = DateTime.now();
      if (error) {
        if (error.message.includes('Unauthorized')) {
          if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
          }
          throw new AppError('Unauthorized', 401);
        }
        this.connection();
      }
    });
  }

  public get ConnectionIdAsync(): Promise<string> {
    return this.sendMessageToServer('GetMyConnectionId', null);
  }

  public get ConnectionState(): HubConnectionState {
    return this.hubConnection?.state;
  }

  public start(user: AuthResponseDTO): void {
    this.user = user;
    this.isStoped = false;
    if (this.hubConnection?.state === HubConnectionState.Disconnected) {
      this.hubConnection
        .start()
        .then(() => {
          console.log('Connection started (1)!');
          this.pOnConnectedEvent.next(true);
        })
        .catch((error) => {
          this.pOnConnectedEvent.next(false);
          if (!this.isStoped) {
            this.connection();
          }
        });
    }
  }

  public stop(): void {
    this.isStoped = true;
    this.hubConnection
      .stop()
      .then(() => console.log('Connection stoped!'))
      .catch((error) => console.log('Connection can not stoped!'));
    this.pOnConnectedEvent.next(false);
  }

  public clearLastDisconnect(): void {
    this.lastDisconnectTime = null;
  }

  private connection(): void {
    if (this.isStoped) {
      return;
    }
    this.timerSubscription = timer(this.DELAY_TIME).subscribe((x) => this.timerUp(x)); // Restart connection after 3 seconds.
  }

  private timerUp(x: number): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
    if (this.hubConnection.state === HubConnectionState.Disconnected) {
      this.hubConnection
        .start()
        .then(() => {
          console.log('Connection started (2)!');
          this.pOnConnectedEvent.next(true);
        })
        .catch((err) => {
          this.pOnConnectedEvent.next(false);
          if (err && err.message.includes('Unauthorized')) {
            if (this.timerSubscription) {
              this.timerSubscription.unsubscribe();
            }
            throw new AppError('Unauthorized', 401);
          } else {
            this.connection();
          }
        });
    }
  }

  public onConectedToServer(onConnectedMethod: (connection: boolean) => void): Subscription {
    return this.pOnConnectedEvent.asObservable().subscribe((data) => {
      onConnectedMethod(data);
    });
  }

  public registerPushFrom<TDTO extends IEntityDTO | null>(methodName: string, newMethod: (dto: TDTO | null) => void): void {
    this.hubConnection.on(methodName, (data) => {
      newMethod(<TDTO>data);
    });
  }
  public unregisterPushFrom(methodName: string): void {
    this.hubConnection.off(methodName);
  }

  public async sendMessageToServer(methodName: string, message: any = null): Promise<any> {
    if (this.hubConnection.state === HubConnectionState.Connected) {
      return await this.hubConnection.invoke(methodName, message);
    } else {
      this.connection();
      return null;
    }
  }
}
