import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { QuillModule } from 'ngx-quill';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PublicRoutingModule } from 'src/app/public/public-routing.module';
import { CircleImageComponent } from 'src/app/shared/circle-image/circle-image.component';
import { ShowRecaptchaDirective } from 'src/app/shared/directives/show-recaptcha.directive';
import { FileSelectorComponent } from 'src/app/shared/file-selector/file-selector.component';
import { SkeletonCardComponent } from 'src/app/shared/skeleton-card/skeleton-card.component';
import { ComentariosModule } from 'src/app/app-common/comentarios/comentarios.module';
import { NgxNeoDirectivesModule } from 'src/app/shared/lib/ngx-neo-directives-mat/public_api';
import { NgxNeoPipesModule } from 'src/app/shared/lib/ngx-neo-pipes/public_api';
import { ReclutamientoSharedModule } from 'src/app/shared/reclutamiento-shared/reclutamiento-shared.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatSliderModule } from '@angular/material/slider';
import { MatIconModule } from '@angular/material/icon';
import { EmailValidationFormComponent } from './oferta/email-validation-form/email-validation-form.component';
import { OfertaComponent } from './oferta/oferta.component';
import { PostulationFormComponent } from './oferta/postulation-form/postulation-form.component';
import { ReclutamientoPublicoService } from './reclutamiento-publico.service';

@NgModule({
  declarations: [OfertaComponent, EmailValidationFormComponent, PostulationFormComponent],
  imports: [
    PublicRoutingModule,
    CommonModule,
    FileSelectorComponent,
    FormsModule,
    ReactiveFormsModule,
    ReclutamientoSharedModule,
    ComentariosModule,
    SharedModule,
    NgxNeoDirectivesModule,
    QuillModule,
    TranslateModule,
    NgxNeoPipesModule,
    ShowRecaptchaDirective,
    NgOptimizedImage,
    NgxSkeletonLoaderModule,
    SkeletonCardComponent,
    CircleImageComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatIconModule,
  ],
  providers: [ReclutamientoPublicoService],
})
export class PublicModule {}
