import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { PersonalEntregaModelDTO } from 'src/app/ModelDTO';
import { EstadoEntrega, PersonalEntregaDTO, PersonalLegajoBasicoDTO, TipoEntregaDTO } from 'src/app/ModelDTO/DTO';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { ListadoDeEntregasService } from 'src/app/layout/administrador/administrador-entregas/listado-de-entregas.service';
import { DUED_RETURN } from 'src/app/layout/administrador/administrador-entregas/listado-de-entregas/listado-de-entregas.component';
import { TiposDeEntregaService } from 'src/app/layout/administrador/configuracion/tipos-de-entrega/tipos-de-entrega.service';

@Injectable({
  providedIn: 'root',
})
export class ListadoDeEntregasViewModel {
  public desde: Date;
  public hasta: Date;
  public userSelected: PersonalLegajoBasicoDTO;
  public entregasFiltradas: PersonalEntregaDTO[];
  public estadosEntregas: string[];
  public estadoEntrega: string;
  public pageNumber: number;
  public pageSize = 50;
  public total: number;

  public tiposDeEntrega: TipoEntregaDTO[];
  public tipoDeEntrega: TipoEntregaDTO;

  constructor(
    private headerService: HeaderAppService,
    private listadoDeEntregasService: ListadoDeEntregasService,
    private tipoEntregaService: TiposDeEntregaService,
    private translate: TranslateService,
  ) {
    this.initViewModel();

    this.headerService.loggedOut$.subscribe((data) => {
      if (data) {
        this.initViewModel();
      }
    });
  }

  public initViewModel(): void {
    this.total = 0;
    this.pageNumber = 0;
    this.estadoEntrega = EstadoEntrega[EstadoEntrega.No_definido];
    this.userSelected = new PersonalLegajoBasicoDTO();
    this.desde = DateTime.now().minus({ months: 3 }).toJSDate();
    this.hasta = DateTime.now().plus({ months: 3 }).toJSDate();
    this.entregasFiltradas = [];
    this.estadosEntregas = PersonalEntregaModelDTO.getEstadoEntrega();
    const ind = this.estadosEntregas.findIndex((x) => x === EstadoEntrega[EstadoEntrega.Solicitud_pendiente]);
    this.estadosEntregas.splice(ind, 1);
    this.tipoDeEntrega = new TipoEntregaDTO();
    this.tiposDeEntrega = [];
  }

  public async loadData(): Promise<void> {
    this.tiposDeEntrega = await this.tipoEntregaService.obtenerTiposDeEntrega();
    this.agregarTodos();
  }

  public async filtrar(): Promise<void> {
    if (this.desde && this.hasta) {
      const estado = this.estadoEntrega === DUED_RETURN ? EstadoEntrega.Entregado : EstadoEntrega[this.estadoEntrega];
      this.entregasFiltradas = await this.listadoDeEntregasService.obtenerEntregasFiltradas(
        this.desde,
        this.hasta,
        estado,
        this.tipoDeEntrega ?? new TipoEntregaDTO(),
        this.userSelected?.id,
        undefined,
        this.estadoEntrega === DUED_RETURN,
      );
    } else {
      this.entregasFiltradas = undefined;
      this.entregasFiltradas = [];
    }
  }

  public async exportar(): Promise<void> {
    if (this.desde && this.hasta) {
      await this.listadoDeEntregasService.exportarEntregasFiltradasEnExcel(
        this.desde,
        this.hasta,
        EstadoEntrega[this.estadoEntrega],
        this.tipoDeEntrega,
        this.userSelected?.id ?? 0,
      );
    }
  }

  private agregarTodos(): void {
    const tipoTodos = new TipoEntregaDTO();
    tipoTodos.nombre = this.translate.instant('GENERAL.ALL');
    this.tiposDeEntrega.unshift(tipoTodos);
  }
}
