import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { ReclutamientoOfertaPublicaDTO } from '@api/interfaces/reclutamiento-oferta-publica.interface';
import { Subscription } from 'rxjs';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { ReclutamientoCampo } from 'src/app/ModelDTO/DTO/reclutamientoCampo.ENUM';
import { CandidateImageData } from 'src/app/shared/seleccionar-profile-img/seleccionar-profile-img.component';
import { quillModuloCelular, QUILL_MODULO_CON_EMOJIS } from 'src/app/shared/shared-functions';

@Component({
  selector: 'app-postulation-form',
  templateUrl: './postulation-form.component.html',
  styleUrls: ['./postulation-form.component.scss'],
})
export class PostulationFormComponent implements OnInit, OnDestroy {
  @Input() public postulatedCandidateForm: FormGroup;
  @Input() public publicOffer: ReclutamientoOfertaPublicaDTO;
  @Input() public successfulApplication: boolean;
  @Input() public imageData: CandidateImageData;
  @Output() public submitApplication = new EventEmitter();
  @Output() public filesChange = new EventEmitter<File[]>();
  @Input() public files: File[];

  public isSubmitted = false;
  public currentStep = 1;
  public quill_module = QUILL_MODULO_CON_EMOJIS;
  public cvRequired: boolean;
  public phoneRequired: boolean;
  public linkedInRequired: boolean;
  public profilePictureRequired: boolean;
  public coverLetterRequired: boolean;

  public get imageControl(): AbstractControl {
    return this.postulatedCandidateForm.get('candidate.image');
  }

  public get skillsFormArray(): FormArray {
    return this.postulatedCandidateForm.get('skills') as FormArray;
  }

  private subs: Subscription = new Subscription();
  constructor(private headerNaalooService: HeaderAppService) {}

  public ngOnInit(): void {
    this.subs.add(
      this.headerNaalooService.smallScreen$.subscribe((small) => {
        this.quill_module = small ? quillModuloCelular : QUILL_MODULO_CON_EMOJIS;
      }),
    );

    this.cvRequired = this.publicOffer.campos.find((x) => x.campo === ReclutamientoCampo.CV)?.obligatorio;
    this.phoneRequired = this.publicOffer.campos.find((x) => x.campo === ReclutamientoCampo.Telefono)?.obligatorio;
    this.linkedInRequired = this.publicOffer.campos.find((x) => x.campo === ReclutamientoCampo.Linkedin)?.obligatorio;
    this.profilePictureRequired = this.publicOffer.campos.find((x) => x.campo === ReclutamientoCampo.Foto)?.obligatorio;
    this.coverLetterRequired = this.publicOffer.campos.find((x) => x.campo === ReclutamientoCampo.Carta)?.obligatorio;
  }

  public onSubmit(): void {
    this.isSubmitted = true;
    this.submitApplication.emit();
  }

  public skillScoreControl(control: AbstractControl): FormControl {
    return control.get('score') as FormControl;
  }

  public prevStep(): void {
    this.currentStep -= 1;
  }

  public nextStep(): void {
    this.currentStep += 1;
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
