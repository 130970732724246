import { Injectable } from '@angular/core';
import { TipoEntregaServiceBackend } from 'src/app/ServiceBackend';
import { TipoEntregaDTO } from 'src/app/ModelDTO/DTO';

@Injectable({
  providedIn: 'root',
})
export class TiposDeEntregaService {
  private tipoAEditar: TipoEntregaDTO;

  constructor(private tipoEntregaServiceBackend: TipoEntregaServiceBackend) {}

  public async obtenerTiposDeEntrega(): Promise<Array<TipoEntregaDTO>> {
    const res = await this.tipoEntregaServiceBackend.getTipoEntrega();
    return res;
  }

  public async obtenerTipoEntregaId(id: number): Promise<TipoEntregaDTO> {
    return this.tipoEntregaServiceBackend.getTipoEntregaId(id);
  }

  public async agregarTipo(tipo: TipoEntregaDTO): Promise<TipoEntregaDTO> {
    const res = await this.tipoEntregaServiceBackend.insertTipoEntrega(tipo);
    return res;
  }

  public async editarTipo(tipo: TipoEntregaDTO): Promise<TipoEntregaDTO> {
    const res = await this.tipoEntregaServiceBackend.updateTipoEntregaId(tipo.id, tipo);
    return res;
  }

  public async eliminarTipo(tipo: TipoEntregaDTO): Promise<void> {
    await this.tipoEntregaServiceBackend.deleteTipoEntregaId(tipo.id);
  }

  public async obtenerEntregasXLegajo(legajoId: number) {
    const res = await this.tipoEntregaServiceBackend.getTipoEntregaPersonalIDPERSONALLEGAJO(legajoId);
    return res;
  }

  public async obtenerEntregasXConvenio(idConvenio: number) {
    const res = await this.tipoEntregaServiceBackend.getTipoEntregaConvenioIDCONVENIO(idConvenio);
    return res;
  }

  public async obtenerEntregasPorPersonal(legajoId: number): Promise<TipoEntregaDTO[]> {
    const res = await this.tipoEntregaServiceBackend.getTipoEntregaFullpersonalIDPERSONALLEGAJO(legajoId);
    return res;
  }
}
