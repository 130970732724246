<div class="d-flex flex-column flex-grow-1 gap-1">
  <app-question-type-header [position]="position" [title]="data.texto" [mandatory]="data.obligatoria"></app-question-type-header>

  <div class="d-flex flex-row align-items-center flex-grow-1 flex-wrap" *ngIf="!modoAdministrador">
    <mat-form-field class="w-100 w-lg-33" appearance="outline">
      <mat-label>Valor</mat-label>
      <input
        #rtaNumeric
        #numericModel="ngModel"
        matInput
        type="number"
        id="rtaNumeric"
        name="rtaNumeric"
        class="form-control"
        [(ngModel)]="data.respuesta.valorNumerico"
        (ngModelChange)="respuestaChange(data.respuesta, $event)"
        [disabled]="!editionMode && estadoCuestionario === cuestionarioTerminado"
        [min]="data.min"
        [max]="data.max"
        step="1"
        pattern="[0-9]*$"
      />
      <mat-error *ngIf="numericModel.errors?.pattern">{{ 'GENERAL.ONLY_NUMERIC_VALUES' | translate }}</mat-error>
    </mat-form-field>
    <mat-slider
      class="flex-fill"
      color="primary"
      [min]="data.min"
      [max]="data.max"
      step="1"
      discrete
      [disabled]="!editionMode && estadoCuestionario === cuestionarioTerminado"
    >
      <input
        matSliderThumb
        [value]="null"
        [(ngModel)]="data.respuesta.valorNumerico"
        (ngModelChange)="respuestaChange(data.respuesta, $event)"
      />
    </mat-slider>
  </div>

  <label *ngIf="modoAdministrador" class="flex-fill mb-2"> Valor seleccionado: {{ answer }}</label>
</div>
