<div class="d-flex flex-column flex-grow-1">
  <ng-container *ngIf="publicOffer.estado === ReclutamientoEstadoOferta.Publicada">
    <div class="container">
      <img src="assets/img/recruitment-header.png" class="hide-md" height="1920" width="573" priority />
      <img src="assets/img/recruitment-background-1.png" class="hidden-lg" height="727" width="589" priority />
      <div class="info-container d-flex flex-column">
        <span class="search"> {{ 'RECRUITMENT.JOB_SEARCH' | translate }}: </span>
        <h1 class="title m-0">{{ publicOffer.titulo }}</h1>
        <span class="business">{{ publicOffer.companyName }}</span>
      </div>
    </div>
    <div class="container-description d-flex">
      <div class="d-flex flex-column">
        <span> {{ 'GENERAL.BUSINESS' | translate }}: {{ publicOffer.companyName }}</span>
        <span *ngIf="publicOffer.nombreOficina"> {{ 'GENERAL.OFFICE' | translate }}: {{ publicOffer.nombreOficina }}</span>
        <span *ngIf="publicOffer.area"> {{ 'GENERAL.AREA_SECTOR' | translate }}: {{ publicOffer.area }}</span>
        <span *ngIf="publicOffer.location !== WorkLocationType.Undefined">
          {{ 'GENERAL.FORMS.MODALITY' | translate }}:
          {{ WorkLocationType[publicOffer.location] | enumTranslate: 'ENUM.RECRUITMENT.WORK_LOCATION_TYPE' }}
        </span>
        <div class="container-detail">
          <div class="container-location" *ngIf="publicOffer.location !== WorkLocationType.Remote">
            <h4 class="primary-color d-flex align-items-center">
              <mat-icon fontSet="material-symbols-outlined" color="primary" class="mr-2">location_on</mat-icon>
              {{ 'RECRUITMENT.LOCATION' | translate }}
            </h4>
            <span class="primary-color">
              {{ 'GENERAL.COUNTRY' | translate }}:<label> {{ publicOffer.localidad.provincia.pais.nombre }} </label>
            </span>
            <span class="primary-color">
              {{ 'GENERAL.PROVINCE' | translate }}: <label>{{ publicOffer.localidad.provincia.nombre }} </label>
            </span>
            <span class="primary-color">
              {{ 'GENERAL.CITY' | translate }}: <label>{{ publicOffer.localidad.nombre }} </label>
            </span>
            <span class="primary-color">
              {{ 'GENERAL.ZIP_CODE' | translate }}:<label>{{ publicOffer.localidad.codigoPostal }} </label>
            </span>
          </div>

          <div class="d-flex flex-column">
            <h4 class="primary-color d-flex align-items-center">
              <mat-icon fontSet="material-symbols-outlined" class="mr-2" color="primary">info</mat-icon>
              {{ 'GENERAL.DETAILS' | translate }}
            </h4>
            <span class="primary-color">
              {{ 'RECRUITMENT.CONTRACT' | translate }}: <label> {{ publicOffer.tipoContrato.nombre }} </label>
            </span>
            <span class="primary-color"
              >{{ 'WORK_DAY.TITLE_SINGULAR' | translate }}: <label>{{ publicOffer.tipoJornada | ofertaTipoJornada }} </label>
            </span>
            <ng-container *ngIf="publicOffer.remunerationVisible">
              <span class="primary-color">
                {{ remunerationLabel }}:
                <label *ngIf="!publicOffer.salarioAnual; else annualSalary"
                  >{{ publicOffer.desde | currency: publicOffer.currency.display }} -
                  {{ publicOffer.hasta | currency: publicOffer.currency.display }}</label
                >
                <ng-template #annualSalary>
                  <label> {{ publicOffer.salarioAnual | currency: publicOffer.currency.display }}</label>
                </ng-template></span
              >
              <span class="primary-color">
                {{ 'GENERAL.CURRENCY' | translate }}: <label>{{ publicOffer.currency.name }} </label>
              </span>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="container-image">
        <app-circle-image [width]="120" [imageSource]="companyImage"> </app-circle-image>
      </div>
      <div class="button-postulate">
        <button (click)="scrollEmail()" mat-raised-button color="primary">
          {{ 'RECRUITMENT.APPLY_NOW' | translate }}
        </button>
      </div>
    </div>

    <div class="job-description d-flex flex-column flex-grow-1">
      <h1 class="title m-0">
        {{ 'RECRUITMENT.JOB_DESCRIPTION' | translate }}
      </h1>
      <quill-view-html class="ql-font" [content]="publicOffer.descripcion"></quill-view-html>
    </div>

    <div *ngIf="publicOffer.benefits" class="job-description d-flex flex-column flex-grow-1">
      <h1 class="title m-0">
        {{ 'RECRUITMENT.BENEFITS' | translate }}
      </h1>
      <quill-view-html class="ql-font" [content]="publicOffer.benefits"></quill-view-html>
    </div>
  </ng-container>
  <div class="container" id="email">
    <div *ngIf="!hideForm; else messageLink">
      <img src="assets/img/recruitment-form-1.png" class="hide-md" />
      <img src="assets/img/recruitment-form-2.png" class="hidden-lg" />
      <div class="form-container d-flex flex-column">
        <h1 class="title m-0" *ngIf="publicOffer.estado !== ReclutamientoEstadoOferta.Publicada; else public">
          {{ 'RECRUITMENT.THIS_OFFER_IS_CLOSED' | translate }}
        </h1>
        <ng-template #public>
          <h1 class="title m-0">
            {{ 'RECRUITMENT.WANT_APPLY_FOR_THIS_JOB' | translate }}
          </h1>
          <span> {{ 'RECRUITMENT.WE_WILL_SEND_A_LINK_TO_YOUR_EMAIL' | translate }} </span>
          <form [formGroup]="validateEmailForm" (ngSubmit)="onSubmit()" class="gap-1">
            <mat-form-field class="w-100 flex-column">
              <mat-label> {{ 'GENERAL.EMAIL' | translate }} </mat-label>
              <input matInput formControlName="email" />
              <mat-error *ngIf="validateEmailForm.invalid && validateEmailForm.touched">
                {{ 'GENERAL.INVALID_EMAIL' | translate }}
              </mat-error>
            </mat-form-field>
            <button mat-raised-button color="primary" type="submit" [disabled]="!validateEmailForm.valid">
              {{ 'GENERAL.SEND' | translate }}
            </button>
          </form>
        </ng-template>
      </div>
    </div>
    <ng-template #messageLink>
      <img src="assets/img/recruitment-message-link-1.png" class="hide-md" height="1920" width="600" />
      <img src="assets/img/recruitment-message-link-2.png" class="hidden-lg" height="726" width="632" />
      <div class="message-container d-flex flex-column">
        <h1 class="title mt-0">{{ 'RECRUITMENT.EMAIL_SENT_TO_YOUR_EMAIL_BOX' | translate }}</h1>
        <span> {{ 'RECRUITMENT.CONTINUE_UPLOADING_DATA_AND_CV' | translate }} </span>
      </div>
    </ng-template>
  </div>
</div>
