<div class="d-flex flex-column mb-2 gap-1">
  <app-question-type-header [position]="position" [title]="data.texto" [mandatory]="data.obligatoria"></app-question-type-header>
  <div *ngFor="let opcion of arrayOpcionMultiSelect">
    <mat-checkbox
      [ngModel]="opcion.selected"
      (ngModelChange)="respuestaChange(opcion, $event)"
      [disabled]="!editionMode && (modoAdministrador || estadoCuestionario === cuestionarioTerminado)"
      class="flex-fill"
      color="primary"
    >
      {{ opcion.opcion.texto }}
    </mat-checkbox>
  </div>
</div>
