import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-question-type-header',
  templateUrl: './question-type-header.component.html',
  styleUrls: ['./question-type-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionTypeHeaderComponent {
  @Input() position: number;
  @Input() title: string;
  @Input() mandatory: boolean;
}
