<mat-card *ngIf="show" [ngClass]="{ 'color-warn-background': !default }" class="no-shadow">
  <mat-card-content>
    <div class="d-flex flex-row align-items-center w-100">
      <mat-icon fontSet="material-symbols-outlined" class="warning-icon flex-shrink-0" *ngIf="!default">warning</mat-icon>
      <p [ngClass]="default ? 'gray-text-muted text-center w-100' : 'color-white pl-6'">
        {{ mensaje }}
        <ng-content></ng-content>
      </p>
    </div>
    <button (click)="show = false" *ngIf="showCloseButton" mat-icon-button>
      <mat-icon fontSet="material-symbols-outlined">close</mat-icon>
    </button>
  </mat-card-content>
</mat-card>
