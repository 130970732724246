<div class="d-flex flex-row mb-4">
  <div class="d-flex flex-row align-items-center w-75">
    <app-circle-image [width]="40" [center]="false" class="mr-2" [personalLegajo]="entregaModel.PersonalLegajo"> </app-circle-image>
    <div class="d-flex flex-column">
      <h4 class="no-margin line-1">
        {{ entregaModel.PersonalLegajoModel.NombreCompleto }}
      </h4>
      <small class="light-gray-text-color">
        {{ 'PROFILE.FILE' | translate }}: {{ entregaModel.PersonalLegajoModel.Legajo }} | {{ 'PROFILE.TIN' | translate }}:
        {{ entregaModel.PersonalLegajoModel.Cuil }}</small
      >
    </div>
  </div>
  <div class="d-flex justify-content-end w-25">
    <mat-chip-set class="pt-1" aria-label="estado solicitud">
      <mat-chip class="text-nowrap" [color]="color">
        {{ status }}
      </mat-chip>
    </mat-chip-set>
  </div>
</div>
<p class="m-0">
  {{ 'GENERAL.QUANTITY' | translate }}:
  <strong>{{ entregaModel.Cantidad }}</strong>
</p>
<p class="m-0">
  {{ 'DELIVERIES.AGREE_DELIVERY_DATE' | translate }}:
  <strong>{{ entregaModel.FechaPactada | ngxdate: 'shortDateFull' }}</strong>
</p>
<p class="m-0">
  {{ 'GENERAL.RENEWAL' | translate }}: <strong></strong
  >{{ entregaModel.VencimientoAproximado ? (entregaModel.VencimientoAproximado | ngxdate: 'shortDateFull') : '' }}
</p>
<p class="m-0" *ngIf="entregaModel.Creador?.id">
  {{ 'GENERAL.CREATOR' | translate }}:<strong> {{ entregaModel.Creador.nombreCompleto }}</strong>
</p>
<p class="m-0" *ngIf="solicitudModel?.Responsable?.id">
  {{ 'GENERAL.RESPONSIBLE' | translate }} {{ solicitudModel.Estado === 'Aprobado' ? 'aprobación' : 'rechazo' }}:<strong>
    {{ solicitudModel.Responsable.nombreCompleto }}
  </strong>
</p>
<p class="m-0" *ngIf="!entregaModel.ConSolicitud">{{ 'DELIVERIES.CREATED_WITHOUT_REQUEST' | translate }}</p>
<div *ngIf="entregaModel.Adjuntos?.length" class="d-flex flex-row no-margin my-2">
  <app-file-chips [attachments]="entregaModel.Adjuntos" [noFilesMessage]="''"></app-file-chips>
</div>
<div class="d-flex flex-column no-margin mt-2">
  <p class="light-gray-text-color">{{ 'GENERAL.REASON' | translate }}:</p>
  <p>{{ entregaModel.Motivo ? entregaModel.Motivo : 'No tiene' }}</p>
</div>
