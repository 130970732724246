import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum NotificationState {
  NotRead = 0,
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public notifications: any[] = new Array<any>();
  public notificationsNotSeen = 0;

  public usersAdministrative: Array<any>;

  public notificationMaxPriority = 0;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async createNewNotification(title: string, details: string, usersName: string[]): Promise<void> {
    return;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async readNotification(notificationDTO: any): Promise<void> {
    return;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async archivedNotification(notificationDTO: any): Promise<void> {
    return;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async unArchivedNotification(notificationDTO: any): Promise<void> {
    return;
  }

  public async ClearBell(): Promise<void> {
    return;
  }
}
