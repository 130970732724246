/* eslint-disable max-classes-per-file */
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { DateTime } from 'luxon';
import { DeleteAction, ImageSpec, ResizeAction } from 'quill-blot-formatter';
import { Observable } from 'rxjs';
import { filter, map, pairwise, throttleTime } from 'rxjs/operators';
import { SancionDTO } from 'src/app/ModelDTO/DTO';
import { DomicilioDTO } from 'src/app/ModelDTO/DTO/domicilio.DTO';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { NotificationDTO, NotificationTotalDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models';
import { Reaction } from 'src/app/shared/reactions/reaction';
import { urlAPI } from './constants';

export const parseLink = (link: string, ext = ''): string => (link.startsWith('http') ? link : `${urlAPI}${link}${ext}`);

export const extensionArchivo = (archivo: string): string => {
  if (archivo === null || archivo === undefined) {
    return '';
  }
  const punto = archivo.lastIndexOf('.');
  const extensionString = archivo.substring(punto, archivo.length)?.toLocaleLowerCase();
  return extensionString;
};

export const forwardMonths = (numberMonths: number, todayIncluded: boolean): Array<Date> => {
  let dateActual = new Date();
  const forward = new Array<Date>();
  if (todayIncluded) {
    forward.push(new Date());
  }
  for (let i = 0; i < numberMonths; i++) {
    dateActual = new Date(dateActual.getFullYear(), dateActual.getMonth() + 1, dateActual.getDate());
    forward.unshift(dateActual);
  }
  return forward;
};

// For calendars or date pickers
export const invertedForwardMonths = (numberMonths: number, todayIncluded: boolean): Array<Date> => {
  const dateActual = new Date();
  let dateActualFormat = new Date(dateActual.getFullYear(), dateActual.getMonth(), 1);
  const forward = new Array<Date>();
  if (todayIncluded) {
    forward.unshift(new Date());
  }
  for (let i = 0; i < numberMonths; i++) {
    dateActualFormat = new Date(dateActualFormat.getFullYear(), dateActualFormat.getMonth() + 1, dateActualFormat.getDate());
    forward.push(dateActualFormat);
  }
  return forward;
};

export const getDaysArray = (start: Date, end: Date): Date[] => {
  const arr: Date[] = [];
  for (const dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt));
  }
  return arr;
};

export const sameDay = (day1: Date, day2: Date): boolean =>
  day1.getDate() === day2.getDate() && day1.getMonth() === day2.getMonth() && day1.getFullYear() === day2.getFullYear();

export const sameDateTimeDay = (day1: DateTime, day2: DateTime): boolean =>
  day1.hasSame(day2, 'day') && day1.hasSame(day2, 'month') && day1.hasSame(day2, 'year');

export const isToday = (date: Date): boolean => {
  const today = new Date();
  return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
};

export const isTodayOrLess = (date: Date): boolean => isToday(date) || DateTime.fromJSDate(date).toMillis() < DateTime.now().toMillis();

export const daysBetween = (startDate: Date, endDate: Date): number => {
  // The number of milliseconds in all UTC days (no DST)
  const oneDay = 1000 * 60 * 60 * 24;

  // A day in UTC always lasts 24 hours (unlike in other time formats)
  const start = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
  const end = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());

  // so it's safe to divide by 24 hours
  return (start - end) / oneDay;
};

export const compareDtoId = (entityOne: any, entityTwo: any): boolean =>
  entityOne && entityTwo ? entityOne.id === entityTwo.id : entityOne === entityTwo;

export const compareModelId = (entityOne: any, entityTwo: any): boolean =>
  entityOne && entityTwo ? entityOne.Id === entityTwo.Id : entityOne === entityTwo;

export const trackByModel = (index: number, item: any): number => item.Id;

export const trackByDto = (index: number, item: any): number => item.id;

export const fileIconClass = (fileName: string): string => {
  const fileExtension = extensionArchivo(fileName);

  switch (fileExtension) {
    case '.pdf':
      return 'pdf';
    case '.xls':
    case '.xlsx':
      return 'xls';
    case '.doc':
    case '.docx':
      return 'doc';
    case '.jpg':
    case '.jpeg':
      return 'jpg';
    case '.png':
      return 'png';
    case '.mp3':
      return 'mp3';
    case '.mp4':
      return 'mp4';
    case '.ppt':
      return 'ppt';
    case '.avi':
      return 'avi';
    case '.csv':
      return 'csv';
    case '.txt':
      return 'txt';
    default:
      return 'file';
  }
};

export const hasImageExtension = (fileName: string): boolean => {
  const fileExtension = extensionArchivo(fileName);
  if (fileExtension === '.jpg' || fileExtension === '.png' || fileExtension === '.jpeg') {
    return true;
  }
  return false;
};

export const hasPdfExtension = (fileName: string): boolean => {
  const fileExtension = extensionArchivo(fileName);
  if (fileExtension === '.pdf') {
    return true;
  }
  return false;
};

export const stringAMinuscula = (palabra: string): string => {
  let palabraMinuscula = '';
  // eslint-disable-next-line no-restricted-syntax
  for (const letra of palabra) {
    palabraMinuscula += letra.toLowerCase();
  }
  return palabraMinuscula;
};

export const onEndScrollReached = (scrollViewport: CdkVirtualScrollViewport, minHeight = 180): Observable<[number, number]> =>
  scrollViewport?.elementScrolled().pipe(
    map(() => scrollViewport.measureScrollOffset('bottom')), // Devuelve el offset con respecto al final de abajo
    pairwise(),
    filter(([y1, y2]) => y2 < y1 && y2 < minHeight), // filtrar y quedarse solo con el scroll descendente. minHeight es para que emitir el evento antes de llegar al último (ej: si cada fila tiene 60px de alto entonces se emite un evento 3 filas antes (60*3=180)).
    throttleTime(300), // Emitir eventos cada 300ms
  );

export const getCreatorFromNotification = (notification: NotificationDTO | NotificationTotalDTO): PersonalLegajoBasicoDTO => {
  const personal = new PersonalLegajoBasicoDTO();
  personal.image = notification.image;
  personal.nombreCompleto = notification.userName;
  personal.id = notification.teammateFileCreatorId || notification.creatorId;
  return personal;
};

export class BreakpointUtils {
  public static isSmall(breakpointObserver: BreakpointObserver): Observable<BreakpointState> {
    return breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall]);
  }

  public static initBreakpointObserver(breakpointObserver: BreakpointObserver): boolean {
    return breakpointObserver.isMatched('(max-width: 599px)');
  }
  public static initBreakpointObserverLarge(breakpointObserver: BreakpointObserver): boolean {
    return breakpointObserver.isMatched('(max-width: 991px)');
  }
}

class CustomImageSpec extends ImageSpec {
  public getActions(): (typeof ResizeAction | typeof DeleteAction)[] {
    return [ResizeAction, DeleteAction];
  }
}

export const QUILL_MODULO_CON_EMOJIS = {
  'emoji-shortname': true,
  'emoji-textarea': true,
  'emoji-toolbar': true,
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ align: [] }],
    ['clean'], // remove formatting button
    ['link', 'image', 'video'], // link and image, video
  ],
  blotFormatter: {
    specs: [CustomImageSpec],
  },
};

export const quillModuloCelular = {
  'emoji-shortname': true,
  'emoji-textarea': true,
  'emoji-toolbar': true,
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    ['link', 'image', 'video'], // link and image, video
  ],
  blotFormatter: {
    specs: [CustomImageSpec],
  },
};

export const quillModuloMinimal = {
  'emoji-shortname': true,
  'emoji-textarea': true,
  'emoji-toolbar': true,
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['link'], // link and image, video
  ],
};

export const toUTCDate = (date: Date): Date =>
  new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()));

export const compare = (a: number | string | boolean, b: number | string | boolean, isAsc: boolean): number => {
  // equal items sort equally
  if (a === b) {
    return 0;
  }

  // nulls sort after anything else
  if (!a) {
    return 1;
  }
  if (!b) {
    return -1;
  }

  // otherwise, if we're ascending, lowest sorts first
  if (isAsc) {
    return a < b ? -1 : 1;
  }

  // if descending, highest sorts first
  return a < b ? 1 : -1;
};

export const domicilioGoogleMaps = (domicilio: DomicilioDTO): string => {
  if (domicilio) {
    const address = `${domicilio.direccion} ${domicilio.localidad?.codigoPostal} ${domicilio.localidad?.nombre} ${domicilio.localidad?.provincia?.nombre}`;
    const url = encodeURIComponent(address);
    return `http://maps.google.com/maps?q=${url}`;
  }
  return '';
};

export const sortSancionFechaCarga = (sanctionsList: SancionDTO[]): SancionDTO[] =>
  sanctionsList.sort(
    (a: SancionDTO, b: SancionDTO) => DateTime.fromJSDate(b.fechaCarga).toMillis() - DateTime.fromJSDate(a.fechaCarga).toMillis(),
  );

export const weekendDays = (startDate: DateTime, endDate: DateTime): DateTime[] => {
  let currentDate = startDate.startOf('day');
  const end = endDate.startOf('day');
  const weekendDaysArray = [];
  while (currentDate <= end) {
    const dayOfWeek = currentDate.weekday;
    if (dayOfWeek === 6 || dayOfWeek === 7) {
      weekendDaysArray.push(currentDate);
    }
    currentDate = currentDate.plus({ days: 1 });
  }
  return weekendDaysArray;
};

export const convertJsonDates = (json: unknown): unknown => {
  if (typeof json !== 'object' || json === null) {
    // Base case: if json is not an object or is null, return it as is
    return json;
  }

  if (Array.isArray(json)) {
    // If json is an array, map over its elements and recursively convert each element
    return json.map((element: unknown) => convertJsonDates(element));
  }

  // Create a new object to store the converted values
  const convertedJson: unknown = {};

  Object.keys(json).forEach((key) => {
    const value = json[key];
    if (typeof value === 'string' && value?.length > 5 && DateTime.fromISO(value).isValid) {
      // If the value is a valid Luxon date string, (exlude 00:00) strings convert it to a Luxon DateTime object
      convertedJson[key] = DateTime.fromISO(value);
    } else if (typeof value === 'object') {
      // If the value is an object, recursively convert its properties
      convertedJson[key] = convertJsonDates(value);
    } else {
      // Otherwise, just copy the value as is
      convertedJson[key] = value;
    }
  });
  return convertedJson;
};

export const parseStringReactions = (reactionsString: string): Reaction[] => {
  const reactions: Reaction[] = [];
  if (reactionsString?.length) {
    const emojis = reactionsString.split('|');

    emojis.forEach((emoji) => {
      const [unicodeEmoji, quantity] = emoji.split('^');
      const newReaction: Reaction = {
        emoji: unicodeEmoji,
        quantity: parseInt(quantity, 10),
      };

      reactions.push(newReaction);
    });
  }
  return reactions;
};

export const classToJson = <T, U>(instance: T): U => {
  // If instance is not an object or is null, return it as is
  if (typeof instance !== 'object' || instance === null) {
    return instance as unknown as U; // Type assertion to U
  }

  const jsonObject: any = {};

  // Iterate over each property of the instance
  Object.keys(instance).forEach((key) => {
    const value = (instance as any)[key];

    if (value instanceof Date) {
      // Recursively convert Date to DateTime if value is a Date
      jsonObject[key] = DateTime.fromJSDate(value);
    } else if (Array.isArray(value)) {
      jsonObject[key] = value.map((x) => classToJson(x));
    } else {
      jsonObject[key] = classToJson(value);
    }
  });

  return jsonObject as unknown as U; // Type assertion to U
};
