import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

(window as any).angularBootstrapped = false;

if (environment.production) {
  enableProdMode();
}

const bootstrap = (): void => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(() => {
      (window as any).angularBootstrapped = true;
    })
    .catch((err) => console.error(err));
};

const isCordovaApp = (): boolean =>
  // eslint-disable-next-line no-prototype-builtins
  window.hasOwnProperty('cordova');

Sentry.init({
  dsn: 'https://9e918c1f96be4e1aa181d1f218e70f59@o1174002.ingest.sentry.io/6269674',
  release: `uaaloo-${environment.production ? 'prod' : 'dev'}@${environment.appVersion}`,
  dist: environment.appVersion,
  integrations: [Sentry.browserTracingIntegration()],
  tracePropagationTargets: ['localhost', /^https:\/\/backend\.naaloo-qa\.com/, /^https:\/\/backend\.naaloo\.com/],
  environment: environment.production ? 'prod' : 'dev',
  enabled: environment.production,
  tracesSampleRate: 0.2,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0.9,
});

if (isCordovaApp()) {
  document.addEventListener(
    'deviceready',
    () => {
      bootstrap();
    },
    false,
  );
} else {
  bootstrap();
}
