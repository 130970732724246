import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ComentarioDTO, ComentarioVisibilidad } from 'src/app/ModelDTO/DTO';
import { ComentarioBase } from './comentario-base';
import { ComentariosService } from './comentarios.service';

@Injectable({
  providedIn: 'root',
})
export class ComentariosEventoHandler extends ComentarioBase {
  constructor(
    private comentarioService: ComentariosService,
    private translateService: TranslateService,
  ) {
    super();
  }

  public async editMessage(id: number, mensaje: ComentarioDTO): Promise<void> {
    await this.comentarioService.editarMensajesEnEventos(id, mensaje);
  }
  public async deleteMessage(id: number, mensaje: ComentarioDTO): Promise<void> {
    await this.comentarioService.borrarMensajesEnEventos(id, mensaje);
  }

  public async sendMessage(id: number, mensaje: FormData): Promise<void> {
    await this.comentarioService.enviarMensajesEnEventos(id, mensaje);
  }

  public async onInit(id: number): Promise<Array<ComentarioDTO>> {
    const comentario = await this.comentarioService.obtenerMensajesEnEventos(id);
    return comentario;
  }

  public isPrivateDefault(): boolean {
    return false;
  }

  public showPrivate(): boolean {
    return false;
  }

  public getTitle(): string {
    return '';
  }
  public needPadding(): boolean {
    return false;
  }

  public getPlaceHolder(tipoVisibilidad: ComentarioVisibilidad): string {
    if (tipoVisibilidad === ComentarioVisibilidad.Publico) {
      return this.translateService.instant('EVENTS.WRITE_COMMENT_MESSAGE');
    }
    return this.translateService.instant('EVENTS.WRITE_PRIVATE_NOTE');
  }

  public getNoDataString(): string {
    return this.translateService.instant('EVENTS.NO_COMMENT_MESSAGE');
  }
}
