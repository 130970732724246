import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PerfilEmpresaService } from 'src/app/layout/administrador/perfil-empresa/perfil-empresa.service';
import { ConvenioColectivoDTO, ResultadoImportacionDTO } from 'src/app/ModelDTO/DTO';
import { ConvenioColectivoServiceBackend, ImportacionConvenioServiceBackend } from 'src/app/ServiceBackend';
import { ExportacionConvenioServiceBackend } from 'src/app/ServiceBackend/exportacionConvenio.ServiceBackend';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models';
import { FileDownloaderService } from 'src/app/shared/services/fileDownloader.service';

enum NacionalityEnum {
  Argentina = 1,
}

@Injectable({
  providedIn: 'root',
})
export class ConvenioColectivoService {
  public get withoutAgreement(): string {
    if (this.perfilEmpresaService.company.pais.id === NacionalityEnum.Argentina) {
      return `${this.translateService.instant('COLLECTIVE_AGREEMENTS.NOT_ASSIGNED')} (${this.translateService.instant('COLLECTIVE_AGREEMENTS.EMPLOYMENT_CONTRACT_LAW')})`;
    }

    return this.translateService.instant('EMPLOYEES.NOT_ASSIGNED');
  }

  public get ConvenioAEditar(): ConvenioColectivoDTO {
    return this.convenioAEditar;
  }

  public set ConvenioAEditar(t: ConvenioColectivoDTO) {
    this.convenioAEditar = t;
  }

  private convenioAEditar: ConvenioColectivoDTO;
  private readonly FUERA_DE_CONVENIO = 'Sin asignar (LCT)';

  constructor(
    private conveniosServiceBackend: ConvenioColectivoServiceBackend,
    private importadorConvenioServiceBack: ImportacionConvenioServiceBackend,
    private exportadorConvenioServiceBack: ExportacionConvenioServiceBackend,
    private fileDownloaderService: FileDownloaderService,
    private perfilEmpresaService: PerfilEmpresaService,
    private translateService: TranslateService,
  ) {}

  public async obtenerConvenios(): Promise<Array<ConvenioColectivoDTO>> {
    const res = await this.conveniosServiceBackend.getConvenioColectivo();
    const sinConvenioDTO = new ConvenioColectivoDTO();
    sinConvenioDTO.id = 0;
    sinConvenioDTO.nombreCompleto = this.withoutAgreement;
    res.unshift(sinConvenioDTO);
    return res;
  }

  public async agregarConvenio(conv: ConvenioColectivoDTO): Promise<ConvenioColectivoDTO> {
    const res = await this.conveniosServiceBackend.insertConvenioColectivo(conv);
    return res;
  }

  public async editarConvenio(conv: ConvenioColectivoDTO): Promise<ConvenioColectivoDTO> {
    const res = await this.conveniosServiceBackend.updateConvenioColectivoId(conv.id, conv);
    return res;
  }

  public async eliminarConvenio(conv: ConvenioColectivoDTO): Promise<void> {
    await this.conveniosServiceBackend.deleteConvenioColectivoId(conv.id);
  }

  public async importarConvenio(formData: FormData): Promise<ResultadoImportacionDTO> {
    const res = await this.importadorConvenioServiceBack.insertImportacionesConvenios(formData);
    return res;
  }

  public async exportarConvenio(): Promise<void> {
    const blob = new NamedBlobDTO();
    await this.exportadorConvenioServiceBack.getExportacionesConvenios(blob);
    this.fileDownloaderService.saveAs(blob);
  }

  public nombreConvenio(convenio: ConvenioColectivoDTO): string {
    if (convenio) {
      return convenio.id !== 0 ? convenio.nombreCompleto : this.withoutAgreement;
    }
    return '---';
  }
}
