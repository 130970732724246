import { Injectable } from '@angular/core';
import { BaseErrorHandler } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { LAST_RELOAD } from 'src/app/shared/localStorageConstants';

@Injectable()
export class AppErrorhandlerService extends BaseErrorHandler {
  public handleError(error: any): Promise<void> {
    super.handleError(error);

    if (/Loading chunk.*/.test(error.message ?? '') || /Loading chunk.*/.test(error ?? '')) {
      this.handleChunkError();
    }

    // eslint-disable-next-line no-useless-return
    return;
  }

  public handleChunkError(): void {
    const now = new Date();
    const lastReload = localStorage.getItem(LAST_RELOAD);
    localStorage.setItem(LAST_RELOAD, now.toISOString());
    if (lastReload) {
      const reload = new Date(lastReload);
      // diff is in ms
      const dateDiff = (now.getTime() - reload.getTime()) / 1000;
      if (dateDiff > 60) {
        this.reloadPage();
      } else {
        console.error('Loading chunk failed');
      }
    } else {
      this.reloadPage();
    }
  }

  public reloadPage(): void {
    if ((window as any)?.cordova) {
      window.location.href = 'index.html';
    } else {
      window.location.reload();
    }
  }
}
