<div class="d-flex flex-row align-items-center p-4 border-dialog mb-0" mat-dialog-title>
  <ng-container *ngIf="personalModel; else loadingHeader">
    <div>
      <button class="button-back" *ngIf="!edicion" (click)="cancel()" mat-mini-fab>
        <mat-icon fontSet="material-symbols-outlined">arrow_back</mat-icon>
      </button>
      <button class="fix-size" *ngIf="edicion" [command]="cancelarCmd" mat-button color="primary">
        {{ 'GENERAL.CANCEL' | translate }}
      </button>
    </div>
    <div class="d-flex flex-grow-1 align-items-center justify-content-center">
      <app-circle-image [center]="false" [width]="30" [personalLegajo]="this.personalModel.getEntityDTO()"> </app-circle-image>
      <div class="d-flex flex-column ml-2">
        <h5 class="mat-dialog-title my-0">{{ personalModel.Apellido }} {{ personalModel.Nombre }}</h5>
        <small class="gray-text-muted smaller">{{ personalModel.Legajo.puestos?.[0]?.cargo?.nombre }}</small>
      </div>
    </div>
    <div *ngIf="edicionColaboradorPermission$ | async">
      <button class="fix-size" [command]="guardarEditarCmd" mat-button color="primary">
        {{ edicion ? ('GENERAL.SAVE' | translate) : ('GENERAL.EDIT' | translate) }}
      </button>
    </div>
  </ng-container>
</div>
<app-detalle-empleado
  (clicked)="detalleClicked($event)"
  (menuSelected)="menuSelected($event)"
  (imageEmployee)="onImageChange($event)"
  [personalModel]="personalModel"
  [backgroundColor]="'primary'"
  [modoEdicion]="edicion"
  [historial]="historial"
  [contactForm]="contactForm"
  [menuItem]="selectedMenu"
>
</app-detalle-empleado>
<ng-template #avisoEdicion>
  <div class="tooltip">{{ 'PROFILE.CLICK_ON_EDIT_TO_MODIFY' | translate }}</div>
</ng-template>
<ng-template #loadingHeader>
  <div class="d-flex flex-fill align-items-center justify-content-center">
    <ngx-skeleton-loader count="1" appearance="circle"></ngx-skeleton-loader>
    <ngx-skeleton-loader class="w-50" count="1" appearance="line"></ngx-skeleton-loader>
  </div>
</ng-template>
