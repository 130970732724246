import { Injectable } from '@angular/core';
import { PersonalLegajoServiceBackend } from 'src/app/ServiceBackend';
import {
  NewNotificationDTO,
  NotificationAttachmentsDTO,
  NotificationDataDTO,
  NotificationDTO,
  NotificationServiceBackend,
  NotificationStatsDTO,
  UserDTO,
  UsersServiceBackend,
} from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { NotificacionesModel } from './notificaciones.model';

@Injectable({
  providedIn: 'root',
})
export class NotificacionesService {
  public get IndiceNotificacion(): number {
    return this.indiceNotificacion;
  }

  public get SelectedNotification(): NotificacionesModel {
    return this.pSelectedNotification;
  }

  public set IndiceNotificacion(id: number) {
    this.indiceNotificacion = id;
  }

  public set SelectedNotification(value: NotificacionesModel) {
    this.pSelectedNotification = value;
  }

  private indiceNotificacion: number;
  private pSelectedNotification: NotificacionesModel;

  constructor(
    private notificationServiceBackend: NotificationServiceBackend,
    private usersServiceBackend: UsersServiceBackend,
    private personalLegajoServiceBackend: PersonalLegajoServiceBackend
  ) {}

  public async notificarUsuarios(notificationDTO: NotificationDTO, usersName: Array<string>): Promise<void> {
    const newNotification = new NewNotificationDTO();
    newNotification.usersName = usersName;
    newNotification.notification = notificationDTO;

    await this.notificationServiceBackend.insertUserNotifications(newNotification);
  }

  public async obtenerUsuariosDelSistema(): Promise<Array<UserDTO>> {
    const res = await this.usersServiceBackend.getUsers(true);
    return res.sort((x, y) => x.fullName.localeCompare(y.fullName));
  }

  public async notificarPorAreaYSector(notificacion: FormData): Promise<void> {
    await this.personalLegajoServiceBackend.insertPersonalLegajosNotificar(notificacion);
  }

  public async notificacionLeidas(tipo: number, archivadas?: boolean, collaboratorView = false): Promise<Array<NotificationStatsDTO>> {
    return this.notificationServiceBackend.getUserNotificationsStaticsByType(tipo, archivadas, collaboratorView);
  }

  public async obtenerUserNotificacionPorId(id: number): Promise<NotificationAttachmentsDTO> {
    return this.notificationServiceBackend.getUserNotificationsId(id);
  }
  public async obtenerNotificacionPorId(id: number): Promise<NotificationAttachmentsDTO> {
    return this.notificationServiceBackend.getUserNotificationsIdNotification(id);
  }
  public async obtenerUserNotificacionPorIdDeNotificacion(id: number): Promise<NotificationAttachmentsDTO> {
    return this.notificationServiceBackend.getUserNotificationsNotificationId(id);
  }

  public async notificacionesTotalSinLectura(tipo: number): Promise<NotificationDataDTO> {
    return this.notificationServiceBackend.getUserNotificationsTotalWithoutRead(tipo);
  }
  public async archivarNotificationStatsId(id: number, definitivamente: boolean): Promise<void> {
    await this.notificationServiceBackend.deleteUserNotificationsStatsId(id, definitivamente);
  }

  public async desarchivarNotificationStatsId(id: number): Promise<void> {
    await this.notificationServiceBackend.updateUserNotificationsStatsId(id);
  }

  public dispose(): void {
    this.indiceNotificacion = 0;
  }
}
