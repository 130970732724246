import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { TranslateModule } from '@ngx-translate/core';
import { QuillModule } from 'ngx-quill';
import { ComentariosListaComponent } from 'src/app/app-common/comentarios/comentarios-lista/comentarios-lista.component';
import { ComentariosComponent } from 'src/app/app-common/comentarios/comentarios.component';
import { CommentComponent } from 'src/app/app-common/comentarios/comment/comment.component';
import { CircleImageComponent } from 'src/app/shared/circle-image/circle-image.component';
import { FileSelectorComponent } from 'src/app/shared/file-selector/file-selector.component';
import { NgxNeoComponentsModule } from 'src/app/shared/lib/ngx-neo-components-mat/ngx-neo-components.module';
import { NgxNeoDirectivesModule } from 'src/app/shared/lib/ngx-neo-directives-mat/ngx-neo-directives.module';
import { NgxDatePipe } from 'src/app/shared/lib/ngx-neo-pipes/ngx-date.pipe';
import { NgxEmojiTranslatePipe } from 'src/app/shared/reactions/ngx-emoji-translate.pipe';
import { ReactionListComponent } from 'src/app/shared/reactions/reaction-list/reaction-list.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { WarningCardComponent } from 'src/app/shared/warning-card/warning-card.component';

@NgModule({
  declarations: [ComentariosComponent, ComentariosListaComponent, CommentComponent],
  imports: [
    CommonModule,
    FileSelectorComponent,
    SharedModule,
    MatMenuModule,
    NgxNeoDirectivesModule,
    NgxNeoComponentsModule,
    FormsModule,
    QuillModule,
    ReactiveFormsModule,
    CircleImageComponent,
    WarningCardComponent,
    TranslateModule,
    NgxDatePipe,
    EmojiModule,
    PickerModule,
    ReactionListComponent,
    NgxEmojiTranslatePipe,
    MatTooltipModule,
    MatButtonModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatIconModule,
  ],
  exports: [ComentariosComponent, ComentariosListaComponent],
})
export class ComentariosModule {}
