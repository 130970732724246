import { IEntityDTO, NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { CuestionarioRespuestaOpcionDTO } from 'src/app/ModelDTO/DTO/cuestionarioRespuestaOpcion.DTO';


export class CuestionarioRespuestaDTO implements IEntityDTO {

   fecha: Date;
   valorTexto = '';
   valorNumerico: number;
   opciones: Array<CuestionarioRespuestaOpcionDTO>;
   modificada: boolean;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.opciones = new Array<CuestionarioRespuestaOpcionDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.fecha != null) { this.fecha = new Date(jsonObj.fecha); }
      if (jsonObj.valorTexto != null) { this.valorTexto = jsonObj.valorTexto; }
      if (jsonObj.valorNumerico != null) { this.valorNumerico = jsonObj.valorNumerico; }
      if (jsonObj.opciones != null) { for (const item of jsonObj.opciones) { const itemDTO = new CuestionarioRespuestaOpcionDTO(); itemDTO.PrepareDTO(item); this.opciones.push(itemDTO); } }
      if (jsonObj.modificada != null) { this.modificada = jsonObj.modificada; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
