import { ActivatedRoute, Router } from '@angular/router';
import { CambiosHistoricosModelDTO, PersonalModelDTO } from 'src/app/ModelDTO';
import { Command, ICommand } from 'src/app/shared/lib/ngx-neo-directives-mat/public_api';
import { Component, Input, OnDestroy, HostListener, Output, EventEmitter, ViewChild, Type, OnInit, ElementRef } from '@angular/core';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { ImageEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/public_api';
import { PersonalDTO } from 'src/app/ModelDTO/DTO';
import { Subscription, BehaviorSubject } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { noProfileImage } from 'src/app/shared/constants';
import { SelectAndCropComponent } from 'src/app/shared/select-and-crop/select-and-crop.component';
import { MatDialog } from '@angular/material/dialog';
import { FeatureFlagService } from 'src/app/shared/feature-flags/feature-flags.service';
import { FeatureFlagHelper } from 'src/app/shared/feature-flags/feature-flag.helper';
import { featureEdicionColaborador } from 'src/app/shared/feature-flags/feature-flag-provider.service';
import { ProfileImageThumbnailUrlPipe } from 'src/app/shared/pipes/profile-image-thumbnail-url.pipe';
import { MatTabGroup } from '@angular/material/tabs';
import { CapacitacionesSharedComponent } from 'src/app/app-common/capacitaciones/capacitaciones-shared/capacitaciones-shared.component';
import { CapacitacionesViewModel } from 'src/app/app-common/capacitaciones/capacitaciones-shared/capacitaciones-shared-view.model';
import { ComentariosPerfilUsuarioHandler } from 'src/app/app-common/comentarios/comentarios-perfil-usuario.handler';
import { DocumentacionSharedViewModel } from 'src/app/app-common/documentacion-shared/documentacion-shared-view.model';
import { PerfilEmpleadoSharedService } from 'src/app/app-common/perfil-empleado-shared/perfil-empleado-shared.service';
import { SectionsEmployee } from 'src/app/app-common/perfil-empleado-shared/perfil-empleado-enums';
import { UserAusenciasViewModel } from 'src/app/app-common/ausencias/user-ausencias-shared/user-ausencias-view.model';
import { UserEntregasSharedComponent } from 'src/app/app-common/entregas/user-entregas-shared/user-entregas-shared.component';
import { UserEntregasViewModel } from 'src/app/app-common/entregas/user-entregas-shared/user-entregas-shared-view.model';
import { UserListaFeedbacksMinComponent } from 'src/app/app-common/user-feedbacks-shared/user-lista-feedbacks-min/user-lista-feedbacks-min.component';
import { UserListaSancionesMinComponent } from 'src/app/app-common/user-sanciones-shared/user-lista-sanciones-min/user-lista-sanciones-min.component';
import { UserSancionesSharedViewModel } from 'src/app/app-common/user-sanciones-shared/user-sanciones-shared-view.model';
import { UserSolicitudesAusenciaComponent } from 'src/app/app-common/ausencias/user-ausencias-shared/user-solicitudes-ausencia/user-solicitudes-ausencia.component';
import { CompanyInformationComponent } from 'src/app/app-common/perfil-empleado-shared/company-information/company-information.component';
import { CompanyInformationService } from 'src/app/app-common/perfil-empleado-shared/company-information/company-information.service';
import { TeammateFeedbackService } from 'src/app/app-common/perfil-empleado-shared/teammate-feedback.service';
import { PersonalLegajoBasicoDTO } from '@api/interfaces/personal-legajo-basico.interface';
import { classToJson } from 'src/app/shared/shared-functions';
import { EmpleadoDocumentosComponent } from './empleado-documentos/empleado-documentos.component';
import { DetalleEmpleadoService } from './detalle-empleado.service';

export enum AltasEmpleadoEnum {
  AltaDocumento,
  AltaAusencia,
  AltaEntrega,
  AltaSancion,
  AltaFeedback,
  AltaCapacitacion,
}

@Component({
  selector: 'app-detalle-empleado',
  templateUrl: './detalle-empleado.component.html',
  styleUrls: ['./detalle-empleado.component.scss'],
  providers: [CompanyInformationService, TeammateFeedbackService],
})
export class DetalleEmpleadoComponent implements OnInit, OnDestroy {
  @ViewChild(MatTabGroup, { static: false }) public tabGroup: MatTabGroup;
  @ViewChild('fileImage') public fileImage: ElementRef;

  @Input() public backgroundColor: string;
  @Input() public color: string;
  @Input() public modoEdicion = false;
  @Input() public historial: CambiosHistoricosModelDTO;
  @Input() public contactForm: UntypedFormGroup;

  @Output() public clicked = new EventEmitter<MouseEvent>();
  @Output() public imageEmployee = new EventEmitter<ImageEntityDTO>();
  @Output() public menuSelected = new EventEmitter<SectionsEmployee>();

  @Input() public set personalModel(value: PersonalModelDTO) {
    if (value) {
      this.personalModelDTO = value;
      const originalDTO = new PersonalDTO();
      originalDTO.PrepareDTO(this.personalModelDTO.getEntityDTO());
      this.personalOriginalModel = new PersonalModelDTO(originalDTO);
      this.selectedItem(this.temporaryMenuSelected);
    }
  }

  @Input() public set menuItem(value: SectionsEmployee) {
    if (value) {
      this.temporaryMenuSelected = value;
      if (this.personalModelDTO) {
        this.selectedItem(value);
      }
    }
  }

  @HostListener('click', ['$event']) public onClick($event: MouseEvent): void {
    this.clickHandle($event);
  }

  public readonly vistaEmpresa$ = this.headerService.vistaEmpresa$;
  public readonly SectionsEmployee = SectionsEmployee;

  public personalModelDTO: PersonalModelDTO;
  public personalOriginalModel: PersonalModelDTO;
  public currentSection: SectionsEmployee;
  public profileOwnImageUrl: string;

  public lazyEntregas: Promise<Type<UserEntregasSharedComponent>>;
  public lazyDisciplina: Promise<Type<UserListaSancionesMinComponent>>;
  public lazyFeedback: Promise<Type<UserListaFeedbacksMinComponent>>;
  public lazyAusencias: Promise<Type<UserSolicitudesAusenciaComponent>>;
  public lazyDocumentos: Promise<Type<EmpleadoDocumentosComponent>>;
  public lazyCapacitaciones: Promise<Type<CapacitacionesSharedComponent>>;
  public lazyCompany: Promise<Type<CompanyInformationComponent>>;

  public altaEntregaCmd: ICommand = new Command(() => this.altaEntrega(), new BehaviorSubject(true), false);
  public altaCapacitacionCmd: ICommand = new Command(() => this.altaCapacitacion(), new BehaviorSubject(true), false);
  public altaAusenciaCmd: ICommand = new Command(() => this.altaAusencia(), new BehaviorSubject(true), false);
  public altaFeedbackCmd: ICommand = new Command(() => this.altaFeedback(), new BehaviorSubject(true), false);
  public altaSancionCmd: ICommand = new Command(() => this.altaSancion(), new BehaviorSubject(true), false);

  public get canEdit(): boolean {
    return (
      (this.edicionColaboradorPermission || this.personalModelDTO.LegajoModel.Id === this.headerService.personalLegajoId) &&
      this.modoEdicion
    );
  }

  private edicionColaboradorPermission = false;
  private temporaryMenuSelected = SectionsEmployee.DatosPersonales;
  private subsc = new Subscription();

  constructor(
    private activeRoute: ActivatedRoute,
    private capacitacionViewModel: CapacitacionesViewModel,
    private detalleEmpleadoService: DetalleEmpleadoService,
    private dialog: MatDialog,
    private documentacionViewModel: DocumentacionSharedViewModel,
    private featureService: FeatureFlagService,
    private headerService: HeaderAppService,
    private perfilService: PerfilEmpleadoSharedService,
    private profileImagePipe: ProfileImageThumbnailUrlPipe,
    private router: Router,
    private userAusenciasViewModel: UserAusenciasViewModel,
    private userEntregasViewModel: UserEntregasViewModel,
    private userSancionesViewModel: UserSancionesSharedViewModel,
    private teammateFeedbackService: TeammateFeedbackService,
    public comentarioHandler: ComentariosPerfilUsuarioHandler,
    public companyInformationService: CompanyInformationService,
  ) {}

  public ngOnInit(): void {
    this.profileOwnImageUrl = noProfileImage;

    this.subsc.add(
      this.perfilService.empleadoArrived$.subscribe((newPersonalModelDTO) => {
        this.personalModelDTO = newPersonalModelDTO;
        if (this.personalModelDTO) {
          if (this.personalModelDTO?.Image) {
            this.profileOwnImageUrl = this.profileImagePipe.transform(this.personalModelDTO.Image, 200);
          } else {
            this.profileOwnImageUrl = noProfileImage;
          }
        }
      }),
    );

    this.subsc.add(
      this.featureService.flags$.subscribe(({ flags }) => {
        this.edicionColaboradorPermission = FeatureFlagHelper.featureOn(featureEdicionColaborador, flags);
      }),
    );

    this.subsc.add(
      this.perfilService.cancelarEdicion$
        .subscribe(() => {
          this.cancelarEdicion();
        })
        .add(
          this.vistaEmpresa$.subscribe((adminView) => {
            this.comentarioHandler.adminView = adminView;
          }),
        ),
    );
  }

  public ngOnDestroy(): void {
    this.subsc.unsubscribe();
    this.documentacionViewModel.initViewModel();
    this.userAusenciasViewModel.initViewModel();
    this.userEntregasViewModel.initViewModel();
    this.userSancionesViewModel.initViewModel();
    this.capacitacionViewModel.initViewModel();
  }

  public clickImageInput(): void {
    if (this.canEdit) {
      this.fileImage.nativeElement.click();
    }
  }

  public dropImagen(files: FileList): void {
    if (this.canEdit) {
      this.fileChangeEvent({
        target: {
          files,
        },
      });
    }
  }

  public fileChangeEvent(event: unknown): void {
    const dialogRef = this.dialog.open(SelectAndCropComponent, {
      panelClass: 'full-size-sm-600-lg-modal',
      disableClose: false,
      data: { event },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const image = new ImageEntityDTO();
        image.image = result;
        this.profileOwnImageUrl = image.image;
        this.imageEmployee.emit(image);
      }
    });
  }

  public async selectedItem(option: SectionsEmployee): Promise<void> {
    this.menuSelected.emit(option);
    this.currentSection = option;
    // eslint-disable-next-line default-case
    switch (option) {
      case SectionsEmployee.Documentos: {
        this.router.navigate([], {
          relativeTo: this.activeRoute,
          queryParamsHandling: 'merge',
          queryParams: { personalLegajo: this.personalModelDTO.Legajo.id },
          skipLocationChange: false,
          replaceUrl: true,
        });
        this.documentacionViewModel.modalPerfilShared = true;
        this.lazyLoadDocumentosComponent();
        break;
      }
      case SectionsEmployee.Ausencias:
        this.userAusenciasViewModel.personalSelected = this.personalModelDTO.getEntityDTO();
        this.userAusenciasViewModel.filtrarCalendar();
        this.userAusenciasViewModel.incluirBajas = !this.personalModelDTO?.Legajo.estadoActual?.tipo?.esAlta;
        this.userAusenciasViewModel.filtrar();
        this.lazyLoadAusenciasComponent();
        break;
      case SectionsEmployee.Entregas:
        this.userEntregasViewModel.userSelected.id = this.personalModelDTO.getEntityDTO().legajo.id;
        this.userEntregasViewModel.filtrar();
        this.lazyLoadEntregasComponent();
        break;
      case SectionsEmployee.Capacitaciones:
        this.capacitacionViewModel.userSelected.id = this.personalModelDTO.getEntityDTO().legajo.id;
        this.capacitacionViewModel.filtrar();
        this.lazyLoadCapacitacionesComponent();
        break;
      case SectionsEmployee.Disciplina:
        this.userSancionesViewModel.personalSelected = this.personalModelDTO.getEntityDTO();
        this.userSancionesViewModel.perfilEmpleado = true;
        await this.userSancionesViewModel.filtrar();
        this.lazyLoadDisciplinaComponent();
        break;
      case SectionsEmployee.Feedback: {
        const basicTeamamte: PersonalLegajoBasicoDTO = {
          ...classToJson(this.personalModelDTO.getEntityDTO()),
          legajo: this.personalModelDTO.getEntityDTO().legajo.legajo,
          id: this.personalModelDTO.getEntityDTO().legajo.id,
          esSupervisor: false,
          esAdmin: 0,
          tieneCertificado: false,
          isActive: true,
        };
        this.teammateFeedbackService.updateTeammateFeedback(basicTeamamte);
        this.lazyLoadFeedbackComponent();
        break;
      }
      case SectionsEmployee.Company:
        this.companyInformationService.updateComanyInformation(this.personalModelDTO.getEntityDTO().legajo?.empresa);
        this.lazyLoadCompanyInfoComponent();
        break;
    }
  }

  private cancelarEdicion(): void {
    const original = new PersonalDTO();
    original.PrepareDTO(this.personalOriginalModel.getEntityDTO());
    this.personalModelDTO = new PersonalModelDTO(original);
  }

  private clickHandle($event: MouseEvent): void {
    this.clicked.emit($event);
  }

  private async lazyLoadEntregasComponent(): Promise<void> {
    if (!this.lazyEntregas) {
      this.lazyEntregas = import('../../entregas/user-entregas-shared/user-entregas-shared.component').then(
        // eslint-disable-next-line
        ({ UserEntregasSharedComponent }) => UserEntregasSharedComponent,
      );
    }
  }

  private async lazyLoadCapacitacionesComponent(): Promise<void> {
    if (!this.lazyCapacitaciones) {
      this.lazyCapacitaciones = import('../../capacitaciones/capacitaciones-shared/capacitaciones-shared.component').then(
        // eslint-disable-next-line
        ({ CapacitacionesSharedComponent }) => CapacitacionesSharedComponent,
      );
    }
  }

  private async lazyLoadDocumentosComponent(): Promise<void> {
    if (!this.lazyDocumentos) {
      this.lazyDocumentos = import('./empleado-documentos/empleado-documentos.component').then(
        // eslint-disable-next-line
        ({ EmpleadoDocumentosComponent }) => EmpleadoDocumentosComponent,
      );
    }
  }

  private async lazyLoadAusenciasComponent(): Promise<void> {
    if (!this.lazyAusencias) {
      this.lazyAusencias = import(
        '../../ausencias/user-ausencias-shared/user-solicitudes-ausencia/user-solicitudes-ausencia.component'
      ).then(
        // eslint-disable-next-line
        ({ UserSolicitudesAusenciaComponent }) => UserSolicitudesAusenciaComponent,
      );
    }
  }

  private async lazyLoadDisciplinaComponent(): Promise<void> {
    if (!this.lazyDisciplina) {
      this.lazyDisciplina = import('../../user-sanciones-shared/user-lista-sanciones-min/user-lista-sanciones-min.component').then(
        // eslint-disable-next-line
        ({ UserListaSancionesMinComponent }) => UserListaSancionesMinComponent,
      );
    }
  }

  private async lazyLoadFeedbackComponent(): Promise<void> {
    if (!this.lazyFeedback) {
      this.lazyFeedback = import('../../user-feedbacks-shared/user-lista-feedbacks-min/user-lista-feedbacks-min.component').then(
        // eslint-disable-next-line
        ({ UserListaFeedbacksMinComponent }) => UserListaFeedbacksMinComponent,
      );
    }
  }

  private async lazyLoadCompanyInfoComponent(): Promise<void> {
    if (!this.lazyCompany) {
      this.lazyCompany = import('src/app/app-common/perfil-empleado-shared/company-information/company-information.component').then(
        // eslint-disable-next-line
        ({ CompanyInformationComponent }) => CompanyInformationComponent,
      );
    }
  }

  private altaEntrega(): void {
    this.detalleEmpleadoService.nuevoAltaEvento(AltasEmpleadoEnum.AltaEntrega);
  }

  private altaCapacitacion(): void {
    this.detalleEmpleadoService.nuevoAltaEvento(AltasEmpleadoEnum.AltaCapacitacion);
  }

  private altaAusencia(): void {
    this.detalleEmpleadoService.nuevoAltaEvento(AltasEmpleadoEnum.AltaAusencia);
  }

  private altaFeedback(): void {
    this.detalleEmpleadoService.nuevoAltaEvento(AltasEmpleadoEnum.AltaFeedback);
  }

  private altaSancion(): void {
    this.detalleEmpleadoService.nuevoAltaEvento(AltasEmpleadoEnum.AltaSancion);
  }
}
