<h1 mat-dialog-title class="d-flex flew-row justify-content-between">
  <span>Ausencia</span>
  <span class="dialog-close-icon material-symbols-outlined" (click)="close()"> close </span>
</h1>
<div mat-dialog-content>
  <app-detalle-ausencia [ausenciaModel]="solicitudAusencia.AusenciaModel"></app-detalle-ausencia>
  <div class="d-flex flex-row justify-content-between no-margin">
    <p class="mt-1 pr-6">
      <strong>{{ 'TIMEOFF.REQUESTED' | translate }}</strong>
    </p>
    <p class="mt-1">{{ solicitudAusencia.Inicio | ngxdate: 'shortDateFull' }}</p>
  </div>
  <div class="d-flex flex-row justify-content-between no-margin">
    <p class="mt-1 pr-6">
      <strong>{{ 'TIMEOFF.MODIFIED' | translate }}</strong>
    </p>
    <p class="mt-1">
      {{ !solicitudAusencia.CambioEstado ? '-' : (solicitudAusencia.CambioEstado | ngxdate: 'shortDateFull') }}
    </p>
  </div>
  <div class="d-flex pt-2">
    <mat-form-field class="flex-fill" appearance="outline">
      <mat-label> {{ 'TIMEOFF.SUPERVISOR_OBSERVATION' | translate }}</mat-label>
      <textarea
        #obs
        disabled
        required
        matInput
        name="obs"
        id="obs"
        rows="5"
        [(ngModel)]="solicitudAusencia.ObservacionSupervisor"
        [maxLength]="225"
      >
      </textarea>
    </mat-form-field>
  </div>
  <mat-divider class="mt-2 mb-2"></mat-divider>
  <h4 class="gray-text-muted">{{ 'GENERAL.COMMENTS' | translate }}</h4>
  <app-comentarios
    [id]="solicitudAusencia.AusenciaModel.Id"
    [comentarioHandler]="comentarioHandler"
    [canBeNotified]="[solicitudAusencia.AusenciaModel.PersonalLegajo]"
    cantBeNotifiedKey="NO_NOTIFICATION_TEAMMATE_ABSENCE"
  ></app-comentarios>
</div>

<div
  mat-dialog-actions
  class="d-flex flex-row"
  [ngClass]="{ 'justify-content-between': pendingRequest, 'justify-content-end': approvedRequest }"
>
  <button mat-stroked-button color="danger" [command]="eliminarSolicitudCmd" *ngIf="pendingRequest">
    {{ 'GENERAL.REMOVE' | translate }}
  </button>
  <button mat-flat-button color="primary" (click)="editar()" *ngIf="pendingRequest">
    {{ 'GENERAL.EDIT' | translate }}
  </button>
  <button mat-raised-button color="primary" (click)="addDocumentation()" *ngIf="approvedRequest">
    {{ 'TIMEOFF.ADD_DOCUMENTATION' | translate }}
  </button>
</div>
