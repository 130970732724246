/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ComentarioDTO as ComentarioApiDTO } from '@api/interfaces/comentario.interface';
import { ComentarioDTO } from 'src/app/ModelDTO/DTO';
import { CommentExtended } from 'src/app/app-common/comentarios/commentExtended';
import { IComentarios } from './icomentarios';

export abstract class ComentarioBase implements IComentarios {
  public sendMessage(id: number, mensaje: FormData): Promise<void> | void {
    return Promise.resolve();
  }

  public async init(id: number, idPersonalLegajo: number, isAdmin: boolean): Promise<CommentExtended[]> {
    const comments = await this.onInit(id);
    return comments.map((comment) => {
      const extendedComment = new CommentExtended();
      extendedComment.PrepareDTO(comment);
      extendedComment.canEdit = comment.personalLegajo.id === idPersonalLegajo;
      extendedComment.canDelete = comment.personalLegajo.id === idPersonalLegajo || isAdmin;
      return extendedComment;
    });
  }

  public onInit(id: number): Promise<ComentarioDTO[]> | Promise<ComentarioApiDTO[]> {
    return null;
  }

  public isPrivateDefault(): boolean {
    return false;
  }
  public showPrivate(): boolean {
    return true;
  }
  public getTitle(): string {
    return null;
  }
  public needPadding(): boolean {
    return false;
  }
  public getPlaceHolder(tipoVisibilidad?: number): string {
    return null;
  }
  public getNoDataString(): string {
    return null;
  }
  public editMessage(id: number, mensaje: ComentarioDTO | ComentarioApiDTO): Promise<void> | void {
    return Promise.resolve();
  }
  public deleteMessage(id: number, mensaje: ComentarioDTO | ComentarioApiDTO): Promise<void> | void {
    return Promise.resolve();
  }
  public canEditMessage(): boolean {
    return true;
  }
  public canDeleteMessage(): boolean {
    return true;
  }

  public allowStars(): boolean {
    return false;
  }
}
