import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { NgxNeoComponentsModule } from 'src/app/shared/lib/ngx-neo-components-mat/ngx-neo-components.module';
import { MatSelectModule } from '@angular/material/select';
import { PersonalSearchService } from 'src/app/shared/services/search/personal-search.service';
import { NgxNeoCompleterMatModule } from 'src/app/shared/lib/ngx-neo-completer-mat/ngx-neo-completer-mat.module';
import { ConvenioColectivoDTO } from 'src/app/ModelDTO/DTO';
import { ConvenioColectivoService } from 'src/app/layout/administrador/configuracion/convenio-colectivo/convenio-colectivo.service';
import { DUED_RETURN } from 'src/app/layout/administrador/administrador-entregas/listado-de-entregas/listado-de-entregas.component';
import { NgxNeoPipesModule } from 'src/app/shared/lib/ngx-neo-pipes/ngx-neo-pipes.module';
import { compareDtoId } from 'src/app/shared/shared-functions';
import { DeliveryListFiltersData } from './delivery-list-filters.interface';

@Component({
  selector: 'app-delivery-list-filters-modal',
  standalone: true,
  templateUrl: './delivery-list-filters-modal.component.html',
  styleUrls: ['./delivery-list-filters-modal.component.scss'],
  imports: [
    CommonModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatSelectModule,
    NgxNeoComponentsModule,
    TranslateModule,
    NgxNeoCompleterMatModule,
    NgxNeoPipesModule,
  ],
})
export class DeliveryListFiltersModalComponent {
  public filtersForm: FormGroup;

  public from: Date;
  public to: Date;
  public readonly DUED_RETURN = DUED_RETURN;
  public readonly compareId = compareDtoId;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DeliveryListFiltersData,
    private dialogRef: MatDialogRef<DeliveryListFiltersModalComponent>,
    private fb: FormBuilder,
    public personalSearch: PersonalSearchService,
    private convenioColectivoService: ConvenioColectivoService,
  ) {
    this.initForm(this.data);
  }

  public filter(): void {
    if (this.from && this.to) {
      this.dialogRef.close({ ...this.filtersForm.value, from: this.from, to: this.to });
    }
  }

  public clear(): void {
    this.filtersForm.reset();
    this.from = null;
    this.to = null;
  }

  public nombreConvenio(convenio: ConvenioColectivoDTO): string {
    return this.convenioColectivoService.nombreConvenio(convenio);
  }

  private initForm(filters: DeliveryListFiltersData): void {
    this.from = this.data.from;
    this.to = this.data.to;

    this.filtersForm = this.fb.group({
      state: filters.state,
      type: filters.type,
      teammate: filters.teammate,
    });
  }
}
